app.controller('CheckoutCouponListDialogController', [
  '$scope',
  '$uibModalInstance',
  'promotionService',
  function ($scope, $uibModalInstance, promotionService) {
    $scope.state = {
      loadingItems: false,
    };
    $scope.pagination = {
      limit: 4,
      page: 0,
      maxPage: 0,
    };
    $scope.coupons = [];

    var rawOffset = new Date().getTimezoneOffset() / -60;
    $scope.timezoneOffset =
      rawOffset < 0 ? rawOffset : '+' + Math.abs(rawOffset);

    var cartPromotionElement = angular.element('.cart-promotions')[0];
    var couponElements = cartPromotionElement
      ? cartPromotionElement.querySelectorAll('.promotion.coupon')
      : [];
    var appliedCodes = _.map(couponElements, function (e) {
      return angular.element(e).attr('data-coupon-code');
    });

    $scope.getCoupons = function () {
      $scope.state.loadingItems = true;
      $scope.pagination.page++;
      promotionService
        .getCoupons($scope.pagination.page, 4, 'valid')
        .then(function (res) {
          var resData = res.data.data;
          $scope.pagination.maxPage = Math.ceil(resData.total / 4);
          $scope.state.loadingItems = false;
          var newCoupons = _.chain(resData.items)
            .reject(function (coupon) {
              return appliedCodes.includes(coupon.codes[0]);
            })
            .map(function (coupon) {
              return Object.assign({}, coupon, {
                formattedTime: dayjs(coupon.end_at).format(
                  $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                    ? 'DD/MM/YYYY HH:mm'
                    : 'YYYY/MM/DD HH:mm',
                ),
              });
            })
            .value();
          $scope.coupons = $scope.coupons.concat(newCoupons);

          // If filtered coupons are less than 4, load more to enable scroll
          if (
            $scope.coupons.length < 4 &&
            $scope.pagination.page < $scope.pagination.maxPage &&
            $scope.pagination.maxPage !== 0
          ) {
            $scope.getCoupons();
          }
        });
    };
    $scope.getCoupons();

    var element;
    $scope.listenToScroll = function () {
      $(document).ready(function () {
        element = $('.checkout-coupon-list-content')[0];
        $('.checkout-coupon-list-content').scroll(
          _.debounce(function () {
            if (
              !$scope.state.loadingItems &&
              element.scrollHeight -
                (element.scrollTop + element.clientHeight) <
                30 && // height of the loading element
              $scope.pagination.page < $scope.pagination.maxPage &&
              $scope.pagination.maxPage !== 0
            ) {
              $scope.getCoupons();
            }
          }, 20),
        );
      });
    };

    $scope.shouldShowLoading = function () {
      return (
        $scope.state.loadingItems ||
        ($scope.pagination.page < $scope.pagination.maxPage &&
          $scope.pagination.maxPage !== 0)
      );
    };

    $scope.cancelModal = function (coupon) {
      $uibModalInstance.close(coupon);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  },
]);

app.service('telService', [
  function () {
    this.buildFakeTelInput = function (phone) {
      var telInputElemnt = $(document.createElement('ipnut'));
      telInputElemnt.intlTelInput();
      telInputElemnt.intlTelInput('setNumber', '+' + phone);
      return telInputElemnt;
    };

    this.getCountryNumber = (iso2) => {
      if (typeof iso2 !== 'string') return undefined;
      const lowerIso2 = iso2.toLowerCase();
      const countryDataObj = window.intlTelInputGlobals
        .getCountryData()
        .find((countryData) => countryData.iso2 === lowerIso2);
      return countryDataObj?.dialCode;
    };
  },
]);

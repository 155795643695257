app.service('eInvoiceService', [
  function () {
    var carrierTypes = [
      {
        value: 0,
        text: 'orders.invoices.carrier_type.member',
      },
      {
        value: 1,
        text: 'orders.invoices.carrier_type.mobile_barcode',
      },
      {
        value: 2,
        text: 'orders.invoices.carrier_type.npc_barcode',
      },
    ];

    return {
      carrierTypes: carrierTypes,
      companySerialNoChecker: function (serial) {
        if (!serial) return false;
        if (!serial.match(/^\d{8}$/)) return false;
        var serialArray = serial.split('');
        var numArray = [1, 2, 1, 2, 1, 2, 4, 1];
        var result = serialArray.map(function (num, index) {
          return num * numArray[index];
        });

        var sum = 0;
        result.forEach(function (num) {
          sum += num
            .toString()
            .split('')
            .reduce(function (acc, curr) {
              return acc + Number(curr);
            }, 0);
        });

        return sum % 10 === 0 || ((sum + 1) % 10 === 0 && serial[6] === '7');
      },
    };
  },
]);

app.directive('cartFormPakpobox', [
  '$timeout'
  '$compile'
  'checkoutService'
  'cart'
  '$q'
  '$rootScope'
  (
    $timeout
    $compile
    checkoutService
    cart
    $q
    $rootScope
  ) ->
    {
      restrict: 'A'
      scope: {
        type: "@"
        defaultLocationCode: "@"
        getLocationCallback: "&onLocationChange"
      }
      link: (scope, element, attrs) ->
        scope.availablePakpoboxLocations = []
        $pakpobox = element
        $pakpobox
          .find("#pakpobox-location select")
          .attr("ng-model", "location_code")
          .attr("ng-options", "location.code as (location.name_translations| translateModel) for location in availablePakpoboxLocations")
          .attr("ng-change", "onPakpoboxLocationChange()")
          .attr("ng-disabled", "!availablePakpoboxLocations")
        $pakpobox.html($compile($pakpobox.html())(scope))

        element.on 'change', 'select[name="delivery[pakpobox][district]"]', (event) ->
          scope.location_code = undefined
          scope.onPakpoboxLocationChange()
          setLocations()

        setLocations = () ->
          locations = try
                        JSON.parse($('#pakpobox-locations-data').val())
                      catch error
                        [] # Catch error when the method is called on re-rendering

          name = checkoutService.getElement('delivery[pakpobox][district]').val()
          district = _.find(locations, ((d) -> return true for lang, district_name of d.district_translations when district_name is name; return false ))
          $timeout((() -> scope.availablePakpoboxLocations = district.locations), 0) if district?

        scope.onPakpoboxLocationChange = () ->
          return if scope.defaultLocationCode == scope.location_code
          scope.defaultLocationCode = scope.location_code
          location = _.find(scope.availablePakpoboxLocations, (l) -> return l.code == scope.location_code)

          deliveryData = if location? then { location_code: scope.location_code, pakpobox_type: 'smart_locker' } else null
          scope.getLocationCallback()(deliveryData) if scope.getLocationCallback()?
          $rootScope.$emit('cart.form.pakpobox.location.change', scope.location_code)

        scope.$on "checkout.cart.form.rerender", () ->
          element.off()
          watcher() if watcher?

        initialize = () ->
          scope.location_code = scope.defaultLocationCode
          scope.onPakpoboxLocationChange()
          setLocations()

        # Listen to angular digest cycle
        registered = false
        watcher = scope.$watch () ->
          return if registered
          registered = true
          scope.$$postDigest () ->
            registered = false
            if checkoutService.getElement('delivery[pakpobox][district]')
              watcher()
              initialize()
          if $('#pakpobox-location').length
            $rootScope.$emit('cart.form.pakpobox.location.change', scope.location_code)

        initialize()
    }
])
app.controller('paymentSlipController', [
  '$http',
  '$scope',
  '$upload',
  '$window',
  '$timeout',
  '$uibModal',
  'order',
  'mainConfig',
  function (
    $http,
    $scope,
    $upload,
    $window,
    $timeout,
    $uibModal,
    order,
    mainConfig,
  ) {
    $scope.state = {
      mode: null,
      isExpendPaymentDescription: false,
      isImageUploaded: false,
      isImageUploadError: false,
      isDatePickerOpened: false,
      isSubmitting: false,
      maxTextLength: 200,
    };

    $scope.paymentObject = order.order_payment.object_data;
    $scope.canShowSlipDetail =
      (order.order_payment_slips && mainConfig.currentUser) ||
      !order.order_payment_slips;

    var guestViewTime = dayjs(order.create_at).add(
      $scope.paymentObject.config_data.payment_slip.days_of_guest_view,
      'day',
    );
    $scope.shouldLogin = guestViewTime.isBefore(dayjs());

    if (order.order_payment_slips) {
      $scope.paymentSlipData =
        order.order_payment_slips &&
        order.order_payment_slips.find(function (slip) {
          return slip.is_last;
        });
    } else {
      if ($scope.shouldLogin) {
        $window.location.href = '/users/sign_in';
      }
    }

    $scope.newPaymentSlipData = {
      media_id: null,
      text: '',
      owner_type: 'User',
      trackable_id: order.order_payment._id,
      trackable_type: 'OrderPayment',
      owner_id: order.customer_id,
      is_private: true,
      custom_data: {
        order_id: order._id,
        paid_time: null,
      },
    };

    $scope.datepickerOptions = {
      showWeeks: false,
    };

    $scope.openDateTimePicker = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.state.isDatePickerOpened = !$scope.state.isDatePickerOpened;
    };

    $scope.formatDate = function (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm');
    };

    $scope.showLargeImage = function (imageUrl) {
      if (imageUrl && !$scope.state.isImageUploading) {
        var newScope = $scope.$new();
        newScope.imageSrc = imageUrl;
        $uibModal.open({
          scope: newScope,
          windowClass: 'paymentSlipImageModal',
          templateUrl: require('../../../../../public/themes/shared/order/templates.payment_slip_modal.html'),
          controller: [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.close = function () {
                $uibModalInstance.dismiss();
              };
            },
          ],
        });
      }
    };

    $scope.handleImageUploadError = function () {
      $scope.state.isImageUploadError = true;
      $scope.newPaymentSlipData.media_id = null;
      $scope.newPaymentSlipData.media = null;
    };

    $scope.expendPaymentDescription = function () {
      $('.orderInfo__instructions .instructionText').trigger('destroy');
      $scope.state.isExpendPaymentDescription = true;
      $scope.state.showReadMore = false;
    };

    $scope.changeImage = function () {
      if ($scope.state.isImageUploaded && !$scope.state.isImageUploading) {
        $('#imageInput').click();
      }
    };

    $scope.onFileSelect = function ($files) {
      if ($scope.state.isImageUploading) return;

      var file = $files[0];
      if ((file && file.size > 10000000) || !/jpg|png|jpeg/.test(file.type)) {
        $scope.handleImageUploadError();
        return;
      }
      if (file) {
        $scope.uploadMedia(file);
      }
    };

    $scope.uploadMedia = function (image) {
      $scope.state.isImageUploading = true;
      $scope.upload = $upload
        .upload({
          url: '/api/media',
          method: 'POST',
          data: {
            media: {
              status: 'temp',
            },
            recaptchable: false,
            is_private: true,
          },
          file: image,
          fileFormDataName: 'media[image_clip]',
          formDataAppender: function (fd, key, val) {
            if (angular.isArray(val)) {
              angular.forEach(val, function (v) {
                fd.append(key, v);
              });
            } else if (angular.isObject(val)) {
              angular.forEach(val, function (value, idx2) {
                fd.append(key + '[' + idx2 + ']', value);
              });
            } else {
              fd.append(key, val);
            }
          },
        })
        .then(function (res) {
          var media = res.data.data;
          $scope.newPaymentSlipData.media_id = media._id;
          $scope.state.presigned_url = media.presigned_url;
          $scope.state.isImageUploaded = true;
          $scope.state.isImageUploadError = false;
        })
        .catch(function () {
          $scope.handleImageUploadError();
        })
        .finally(function () {
          $scope.state.isImageUploading = false;
        });
    };

    $scope.isFormValid = function () {
      return (
        !$scope.state.isSubmitting &&
        $scope.newPaymentSlipData.media_id &&
        $scope.newPaymentSlipData.text.length <= $scope.state.maxTextLength
      );
    };

    $scope.submit = function () {
      if (!$scope.isFormValid()) return;

      $scope.state.isSubmitting = true;
      $http({
        method: 'POST',
        url:
          '/api/orders/' +
          order._id +
          '/comments?performer_id=' +
          order.customer_id,
        data: {
          comment: $scope.newPaymentSlipData,
          recaptchable: true,
        },
      })
        .then(function (res) {
          $scope.paymentSlipData = res.data;
          $scope.paymentSlipData.images = {
            presigned_url: $scope.state.presigned_url,
          };
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          $scope.state.isSubmitting = false;
        });
    };

    $timeout(function () {
      if ($('.orderInfo__instructions .instructionText').height() > 100) {
        $scope.state.showReadMore = true;
      }
      $('.orderInfo__instructions .instructionText').dotdotdot({
        wrap: 'letter',
        ellipsis: '...',
        height: 100,
      });
    });
  },
]);

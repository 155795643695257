app.directive("checkoutCustomerForm", [
  '$compile'
  '$timeout'
  '$rootScope'
  'mainConfig'
  'featureService'
  'checkoutService'
  '$http'
  (
    $compile
    $timeout
    $rootScope
    mainConfig
    featureService
    checkoutService
    $http
  ) ->
    {
      restrict: 'A'
      scope: {
        phonesCountryCodes: '='
      }
      link: (scope, element, attrs) ->
        scope.state = {}
        if checkoutService.getElement('saveFields[customer_info][birthday]', element).length > 0
          scope.birthday = ""
          scope.toggleBirthdayPicker = (() -> $timeout((()-> scope.state.showBirthday = !scope.state.showBirthday), 10))
          $http(
            method: 'GET'
            url: '/api/merchants/' + mainConfig.merchantId + '/user_setting').then (res) ->
            # For validateFormField birthday validate
            $rootScope.minimumAgeLimit =  Number(res.data.minimum_age_limit or 13);
            $rootScope.maxDate = dayjs().endOf('day').subtract($rootScope.minimumAgeLimit, 'years');
            scope.datepickerOptions =
              maxDate: $rootScope.maxDate
              minDate: new Date('1899-12-31')
              initDate: new Date($rootScope.maxDate)
            return
          $container = element.find(".input-date").closest(".form-group")
          value = $container.find(".input-date").val()
          $container.find(".input-date").attr({
            "uib-datepicker-popup": "yyyy/MM/dd"
            "ng-model": "birthday"
            "is-open": "state.showBirthday"
            "show-button-bar": "false"
            "datepicker-options": "datepickerOptions"
            "ng-pattern": "/^(19|20)[0-9]{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/"
            "show-weeks": "true"
          })
          $container.find(".btn-datepicker").attr("ng-click", "toggleBirthdayPicker()")
          $container.html $compile($container.html())(scope)
          if value.length != 0
            scope.birthday = new Date(value)

        scope.$on "checkout.customer.needs_update", (() -> onCustomerInfoUpdated())
        onCustomerInfoUpdated = () ->
          payload = {
            customer_name: checkoutService.getElementValue("order[customer_name]", element),
            customer_phone: checkoutService.getElementValue("order[customer_phone]", element)
          }
          $rootScope.$broadcast("checkout.customer.changed", payload)

        notifyCustomerUpdateDebounced = _.debounce(onCustomerInfoUpdated, 100)

        element.on "keyup focusout", "input[name='order[customer_name]'], input[name='order[customer_phone]']", (() -> notifyCustomerUpdateDebounced())

        # this selector are deprecated
        element.on "change", "select#order-customer-phone", (event) ->
          $switch = $(event.currentTarget)
          $container = $switch.closest(".form-group")
          if $switch.val() is 'new'
            $switch.removeClass('has-country-selector') if featureService.hasRolloutFeature('country_code')
            $container.find('.order-customer-phone-select-wrapper .intl-tel-input').hide()
            $container.find('.order-customer-phone-input-wrap').val('').show()
            $container.find('input').val('')
            $container.siblings(".save-customer-phone").show()
          else
            $switch.addClass('has-country-selector') if featureService.hasRolloutFeature('country_code')
            $container.find('.order-customer-phone-select-wrapper .intl-tel-input').show()
            notifyCustomerUpdateDebounced()
            $container.find('.order-customer-phone-input-wrap').hide()
            $container.siblings(".save-customer-phone").hide()
            if featureService.hasRolloutFeature('country_code') && scope.phonesCountryCodes[checkoutService.getElement('order[customer_phone]').val()]
              $container.find('.contry-content').intlTelInput(
                'setCountry',
                scope.phonesCountryCodes[checkoutService.getElement('order[customer_phone]').val()].iso2
              )
            else
              $container.find('.contry-content').intlTelInput(
                'setCountry',
                mainConfig.requestCountry.toLowerCase()
              )

        $('.order-customer-phone-with-countrycode')
          .intlTelInput { preferredCountries: [mainConfig.requestCountry.toLowerCase()] }

        element.on "destroy", (() -> element.off()) # Unbind events
    }
])

app.directive('facebookMessengerCheckboxV1', [
  'mainConfig',
  'benchatService',
  'imageServiceEndpoint',
  '$timeout',
  '$q',
  function (mainConfig, benchatService, imageServiceEndpoint, $timeout, $q) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.facebook-messenger-checkbox-v1.html'),
      scope: {
        benchatConfig: '=',
        benchatChannel: '=',
        customerId: '=',
        orderId: '=',
        sessionId: '=',
        topics: '=',
      },
      link: function (scope) {
        scope.imageServiceEndpoint = imageServiceEndpoint;
        scope.merchant = mainConfig.merchantData;
        scope.benchatSubscriptions = [];
        scope.state = {
          isSubscriptionLoaded: false,
        };
        scope.userRef = null;
        scope.customerIdentifier = scope.customerId || scope.sessionId;
        scope.origin = window.location.origin.replace('http://', 'https://');

        var reloadCheckbox = function () {
          scope.userRef = benchatService.generateUserRef(
            scope.customerIdentifier,
          );

          // need to wait for all dom attributes in checkbox to be ready before re-rendering it
          $timeout(function () {
            FB.XFBML.parse(
              document.getElementById('facebook-messenger-checkbox'),
            );
          }, 0);
        };

        var getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerIdentifier)
            .then(function (result) {
              var subscriptions = result.data.subscriptions;
              if (_.isEmpty(subscriptions)) {
                return [];
              }
              return benchatService.getFacebookActiveSubscriptions(
                scope.topics,
                subscriptions,
              );
            });
        };

        var init = function () {
          getSubscriptions().then(function (subscription) {
            scope.state.isSubscriptionLoaded = true;
            scope.benchatSubscriptions = subscription;

            if (subscription.length === 0) {
              reloadCheckbox();
            }
          });
        };

        scope.optOut = function () {
          $q.all(
            _.map(scope.benchatSubscriptions, function (subscription) {
              benchatService.deactivateSubscription(subscription._id);
            }),
          ).then(function () {
            init();
          });
        };

        var activateSubscriptions = function (subscriptions) {
          return $q.all(
            _.map(subscriptions, function (subscription) {
              benchatService.activateSubscription(subscription._id, {
                subscription: subscription,
              });
            }),
          );
        };

        // opt-in event handling
        FB.Event.subscribe('messenger_checkbox', function (e) {
          if (e.event == 'checkbox' && e.state == 'checked') {
            // before triggering facebook, create subscription in benchat first
            benchatService
              .patchSubscriptions(
                _.map(scope.topics, function (topic) {
                  return {
                    userId: scope.customerIdentifier,
                    channelOwnerId: mainConfig.merchantData._id,
                    userRef: scope.userRef,
                    platform: 'FACEBOOK',
                    topic: topic,
                  };
                }),
              )
              .then(
                function (response) {
                  // ref data that benchat requires to identify customer and topics
                  var ref = {
                    order: scope.orderId, // order id is optional
                    customer: scope.customerIdentifier,
                    merchant: mainConfig.merchantData._id,
                    topics: scope.topics,
                  };

                  FB.AppEvents.logEvent(
                    'MessengerCheckboxUserConfirmation',
                    null,
                    {
                      app_id: scope.benchatConfig.facebook_app_id,
                      page_id: scope.benchatChannel.platformChannelId,
                      ref: JSON.stringify(ref),
                      user_ref: scope.userRef,
                    },
                  );

                  activateSubscriptions(response.data).then(function () {
                    init();
                  });
                },
                function () {
                  // something went wrong when creating subscription in benchat
                },
              );
          }
        });

        init();
      },
    };
  },
]);

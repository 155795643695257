app.service('memberGetCouponPopupServices', [
  '$filter',
  '$uibModal',
  '$location',
  'slFeatureService',
  function ($filter, $uibModal, $location, slFeatureService) {
    const {
      result: couponResult,
      failed_status: failedStatus,
      name: promotionName,
    } = $location.search();
    const popupMessageEnumeration = {
      succeed: {
        title: promotionName,
        content: 'coupon.congratulation',
        confirm: 'coupon.view',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_success.png',
      },
      // failed
      not_login: {
        title: $filter('translate')('coupon.login_to_receive'),
        content: 'coupon.login_or_register',
        confirm: 'coupon.login',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_not_logged_in.png',
      },
      fulled: {
        title: promotionName,
        content: 'coupon.usage_limit',
        confirm: 'coupon.close',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_fail.png',
      },
      expired: {
        title: promotionName,
        content: 'coupon.is_expired',
        confirm: 'coupon.close',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_fail.png',
      },
      not_eligible: {
        title: promotionName,
        content: 'coupon.not_eligible',
        confirm: 'coupon.close',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_fail.png',
      },
      already_taken: {
        title: promotionName,
        content: 'coupon.already_taken',
        confirm: 'coupon.close',
        statusImage:
          'https://s3.ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/get_coupon_fail.png',
      },
    };

    function openPopup({ onConfirm }) {
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/users/templates.dialog.member_get_coupon.html'),
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            const isFailed = couponResult === 'failed';
            const popupMessage = isFailed
              ? popupMessageEnumeration[failedStatus]
              : popupMessageEnumeration[couponResult];
            $scope.couponMessage = {
              ...popupMessage,
              isFailed,
            };
            $scope.confirm = function () {
              $uibModalInstance.close();
              if (typeof onConfirm === 'function') {
                onConfirm();
              }
            };
          },
        ],
        windowClass: 'coupon-get-modal-window',
      });
    }

    function openGetCouponPopup(props) {
      if (!slFeatureService.hasFeature('get_coupon')) {
        return;
      }

      const { type } = props;
      // the login page
      if (type === 'login' && couponResult === 'not_login') {
        openPopup(props);
        return;
      }
      // the coupon page
      if (
        type === 'coupon' &&
        (couponResult === 'succeed' ||
          (couponResult === 'failed' &&
            ['fulled', 'expired', 'not_eligible', 'already_taken'].includes(
              failedStatus,
            )))
      ) {
        openPopup(props);
        return;
      }
    }

    return { openGetCouponPopup };
  },
]);

app.directive("checkoutCartForm", [
  '$http'
  '$timeout'
  '$compile'
  'checkoutService'
  'cartService'
  'mainConfig'
  '$rootScope'
  'logger'
  (
    $http
    $timeout
    $compile
    checkoutService
    cartService
    mainConfig
    $rootScope
    logger
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        onCartFormLoaded = (() -> scope.$emit("checkout.cart.form.loaded"))
        
        scope.zeekPickupStatus = {}

        scope.$on "checkout.cart.form.reload", () ->
          checkoutService.requestPartial('cart', 'form')
            .then (res) ->
              scope.$broadcast("checkout.cart.form.rerender")
              $timeout((() -> element.html($compile(res.data)(scope))), 0)
            .catch (error) ->
              logger.log "Unable to load cart form"
            .finally (() -> onCartFormLoaded())

        element.on "change", "#order-delivery-country", (event) ->
          $rootScope.$emit('cart.form.country.change')
          updateOption { country: angular.element(event.currentTarget).val() }

        element.on "change", "#order-delivery-method", (event) ->
          deliveryOptionId = angular.element(event.currentTarget).val();
          $rootScope.$emit('cart.form.delivery.change', deliveryOptionId)
          updateOption { delivery_option_id: deliveryOptionId, delivery_data: {}}, { triggerItemsValidation: true }

        element.on "change", "#order-payment-method", (event) ->
          updateOption { payment_id: angular.element(event.currentTarget).val() }

        scope.onSfLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onPakpoboxLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onZeekLocationChange = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false }

        scope.onStoreSelected = (deliveryData) ->
          updateOption { delivery_data: (deliveryData || {}) }, { rerender: false, triggerItemsValidation: true }

        validateCartItem = () ->
          cartService.validate()
            .then (data) ->
              scope.$emit("checkout.cart.items.changed", { success: true })
            .catch (response) ->
              scope.$emit('checkout.cart.items.changed', {
                success: false, cartValidateItem: response
              })

        updateOption = (data, options) ->
          options = {} if !options?
          _.defaults(options, { rerender: true })

          scope.$emit "checkout.cart.form.changing" if options.rerender
          checkoutService.requestPartial('cart', 'form', data)
            .then (res) ->
              if options.rerender
                scope.$broadcast("checkout.cart.form.rerender")
                $timeout((() -> element.html($compile(res.data)(scope))), 0)
              scope.$emit "checkout.cart.form.changed", { rerender: options.rerender }
              if options.triggerItemsValidation
                validateCartItem()
            .catch (error) ->
              logger.log "Unable to update option"
              logger.log data
            .finally (() -> onCartFormLoaded())

        element.on "destroy", (() -> element.off()) # Unbind events
    }
])

app.directive('smsMessengerCheckbox', [
  '$q',
  'mainConfig',
  'benchatService',
  'smsSubscriptionService',
  function ($q, mainConfig, benchatService, smsSubscriptionService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.sms-messenger-checkbox.html'),
      scope: {
        subscribedEmailTypes: '=',
        customerId: '@',
        topicStr: '@',
        benchatSubscriptions: '=',
      },
      link: function (scope) {
        scope.sms = { isChecked: false };
        scope.customerIdentifier = scope.customerId;
        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.topics = scope.topicStr.split(',');
        scope.subscriptions = []; // would only store active subscriptions
        scope.hasMarketingSubscriptions = false; // if there are active / inactive subscriptions
        scope.hasMarketingNewsTag =
          scope.subscribedEmailTypes &&
          scope.subscribedEmailTypes.includes('marketing.news'); // if there is marketing.news (true/false)

        var getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerIdentifier)
            .then(function (result) {
              var subscriptions = result.data.subscriptions;
              // to update parent`s benchat subscription
              if (subscriptions.length > 0) {
                scope.benchatSubscriptions = angular.extend(
                  scope.benchatSubscriptions,
                  subscriptions,
                );
              }
              scope.hasMarketingSubscriptions = smsSubscriptionService.hasMarketingSubscriptions(
                subscriptions,
              );
              scope.subscriptions = smsSubscriptionService.getActiveSubscriptions(
                scope.topics,
                subscriptions,
              );

              // to fulfill business logic
              if (
                scope.hasMarketingSubscriptions === false &&
                scope.hasMarketingNewsTag === true
              ) {
                scope.sms.isChecked = true;
              } else if (scope.subscriptions.length > 0) {
                scope.sms.isChecked = true;
              } else {
                scope.sms.isChecked = false;
              }

              return;
            });
        };

        var deactivateSubscription = function () {
          if (scope.subscriptions.length === 0) {
            // for deactivate an not exist subscription
            return createInactiveSubscription();
          }

          var tasks = _.map(scope.subscriptions, function (subscription) {
            return benchatService.deactivateSubscription(subscription._id);
          });

          return $q.all(tasks).finally(function () {
            return getSubscriptions();
          });
        };

        var createInactiveSubscription = function () {
          return smsSubscriptionService
            .createInactiveSubscriptions(
              scope.customerIdentifier,
              mainConfig.merchantData._id,
              scope.userRef,
              scope.topics,
            )
            .then(function () {
              return getSubscriptions();
            });
        };

        var createActiveSubscription = function () {
          return smsSubscriptionService
            .createActiveSubscriptions(
              scope.customerIdentifier,
              mainConfig.merchantData._id,
              scope.userRef,
              scope.topics,
            )
            .then(function () {
              return getSubscriptions();
            });
        };

        scope.$watch('subscribedEmailTypes', function (newValue) {
          var newMarketingNewsTag =
            newValue && newValue.includes('marketing.news');

          if (scope.hasMarketingNewsTag !== newMarketingNewsTag) {
            scope.hasMarketingNewsTag = newMarketingNewsTag;
            getSubscriptions();
          }
        });

        scope.handleClick = function () {
          if (scope.sms.isChecked === false) {
            deactivateSubscription();
          } else {
            createActiveSubscription();
          }
        };

        var init = function () {
          getSubscriptions();
        };

        init();
      },
    };
  },
]);

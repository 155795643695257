app.controller('RegistrationsNewController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  '$location',
  '$filter',
  'resource',
  'mainConfig',
  'featureService',
  'benchatService',
  '$q',
  'mobilePhoneService',
  'staticImageHost',
  'fbService',
  'slPixelService',
  'customValidator',
  'RecaptchaService',
  '$cookies',
  'memberGetCouponPopupServices',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    $location,
    $filter,
    resource,
    mainConfig,
    featureService,
    benchatService,
    $q,
    mobilePhoneService,
    staticImageHost,
    fbService,
    slPixelService,
    customValidator,
    RecaptchaService,
    $cookies,
    memberGetCouponPopupServices,
  ) {
    var timeZoneOffset = new Date().getTimezoneOffset() / -60;
    $scope.benchatSubscriptions = [];
    $scope.emailRegex = customValidator.EMAIL_REGEX;
    $scope.staticImageHost = staticImageHost;
    $scope.featureService = featureService;
    $scope.resource = resource.data;
    $scope.maxlength = 50;

    memberGetCouponPopupServices.openGetCouponPopup({ type: 'login' });

    $scope.genders = ['male', 'female', 'other'].map(function (key) {
      return { id: key, label: $filter('translate')('users.signup.' + key) };
    });
    if (featureService.hasFeature('mobile_signup_p2')) {
      $scope.resource.isMobilePhoneSignUp = featureService.hasFeature(
        'mobile_signup_only',
      );
      if ($scope.resource.isMobilePhoneSignUp) {
        $scope.resource.email = null;
      }

      $scope.signUpSelections = ['email', 'mobile_phone'].map(function (key) {
        return {
          label: $filter('translate')('users.signup.' + key),
          value: key == 'mobile_phone',
        };
      });
    }

    $scope.resource.owner_id = mainConfig.merchantData._id;
    $scope.temp = {}; //hackaround for invalid birthday error
    $scope.$watch('resource.email', function (newVal, oldVal) {
      if (newVal != oldVal && $scope.userForm.email != undefined) {
        $scope.userForm.email.$setValidity('general', true);
      }
    });

    $scope.clickFBLogin = function (appId, version) {
      if (fbService.isMessengerInAppBrowser()) {
        return window.location.replace('/users/auth/facebook');
      }
      fbService.login(appId, version).then(function () {
        window.location.replace('/');
      });
    };

    $scope.$on('mobile-step-change', function (event, step) {
      $scope.mobileSignInStep = step;
    });

    $scope.setWidgetId = function (widgetId) {
      //'Created widget ID: %s', widgetId
      $scope.widgetId = widgetId;
    };

    $http({
      method: 'GET',
      url: '/api/merchants/' + mainConfig.merchantId + '/user_setting',
    }).then(function (res) {
      $scope.user_setting = res.data;
      $scope.minimumAgeLimit = Number(res.data.minimum_age_limit || 13);
      $scope.maxBirthdayDate = dayjs()
        .endOf('day')
        .subtract($scope.minimumAgeLimit, 'years');
      $scope.datepickerOptions = {
        showWeeks: true,
        maxDate: $scope.maxBirthdayDate,
        minDate: new Date('1899-12-31'),
        initDate: new Date($scope.maxBirthdayDate),
      };
    });

    $scope.subscription_tags = {
      'marketing.news': false,
    };
    // T&C checkbox
    $scope.state = {
      tncChecked: false,
    };

    $scope.facebook_url = function () {
      if ($scope.subscription_tags['marketing.news']) {
        return '/users/auth/facebook?marketing=true&signup=true';
      } else {
        return '/users/auth/facebook?signup=true';
      }
    };

    $scope.line_url = function (channel) {
      return benchatService.getLineLoginUri(channel.platformChannelId);
    };

    // Datepicker
    $scope.datepicker = {
      open: false,
    };
    $scope.open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datepicker.open = true;
    };

    $scope.userForm = {};

    $scope.mobileValidationFormSubmit = function () {
      $scope.userForm.errorMessages = null;
      $scope.resource.mobile_phone = $scope.resource.mobilePhone;
      $scope.resource.country_calling_code = $scope.resource.countryCallingCode;
      $scope.showMobileUnVerifiedMessage = false;

      $http({
        method: 'POST',
        url: '/api/users/register_validation',
        data: { user: $scope.resource },
      }).then(
        function (response) {
          if (response.data && response.data.message) {
            $scope.userForm.successMessages = response.data.message;
          } else {
            $scope.$broadcast('mobile-step-change', 'check_mobile');
            $scope.userForm.submitting = false;
            slPixelService.hdTracking('signUp');
          }
        },
        function (response) {
          $scope.userForm.errorMessages = response.data.error_messages;
          $scope.userForm.submitting = false;
        },
      );
    };

    function createMobileRegex(country) {
      return {
        test: function (value) {
          var mobilePhoneInput = $('.intl-tel-input');
          // TODO: update utils.js for newest hk mobile number validation
          if (country === 'hk') {
            var internationalNumber = mobilePhoneInput
              .intlTelInput(
                'getNumber',
                intlTelInputUtils.numberFormat.NATIONAL,
              )
              .replace(/\D/g, '');

            return (
              /^[4-9]{1}\d{7}$/.test(value) && value == internationalNumber
            );
          }
          return intlTelInputUtils.isValidNumber(value, country);
        },
      };
    }

    var renderBenchatFields = function () {
      var benchatFields = {
        subscriptions: {
          line: [],
          facebook: [],
        },
      };

      if (
        document.getElementsByName('benchatFields[subscriptions][line]')
          .length > 0
      ) {
        benchatFields.subscriptions.line = document.getElementsByName(
          'benchatFields[subscriptions][line]',
        )[0].value;
      }

      if (
        document.getElementsByName('benchatFields[subscriptions][facebook]')
          .length > 0
      ) {
        benchatFields.subscriptions.facebook = document.getElementsByName(
          'benchatFields[subscriptions][facebook]',
        )[0].value;
      }

      return benchatFields;
    };

    $scope.onCountryCodeChange = function (countryData) {
      $scope.countryMobilePattern = createMobileRegex(countryData.iso2);
      $scope.resource.countryCallingCode = countryData.dialCode;
      $scope.resource.countryCode = countryData.iso2;

      const isValid = $scope.countryMobilePattern.test(
        $scope.resource.mobilePhone,
      );
      $scope.userForm.mobilePhone.$setValidity('pattern', isValid);
    };

    $scope.formatMobilePhone = function () {
      const { mobilePhone, countryCode } = $scope.resource;

      if (
        !mobilePhone ||
        !countryCode ||
        !intlTelInputUtils.isValidNumber(mobilePhone, countryCode)
      )
        return;

      $scope.resource.mobilePhone = intlTelInputUtils
        .formatNumber(
          '+' + $scope.resource.countryCallingCode + mobilePhone,
          '',
          intlTelInputUtils.numberFormat.NATIONAL,
        )
        .replace(/\D/g, '');
    };

    $scope.isGreaterThanMaximumDate = function () {
      return (
        $scope.temp.birthday > $scope.maxBirthdayDate &&
        $scope.temp.birthday < new Date()
      );
    };

    $scope.isFeatureDate = function () {
      return $scope.temp.birthday > new Date();
    };

    $scope.registrationFormSubmit = function () {
      $scope.userForm.errorMessages = null;
      $scope.userForm.showUnVerifiedMessage = false;
      $scope.resource.subscribed_email_types = [];

      angular.forEach($scope.subscription_tags, function (value, key) {
        if (value) {
          $scope.resource.subscribed_email_types.push(key);
        }
      });

      if ($scope.temp['birthday'] != undefined) {
        $scope.resource.birthday = $scope.temp['birthday'].toDateString();
      } // sometimes date is saved wrong otherwise

      var path = '/api/users';
      if (!$scope.isMobilePhoneSignUp()) {
        $scope.resource.email = $scope.resource.email.toLowerCase();
      }
      $scope.resource.mobile_phone = $scope.resource.mobilePhone;
      $scope.resource.country_calling_code = $scope.resource.countryCallingCode;
      $scope.resource.time_zone_offset = timeZoneOffset;

      var benchatFields = renderBenchatFields();

      return $q(function (resolve, reject) {
        $http({
          method: 'POST',
          url: path,
          data: {
            user: $scope.resource,
            benchatFields: benchatFields,
            recaptchable: true,
          },
        })
          .then(function (res) {
            var data = res.data;
            var redirect_to = data.redirect_to;
            slPixelService.hdTracking('signUp');
            if (
              redirect_to === '/cart' ||
              (redirect_to && redirect_to.indexOf('confirmation') > 0)
            ) {
              $window.location.href = redirect_to;
            } else if (redirect_to) {
              var fbc = $cookies.get('_fbc') || '';
              var fbp = $cookies.get('_fbp') || '';
              $window.location.href =
                '/sign_up_confirmation?fbc=' + fbc + '&fbp=' + fbp;
            }

            $scope.userForm.successMessages = [data.message];
            $scope.userForm.submitting = false;
            resolve();
          })
          .catch(function (res) {
            var data = res.data;
            $scope.userForm.errorMessages = data.error_messages;

            if (data.error) {
              if (data.reached_rate_limit)
                $scope.userForm.errorMessages = data.error;
              $scope.userForm.showUnVerifiedMessage = !data.reached_rate_limit;
              $scope.unConfirmedEmail = data.unconfirmed_email;
            }
            $scope.userForm.submitting = false;

            reject();
          });
      });
    };

    $scope.isMobilePhoneSignUp = function () {
      return (
        featureService.hasFeature('mobile_signup_p2') &&
        $scope.resource.isMobilePhoneSignUp
      );
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting || $scope.userForm.$invalid) {
        return;
      }

      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      if ($scope.isMobilePhoneSignUp()) {
        $scope.mobileValidationFormSubmit();
      } else {
        $scope
          .registrationFormSubmit()
          .then(_.noop, _.noop)
          .finally(function () {
            RecaptchaService.reset('sign-up-recaptcha');
          });
      }
    };

    $scope.changeSignupSelection = function () {
      if ($scope.isMobilePhoneSignUp()) {
        $scope.resource.email = null;
      } else {
        $scope.resource.mobilePhone = null;
      }
    };
  },
]);

import { onTabActive } from '../features/member-center';

app.controller('CouponsController', [
  '$scope',
  'promotionService',
  '$filter',
  '$element',
  '$location',
  'memberGetCouponPopupServices',
  function (
    $scope,
    promotionService,
    $filter,
    $element,
    $location,
    memberGetCouponPopupServices,
  ) {
    $scope.$filter = $filter;
    $scope.memberPoints = [];
    $scope.pagination = {
      limit: 5,
      page: 0,
      maxPage: 0,
    };
    $scope.total = {
      valid: 0,
      invalid: 0,
    };
    $scope.coupons = [];

    var rawOffset = new Date().getTimezoneOffset() / -60;
    $scope.timezoneOffset =
      rawOffset < 0 ? rawOffset : '+' + Math.abs(rawOffset);

    $scope.state = {
      loadingItems: false,
      type: 'valid',
    };

    $scope.setType = function (type) {
      $scope.state.type = type;
      $scope.coupons = [];
      $scope.pagination.page = $scope.pagination.maxPage = 0;
      $scope.getCoupons(type);
    };

    memberGetCouponPopupServices.openGetCouponPopup({
      type: 'coupon',
      onConfirm: () => {
        const searches = $location.search();
        const couponResult = searches['result'];
        const couponStatus = searches['coupon_status'] || 'valid';
        if (couponResult === 'succeed') {
          $scope.setType(couponStatus);
        }
      },
    });

    $scope.getCoupons = function () {
      $scope.state.loadingItems = true;
      $scope.pagination.page++;
      promotionService
        .getCoupons($scope.pagination.page, 5, $scope.state.type)
        .then(function (res) {
          $scope.pagination.maxPage = Math.ceil(res.data.data.total / 5);
          $scope.state.loadingItems = false;
          var newCoupons = _.map(res.data.data.items, function (item) {
            return Object.assign(item, {
              showExpireTag: dayjs(item.end_at).isBefore(dayjs()),
              formattedTime: dayjs(item.end_at).format(
                $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                  ? 'DD/MM/YYYY HH:mm'
                  : 'YYYY/MM/DD HH:mm',
              ),
              formattedStartTime: dayjs(item.start_at).format(
                $scope.mainConfig.localeData.loadedLanguage.code === 'vi'
                  ? 'DD/MM/YYYY HH:mm'
                  : 'YYYY/MM/DD HH:mm',
              ),
            });
          });
          $scope.coupons = $scope.coupons.concat(newCoupons);
        });
    };

    $scope.initCouponsCount = function () {
      _.forEach(['comingSoon', 'valid', 'invalid'], function (type) {
        promotionService.getCoupons(1, 0, type).then(function (res) {
          $scope.total[type] = res.data.data.total;
        });
      });
    };

    $scope.initCouponsCount();
    $scope.getCoupons('valid');

    onTabActive($element, () => {
      history.replaceState(null, null, `coupons${location.search}`);
    });
  },
]);

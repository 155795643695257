app.directive('facebookMessengerCheckboxV2', [
  'mainConfig',
  'benchatService',
  'imageServiceEndpoint',
  '$timeout',
  '$q',
  function (mainConfig, benchatService, imageServiceEndpoint, $timeout, $q) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.facebook-messenger-checkbox-v2.html'),
      scope: {
        benchatConfig: '=',
        benchatChannel: '=',
        customerId: '=',
        orderId: '=',
        sessionId: '=',
        topics: '=',
      },
      link: function (scope) {
        scope.imageServiceEndpoint = imageServiceEndpoint;
        scope.merchant = mainConfig.merchantData;
        scope.benchatSubscriptions = [];
        scope.state = {
          isSubscriptionLoaded: false,
        };
        scope.customerIdentifier = scope.customerId || scope.sessionId;
        scope.origin = window.location.origin.replace('http://', 'https://');

        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        var getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerIdentifier)
            .then(function (result) {
              var subscriptions = result.data.subscriptions;
              if (_.isEmpty(subscriptions)) {
                return [];
              }
              return benchatService.getFacebookActiveSubscriptions(
                scope.topics,
                subscriptions,
              );
            });
        };

        var reloadCheckbox = function () {
          // need to wait for all dom attributes in checkbox to be ready before re-rendering it
          $timeout(function () {
            FB.XFBML.parse();
          }, 0);
        };

        var init = function () {
          getSubscriptions().then(function (subscription) {
            scope.state.isSubscriptionLoaded = true;
            scope.benchatSubscriptions = subscription;
            reloadCheckbox();
          });
        };

        scope.optOut = function () {
          $q.all(
            _.map(scope.benchatSubscriptions, function (subscription) {
              benchatService.deactivateSubscription(subscription._id);
            }),
          ).then(function () {
            init();
          });
        };

        var activateSubscriptions = function (subscriptions) {
          return $q.all(
            _.map(subscriptions, function (subscription) {
              benchatService.activateSubscription(subscription._id, {
                subscription: subscription,
              });
            }),
          );
        };

        FB.Event.subscribe('send_to_messenger', function (e) {
          if (e.event == 'opt_in') {
            // before triggering facebook, create subscription in benchat first
            benchatService
              .patchSubscriptions(
                _.map(scope.topics, function (topic) {
                  return {
                    userId: scope.customerIdentifier,
                    channelOwnerId: mainConfig.merchantData._id,
                    userRef: scope.userRef,
                    platform: 'FACEBOOK',
                    topic: topic,
                  };
                }),
              )
              .then(
                function (response) {
                  activateSubscriptions(response.data).then(function () {
                    init();
                  });
                },
                function () {
                  // something went wrong when creating subscription in benchat
                },
              );
          }
        });

        init();
      },
    };
  },
]);

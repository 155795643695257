/**
 * link: https://shopline.atlassian.net/wiki/spaces/EN/pages/2404122807/intlTelInputCheckout+intlTelInputService
 */
app.directive('intlTelInputCheckout', [
  'mainConfig',
  'intlTelInputService',
  function (mainConfig, intlTelInputService) {
    return {
      restrict: 'C',
      link: function (scope, element, attrs) {
        const handleCountryChange = () => {
          const hasPlaceholderAndHint = element.parents(
            '.iti--allow-dropdown.without-country-code',
          ).length;
          if (
            hasPlaceholderAndHint &&
            intlTelInputService.getCountry('abbr', element)
          )
            intlTelInputService.removePlaceholderAndHint(element);
          if (
            !hasPlaceholderAndHint &&
            !intlTelInputService.getCountry('abbr', element)
          )
            intlTelInputService.insertPlaceholderAndHint(element);
        };

        /* setup listener */
        function cleanUp() {
          element.off();
        }
        element.on('countrychange', handleCountryChange);
        scope.$on('$destroy', cleanUp);

        if (attrs.isFormat === 'true') {
          element.on('countrychange', () =>
            intlTelInputService.formatPhone(element),
          );
          element.on('input', () => intlTelInputService.formatPhone(element));
        }
      },
    };
  },
]);

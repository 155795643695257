app.service('promotionService', [
  '$http',
  function ($http) {
    this.checkRedeemGiftPromotionExist = function () {
      return $http({
        url: 'api/promotions/is_redeem_gift_promotion_exist',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
    };
    this.getCoupons = function (page, limit, scope) {
      return $http({
        method: 'GET',
        url:
          '/api/users/promotions?page=' +
          page +
          '&limit=' +
          limit +
          '&show_coupon=true&usage=coupon&scope=' +
          scope,
      });
    };
  },
]);

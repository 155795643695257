app.service('RecaptchaService', function () {
  this.reset = function (elementId) {
    if (
      typeof grecaptcha !== 'undefined' &&
      document.querySelector('#g-recaptcha-response')
    ) {
      if (document.querySelector('.g-recaptcha#' + elementId) === null) {
        return;
      }
      var widgetId = document.querySelector('.g-recaptcha#' + elementId).dataset
        .widgetId;
      grecaptcha.reset(widgetId);
    }
  };

  this.execute = function (elementId) {
    if (
      typeof grecaptcha !== 'undefined' &&
      document.querySelector('#g-recaptcha-response')
    ) {
      if (document.querySelector('.g-recaptcha#' + elementId) === null) {
        return;
      }
      var widgetId = document.querySelector('.g-recaptcha#' + elementId).dataset
        .widgetId;
      grecaptcha.execute(widgetId);
    }
  };
});

app.directive('paypalFastCheckoutBtn', [
  '$q',
  '$http',
  '$filter',
  'mainConfig',
  '$timeout',
  '$rootScope',
  'trackerService',
  'Analytics',
  function (
    $q,
    $http,
    $filter,
    mainConfig,
    $timeout,
    $rootScope,
    trackerService,
    Analytics,
  ) {
    var loadScript = function (u) {
      var d, deferred, o, s, t;
      d = document;
      t = 'script';
      o = d.createElement(t);
      s = d.getElementsByTagName(t)[0];
      o.src = 'https://' + u;
      deferred = $q.defer();
      o.addEventListener(
        'load',
        function () {
          deferred.resolve();
        },
        false,
      );
      s.parentNode.insertBefore(o, s);
      return deferred.promise;
    };
    return {
      template:
        '<div id="paypal-fast-checkout-button-{{position}}"' +
        'type="submit"' +
        'class="ladda-button"' +
        'data-style="slide-down"' +
        'ladda="loading">' +
        '</div>',
      restrict: 'E',
      scope: {
        position: '@',
        clickAction: '&',
      },
      link: function ($scope) {
        var currency = mainConfig.merchantData.base_currency.iso_code.toUpperCase();
        var isPillBtn = ['doris_bien'].includes(
          mainConfig.merchantData.current_theme_key,
        );
        var renderPaypalBtn = function () {
          var payerId = null;
          var orderId = null;
          var productId = null;
          var skipClickActionPositionRegex = /^cart-.*/;
          var currentDeviceType =
            window.innerWidth < 600 ? 'mobile' : 'desktop';
          var spbHeightMapping = {
            'fixed-btn': {
              mobile: 48,
              desktop: 38,
            },
          };

          var spbHeight = spbHeightMapping[$scope.position]
            ? spbHeightMapping[$scope.position][currentDeviceType]
            : 38;

          var timeoutSecs =
            $scope.position ===
            ('quick-cart-modal' || 'quick-cart-modal-bianco')
              ? 300
              : 100;

          var createPaypalOrderAction = function (productId) {
            var payload = productId ? { product_id: productId } : null;
            return $http({
              method: 'POST',
              url: '/api/paypal/create_order',
              data: payload,
            }).then(function (res) {
              var data = res.data;
              orderId = data.order_id;
              payerId = data.payer_id;
              return orderId;
            });
          };
          // timeout for quick-cart-modal btn
          $timeout(function () {
            if ($('#paypal-fast-checkout-button-' + $scope.position).length) {
              paypal
                .Buttons({
                  style: {
                    layout: 'horizontal',
                    size: 'responsive',
                    tagline: false,
                    height: spbHeight,
                    label: 'pay',
                    shape: isPillBtn ? 'pill' : 'rect',
                  },
                  createOrder: function () {
                    var isFastCheckout = !skipClickActionPositionRegex.test(
                      $scope.position,
                    );
                    if (Analytics.configuration.enhancedEcommerce) {
                      Analytics.trackCheckout(1, 'Checkout');
                      Analytics.trackEvent(
                        'UX',
                        'checkout',
                        'Checkout',
                        undefined,
                        true,
                      );
                      Analytics.trackCheckout(2, 'InitateCheckout');
                      Analytics.trackEvent(
                        'UX',
                        'initate_checkout',
                        'InitateCheckout',
                        undefined,
                        true,
                      );
                    }

                    if (isFastCheckout) {
                      return new Promise(function (resolve, reject) {
                        $scope.clickAction();

                        var deregisterCartItemUpdateFailed = $rootScope.$on(
                          'cartItemUpdateFailed',
                          function () {
                            deregisterCartItemUpdateFailed();
                            return resolve(null);
                          },
                        );
                        var deregisterCartItemsUpdated = $rootScope.$on(
                          'cartItemsUpdated',
                          function (event, cartData) {
                            deregisterCartItemsUpdated();
                            const cartItems = $rootScope.currentCart.getFastCheckoutCartItems();
                            const subtotal = $rootScope.currentCart.getFastCheckoutSubtotal();
                            trackerService.fbInitiateCheckout(
                              cartItems,
                              subtotal,
                            );
                            trackerService.track({
                              type:
                                trackerService.generalEventType.START_CHECKOUT,
                              data: {
                                items: cartItems,
                                subtotal,
                              },
                            });

                            productId = cartData.items[0].product_id;
                            return $http({
                              method: 'POST',
                              url: '/api/paypal/create_order',
                              data: {
                                product_id: productId,
                              },
                            })
                              .then(function (res) {
                                var data = res.data;
                                orderId = data.order_id;
                                payerId = data.payer_id;
                                resolve(data.order_id);
                              })
                              .catch(function (err) {
                                reject(err);
                              });
                          },
                        );
                      }).catch(function (err) {
                        alert(err.data.error_messages);
                      });
                    } else {
                      const cartItems = $rootScope.currentCart.getItems();
                      const subtotal = $rootScope.currentCart.getSubtotal();
                      trackerService.fbInitiateCheckout(cartItems, subtotal);
                      trackerService.track({
                        type: trackerService.generalEventType.START_CHECKOUT,
                        data: {
                          items: cartItems,
                          subtotal,
                        },
                      });
                      return new Promise(function (resolve, reject) {
                        return createPaypalOrderAction()
                          .then(function (orderId) {
                            resolve(orderId);
                          })
                          .catch(function (err) {
                            reject(err);
                          });
                      }).catch(function (err) {
                        alert(err.data.error_messages);
                      });
                    }
                  },
                  onApprove: function () {
                    var productIdQuery = productId
                      ? '&product_id=' + productId
                      : '';
                    window.location =
                      '/fast_checkout?payer_id=' +
                      payerId +
                      '&order_id=' +
                      orderId +
                      productIdQuery;
                  },
                })
                .render('#paypal-fast-checkout-button-' + $scope.position);
            }
          }, timeoutSecs);
        };
        if (window.location.pathname === '/fast_checkout') {
          // do nothing
        } else if (!window.paypal) {
          loadScript(
            'www.paypal.com/sdk/js?client-id=' +
              mainConfig.paypalCnClientId +
              '&currency=' +
              currency +
              '&commit=false' +
              '&locale=' +
              $filter('translate')('paypal.language'),
          ).then(function () {
            renderPaypalBtn();
          });
        } else {
          renderPaypalBtn();
        }
      },
    };
  },
]);

app.factory('IntlTelClass', [
  'intlTelInputService',
  'mainConfig',
  function (intlTelInputService, mainConfig) {
    return class IntlTelClass {
      /**
       * @param { jQuery objects } element
       * @param { defaultCountry?, phoneValue: { phone, dialCode }} config
       */
      constructor(element, config) {
        this.element = element;
        this.config = config;
        const {
          defaultCountry = mainConfig.requestCountry.toLowerCase(),
          phoneValue = {},
          ...rest
        } = this.config ?? {};
        const finalCountry = Number.isInteger(Number(defaultCountry))
          ? intlTelInputService.toAbbr(defaultCountry)
          : defaultCountry;
        intlTelInputService.initSetting(this.element, {
          userCountry: finalCountry,
          ...rest,
        });
        intlTelInputService.initValue(this.element, {
          defaultCountry: finalCountry,
          ...phoneValue,
        });
      }

      getCountry(type) {
        return intlTelInputService.getCountry(type, this.element);
      }

      setCountry(type, value) {
        return intlTelInputService.setCountry(type, this.element, value);
      }

      getPhone(type) {
        return intlTelInputService.getPhone(type, this.element);
      }

      setPhone(type, value) {
        return intlTelInputService.setPhone(type, this.element, value);
      }

      setOnCountryChange(callback) {
        return this.element.on('countrychange', callback);
      }

      reset(newConfig) {
        return intlTelInputService.reset(
          this.element,
          newConfig ?? this.config,
        );
      }
    };
  },
]);

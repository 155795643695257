app.directive('productVariantGallery', [
  '$timeout',
  '$lightbox',
  '$rootScope',
  'imageService',
  function ($timeout, $lightbox, $rootScope, imageService) {
    return {
      templateUrl: require('../../../../../public/themes/v1/default/views/product.variant-gallery.html'),
      scope: true,
      restrict: 'AE',
      link: function (scope, element) {
        var isQuickCart = element.attr('is-quick-cart');
        var defaultSize =
          isQuickCart &&
          $rootScope.mainConfig.merchantData.current_theme_key === 'skya'
            ? 62
            : element.attr('item-size');

        var enableCarousel = element.attr('enable-carousel') === 'true'; // for theme that displays all images, ex. dusk
        scope.isShowAllImageEnabled =
          element.attr('enable-show-all-image') === 'true'; // for theme that displays all images, ex. kingsman_v2
        scope.isZoomInEnabled = element.attr('enable-zoom-in') === 'true';
        scope.shouldShowAllImage =
          ((scope.isShowAllImageEnabled &&
            window.matchMedia('(min-width: 992px)').matches) ||
            shouldShowAllImageCustom()) &&
          !enableCarousel;
        scope.shouldZoomIn =
          scope.isZoomInEnabled &&
          window.matchMedia('(min-width: 992px)').matches;
        var initItem = function () {
          scope.itemSize = parseInt(element.attr('item-size'), 10);
          scope.itemMargin = parseInt(element.attr('item-margin'), 10);
          scope.sizeKey = 'original';
        };

        initItem();

        scope.$watch(
          function () {
            return element.attr('item-size') + '' + element.attr('item-margin');
          },
          function () {
            $timeout(function () {
              initItem();
            }, 10);
          },
        );

        var resizeAction = _.throttle(function () {
          $timeout(function () {
            scope.shouldShowAllImage =
              ((scope.isShowAllImageEnabled &&
                window.matchMedia('(min-width: 992px)').matches) ||
                shouldShowAllImageCustom()) &&
              !enableCarousel;
            scope.shouldZoomIn =
              scope.isZoomInEnabled &&
              window.matchMedia('(min-width: 992px)').matches;
            if ($(window).width() <= 768 && !isQuickCart) {
              element.attr('item-size', (($(window).width() - 40) * 2) / 7);
              scope.maxImageContain = Math.floor(
                $('.variant-gallery-scroll-container').width() /
                  ((($(window).width() - 40) * 2) / 7 + scope.itemMargin),
              );
            } else {
              element.attr('item-size', defaultSize);
              scope.maxImageContain = Math.floor(
                $('.variant-gallery-scroll-container').width() /
                  (parseFloat(defaultSize) + scope.itemMargin),
              );
            }
            if (scope.shouldZoomIn) {
              $timeout(function () {
                setZoomIn();
              }, 0);
            }
          }, 10);
        }, 500);
        $(window).resize(resizeAction);
        resizeAction();

        function setZoomIn() {
          scope.medias.forEach(function (media) {
            var originImgUrl = media.images.original.url;
            var $zoomImg = $('.js-variant-gallery-stage a').find(
              'img[src = "' + originImgUrl + '"]',
            );
            $zoomImg
              .parent()
              .trigger('zoom.destroy')
              .zoom({
                url: imageService.getMediaImageUrl(media, { size: '1000x' }),
              });
          });
        }

        function resizeMainImage() {
          var imgToBeReplaced = document.querySelector('#sl-product-image');
          imgToBeReplaced.src = imageService.getLargeImage(imgToBeReplaced.src);
        }

        var initGallery = function () {
          scope.medias = scope.product.media;
          scope.offset = 0;
          scope.selectedMedia = scope.medias[0];

          if (scope.product.variations && scope.product.variations.length > 0) {
            scope.$watch('variationSelected.key', function (
              newValue,
              oldValue,
            ) {
              if (!scope.variationSelected) {
                return;
              }
              $rootScope.$broadcast('variationSelected.key.change');
              var oldVariation = _.find(scope.product.variations, function (v) {
                return v.key == oldValue;
              });
              var variationMedia = null;
              if (scope.variationSelected.media) {
                variationMedia = scope.variationSelected.media;
              }

              var productMedias = scope.product.media;
              if (variationMedia) {
                productMedias = _.reject(productMedias, function (m) {
                  return m._id == variationMedia._id;
                });
                productMedias.unshift(variationMedia);
              }

              var resetIndex =
                (oldVariation.media == null && variationMedia == null) == false;
              scope.medias = productMedias;
              if (resetIndex) {
                scope.selectMedia(scope.medias[0]);
              }

              if (scope.shouldZoomIn) {
                $timeout(function () {
                  setZoomIn();
                }, 0);
              }
            });
          }

          $timeout(function () {
            resizeMainImage();
            $rootScope.$emit('productGallery.load');
          }, 0);
        };

        $rootScope.$on('productGallery.load', function () {
          $timeout(function () {
            var gallery = document.querySelector('product-variant-gallery');
            var isHorizontal = gallery.getAttribute('is-horizontal');
            if (isHorizontal) {
              var itemSize = Number(gallery.getAttribute('item-size'));
              var itemMargin = Number(gallery.getAttribute('item-margin'));
              var slider = gallery.querySelector('.variant-gallery-slider');
              slider.style.width =
                slider.querySelectorAll('.js-variant-image').length *
                  (itemSize + itemMargin) +
                'px';
            }
          }, 200);
        });

        scope.$watch('product', function (newValue) {
          if (newValue) {
            initGallery();
          }
        });

        var scrollXTo = function (element, to, duration) {
          if (duration <= 0 || !element) return;

          var difference = to - element.scrollLeft;
          var perTick = (difference / duration) * 10;

          setTimeout(function () {
            element.scrollLeft = element.scrollLeft + perTick;
            if (element.scrollLeft === to) return;
            scrollXTo(element, to, duration - 10);
          }, 10);
        };

        scope.$watch(
          'selectedMedia',
          function () {
            $timeout(function () {
              resizeMainImage();
            }, 0);
          },
          true,
        );

        scope.selectMedia = function (media, options) {
          scope.selectedMedia = media;
          var offset =
            scope.medias.indexOf(media) * (scope.itemSize + scope.itemMargin);

          if (!options) {
            options = {};
          }

          if (options.galleryScroll !== false) {
            scrollXTo(
              element.find('.variant-gallery-scroll-container')[0],
              offset,
              200,
            );
          }

          scope.$emit('product.gallery.selectMedia', scope.selectedMedia);

          if (options.scrollToImg) {
            var index = scope.medias.indexOf(media);
            scrollToImage(index);
          }
        };

        function scrollToImage(index) {
          var $targetImg = $('.js-variant-gallery-stage a:eq(' + index + ')');
          var $targetNav = window.matchMedia('(min-width: 1200px)').matches
            ? $('.js-sticky')
            : $('.js-navigationbar-mobile');
          var navHeight =
            $targetImg.offset().top > 200 ? $targetNav.height() : 0;
          $('html, body').animate({
            scrollTop: $targetImg.offset().top - navHeight,
          });
        }

        scope.canSelectNext = function () {
          return (
            scope.medias &&
            scope.medias.indexOf(scope.selectedMedia) < scope.medias.length - 1
          );
        };

        scope.selectNext = function (options) {
          if (!scope.canSelectNext()) {
            return;
          }
          var index = scope.medias.indexOf(scope.selectedMedia);
          scope.selectMedia(scope.medias[index + 1], options);
        };

        scope.canSelectPrevious = function () {
          return scope.medias && scope.medias.indexOf(scope.selectedMedia) > 0;
        };

        scope.selectPrevious = function (options) {
          if (!scope.canSelectPrevious()) {
            return;
          }
          var index = scope.medias.indexOf(scope.selectedMedia);
          scope.selectMedia(scope.medias[index - 1], options);
        };

        scope.enlargeImage = function (targetMedia, $event) {
          // these themes only enlarge image when click button
          if (
            ($rootScope.mainConfig.merchantData.current_theme_key ===
              'philia' ||
              $rootScope.mainConfig.merchantData.current_theme_key ===
                'varm') &&
            $event.target.tagName === 'IMG'
          ) {
            return;
          }
          $lightbox.open(scope.medias, scope.medias.indexOf(targetMedia), {
            size: 'lg',
          });
        };

        scope.getSelectedMediaUrl = function () {
          return (
            scope.selectedMedia.default_image_url ||
            scope.getMediaImageUrl(scope.selectedMedia)
          );
        };

        scope.getThumbnailStyle = function (media) {
          return {
            'background-image':
              'url(' +
              (media.thumb_image_url || media.images.original.url) +
              ')',
          };
        };

        scope.getMediaImageUrl = function (media) {
          var size =
            $rootScope.mainConfig.merchantData.current_theme_key === 'dusk' &&
            scope.medias.length === 1
              ? '1000x'
              : '800x';

          return imageService.getMediaImageUrl(media, { size: size });
        };

        function shouldShowAllImageCustom() {
          var enableTheme = ['sangria', 'bianco', 'dusk'];
          var currentTheme =
            $rootScope.mainConfig.merchantData.current_theme_key;
          return enableTheme.includes(currentTheme);
        }
      },
    };
  },
]);

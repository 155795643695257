angular
  .module('sl.promotion-title-filter', [])
  .filter('promotionTitle', [
    'mainConfig',
    '$filter',
    function (mainConfig, $filter) {
      return function (promotion) {
        var getLocalizedDiscountValue = function () {
          var discountValue;
          var langCode = mainConfig.localeData.loadedLanguage.code;
          if (
            langCode &&
            langCode.startsWith('zh') &&
            promotion.discount_type == 'percentage'
          ) {
            discountValue = 100 - promotion.discount_percentage;
            if (discountValue < 10) {
              discountValue = '0.' + discountValue;
            }
            discountValue = (discountValue + '').replace(/0$/, '');
          } else if (promotion.discount_type == 'percentage') {
            discountValue = promotion.discount_percentage;
          } else {
            discountValue = promotion.discount_amount.dollars;
          }

          return $filter('translate')(
            'promotions.section.discount_summary.discount_value',
            {
              currency: mainConfig.merchantData.base_currency.alternate_symbol,
              value: discountValue,
              type: promotion.discount_type,
            },
          );
        };

        var title = '';

        if (!_.isEmpty(promotion.title_translations)) {
          title = $filter('translateModel')(promotion.title_translations);
        }

        var conditionScope,
          conditionOn,
          conditionValue,
          criteriaString,
          benefitsString;

        if (promotion.is_extend_promotion || title == '') {
          if (promotion.is_extend_promotion) title += ' - ';

          //generate discount title by its properties
          if (promotion.condition) {
            conditionScope =
              promotion.condition.whitelisted_product_ids.length > 0
                ? 'discounted_products'
                : 'discounted_subtotal';
            conditionOn = $filter('translate')(
              'promotions.section.discount_summary.criteria.scope_' +
                conditionScope,
            );

            if (promotion.condition.min_price) {
              conditionValue = promotion.condition.min_price.label;
            } else {
              conditionValue = $filter('translate')(
                'promotions.section.discount_summary.criteria.type_quantity',
                {
                  count: promotion.condition.min_item_count,
                },
              );
            }

            criteriaString = $filter('translate')(
              'promotions.section.discount_summary.criteria.scope_' +
                conditionScope +
                '.content',
              {
                condition_value: conditionValue,
              },
            );
          }

          benefitsString = $filter('translate')(
            'promotions.section.discount_summary.set.on_' +
              promotion.discount_on,
            {
              discount_value: getLocalizedDiscountValue(),
              discount_on: promotion.discount_on,
              criteria_on: conditionOn,
            },
          );

          title += $filter('translate')(
            'promotions.section.discount_summary.content',
            {
              criteria: criteriaString,
              benefits: benefitsString,
            },
          );
        }

        return title;
      };
    },
  ])
  .filter('promotionTag', [
    'mainConfig',
    '$filter',
    function (mainConfig, $filter) {
      return function (promotion) {
        var tag_key = '';
        if (promotion.discount_type == 'free_shipping') {
          tag_key = 'free_shipping';
        } else if (promotion.coupon_item.coupon_code) {
          tag_key = 'coupon';
        } else if (promotion.whitelisted_membership_tier_ids.length > 0) {
          tag_key = 'membership';
        } else {
          tag_key = 'promotion';
        }
        return $filter('translate')('checkout.promotion.tags.' + tag_key);
      };
    },
  ])
  .filter('promotionDiscountPercentage', [
    'mainConfig',
    '$filter',
    function (mainConfig, $filter) {
      return function (discountPercentage) {
        var discountValue;
        var langCode = mainConfig.localeData.loadedLanguage.code;
        if (langCode && langCode.startsWith('zh')) {
          discountValue = 100 - discountPercentage;
          if (discountValue < 10) {
            discountValue = '0.' + discountValue;
          }
          discountValue = (discountValue + '').replace(/0$/, '');
        } else {
          discountValue = discountPercentage;
        }

        return $filter('translate')(
          'promotions.section.discount_percentage.caption',
          {
            value: discountValue,
          },
        );
      };
    },
  ]);

app.directive("checkoutInvoiceForm", [
  '$compile'
  '$http'
  '$filter'
  'checkoutService'
  'merchantService'
  'mainConfig'
  'staticResourceHost'
  (
    $compile
    $http
    $filter
    checkoutService
    merchantService
    mainConfig
    staticResourceHost
  ) ->
    {
      restrict: 'A'
      scope: {
        isLoading: '='
        countries: '='
        isStorePickup: '='
      }
      link: (scope, element, attrs) ->
        scope.state = {}

        if scope.isStorePickup
          scope.state.invoiceAddressType =
            if mainConfig.currentUser && mainConfig.currentUser.delivery_addresses.length
            then 'select-from-saved'
            else 'new'
        else
          scope.state.invoiceAddressType = 'same-as-order'

        unwatch = scope.$watch('countries.length', (length) ->
          if length
            unwatch()
            scope.state.country = _.findWhere(scope.countries, { code: 'TW' })

            if mainConfig.currentUser
              scope.savedAddress = mainConfig.currentUser.delivery_addresses.map((addr) ->
                return _.findWhere(scope.countries, { code: addr.country }).name +
                  (addr.postcode || '') +
                  (addr.state || '') +
                  (addr.city || '') +
                  (addr.address_2 || '') +
                  (addr.address_1 || '')
              )
        )

        scope.districtData = {}

        scope.$watch('state.country.code', (countryCode) ->
          scope.state.region = undefined
          scope.state.district = undefined
          scope.state.address = undefined
          if ['TW', 'HK'].indexOf(countryCode) != -1 && !scope.districtData[countryCode]
            url = staticResourceHost + 'web/v1/translations/districts_' + countryCode.toLowerCase() + '.json';
            $.getJSON(url, (data) ->
              scope.districtData[countryCode] = {
                region: [],
                district: []
              }

              data.forEach((item) ->
                scope.districtData[countryCode][item.type].push(item.data)
              )

              scope.$digest();
            )
        )

        scope.$watch('state.region', () ->
          scope.state.district = undefined
          scope.state.address = undefined
        )

        scope.buildNewAddress = (state) ->
          if ['TW', 'HK'].indexOf(state.country.code) != -1
            _.findWhere(scope.countries, { code: state.country.code }).name +
              state.district.postcode +
              $filter('translateModel')(state.region) +
              $filter('translateModel')(state.district).split(' ')[1] +
              state.address
          else
            _.findWhere(scope.countries, { code: state.country.code }).name + state.address

        scope.calculateMailingAddressMaxLength = () ->
          return 100 - (scope.state.country.name +
            $filter('translateModel')(scope.state.region) +
            $filter('translateModel')(scope.state.district)
          ).length;

        reloadForm = () ->
          return if scope.isLoading
          scope.isLoading = true
          $ ".invoice-form .loading-icon"
            .show()
          $ ".invoice-form"
            .find "input,select"
            .prop 'disabled', true
          formData = _.object(
            _.map $(".invoice-form").find("input,select"), (field) -> 
              [_.last(field.name.match /([a-zA-Z0-9\_\-]+)/g), field.value]
          )

          checkoutService.requestPartial("checkout", "invoice_form", formData)
            .then (res) ->
              element.html($compile(res.data)(scope))
              $ ".invoice-form"
                .find "input,select"
                .prop 'disabled', false
              $(".invoice-form .loading-icon").hide()
              scope.isLoading = false
            .catch (error) ->
              # console.error("Unable to load invoice form")
              # scope.isLoading = false

        element.on "change", "select#invoice-type, select#carrier-type", ((event) -> reloadForm())
        element.on "destroy", (() -> element.off()) # Unbind events
    }
])

do ->
  itemVariations = (cartService, $timeout, moneyService, imageService, mainConfig, $rootScope) ->
    link = (scope, element, attrs) ->
      multiSelect = attrs.multiselect == 'true'

      replaceCart = (variation) ->
        return if scope.pageId == undefined
        if scope.skipReplaceCart
          scope.skipReplaceCart = false
          return

        data = {
          quantity: if variation then parseInt(scope.itemQuantity[variation.key]) else scope.itemQuantity,
          variation: variation,
          blacklisted_payment_option_ids: scope.product.blacklisted_payment_option_ids,
          blacklisted_delivery_option_ids: scope.product.blacklisted_delivery_option_ids
        }

        if scope.product.subscription_enabled
          data['type'] = 'subscription_product'
        cartService
          .addItem(scope.product._id, data, undefined, { skip_calculate_order: false, cache_key: mainConfig.currentPath })
          .then(() ->
            $rootScope.overLimit = false
          )
          .catch((error) ->
            if error.type is 'overLimit'
              $rootScope.overLimit = true
              scope.skipReplaceCart = true
              scope.itemQuantity[variation.key] = 0;
              window.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
              });
          )
          .finally(() -> 
            scope.reload()
          )

      applyDotdotdot = () -> $('.product-des').dotdotdot({ wrap: "letter" })

      initPage = () ->
        scope.state.cartCleared = true
        $(window).on 'resize', _.debounce(applyDotdotdot, 300)
        $timeout(
          applyDotdotdot()
        )

      if (multiSelect)
        cartService.destroyCart().then(() ->
          initPage()
          scope.reload()
        )
      else
        # wait for `scope.variationSelected` being set
        _replaceCart = replaceCart
        $timeout(() ->
          _replaceCart(scope.variationSelected)
          initPage()
        )

      # prevent triggering addCartItem during init quantities
      # to bypass the first callback of scope.$watch itemQuantity
      replaceCart = _.after(2, replaceCart)

      updateCart = _.debounce(((variation) ->
        replaceCart(variation)
        scope.$emit "checkout.cart.content.loaded"
      ), if multiSelect then 0 else 500)

      scope.getSelectedMediaUrl = () ->
        if scope.product.variations.length == 0 || scope.variationSelected.media_id == null
          # no variations or no variation img
          url = imageService.getMediaImageUrl(scope.product.media[0], { size: '200x200f' })
        else # variation image exist
          url = imageService.getMediaImageUrl(scope.variationSelected.media, { size: '200x200f' })
        return url

      scope.getSelectedMediaAlt = () ->
        if scope.product.variations.length == 0 || scope.variationSelected.media_id == null
          alt = scope.product.media[0].alt_translations
        else
          alt = scope.variationSelected.media.alt_translations
        return alt

      scope.getItemPrice = () -> if scope.product.variations.length then scope.variationsPriceMappings[scope.variationSelected.key] else scope.itemPrice

      scope.updateItemQuantity = (variation, incrment) ->
        regex = /^[0-9]{1,5}$/

        if (scope.product.variations.length)
          scope.itemQuantity[variation.key] = parseInt(scope.itemQuantity[variation.key])
          scope.itemQuantity[variation.key] = 0 if !regex.test(scope.itemQuantity[variation.key])
          if incrment
            scope.itemQuantity[variation.key] += incrment

          if (!multiSelect && incrment && scope.itemQuantity[variation.key] <= 0)
            scope.itemQuantity[variation.key] = 1
        else
          scope.itemQuantity = parseInt(scope.itemQuantity)
          scope.itemQuantity = 0 if !regex.test(scope.itemQuantity)
          if incrment
            scope.itemQuantity += incrment

          if (!multiSelect && scope.itemQuantity <= 0)
            scope.itemQuantity = 1

        updateCart(variation)

      if (!scope.product.variations.length)
        scope.$watch('itemQuantity', () -> scope.updateItemQuantity())
      else if multiSelect
        scope.product.variations.forEach((variation) ->
          scope.$watch('itemQuantity[\'' + variation.key + '\']', () -> scope.updateItemQuantity(variation))
        )
      else
        # variation without multiSelect
        scope.$watch('itemQuantity[\'' + scope.variationSelected.key + '\']', () -> scope.updateItemQuantity(scope.variationSelected))

      if !multiSelect
        unwatch = null
        scope.$watch 'variationSelected', (newVariation, oldVariation) ->
          if (newVariation && oldVariation)
            scope.itemQuantity[newVariation.key] = scope.itemQuantity[oldVariation.key]
            scope.itemQuantity[oldVariation.key] = 0
            if (scope.itemQuantity[newVariation.key] == 0)
              scope.itemQuantity[newVariation.key] = 1

          updateCart(newVariation)
          scope.$emit "checkout.cart.content.loaded"
          $timeout(unwatch) if unwatch
          unwatch = scope.$watch('itemQuantity[\'' + scope.variationSelected?.key + '\']', () -> scope.updateItemQuantity(scope.variationSelected))

    directive =
      link        : link
      templateUrl : (elements, attrs) ->
        if attrs.multiselect == 'true' then require('../../../../../public/themes/v1/default/views/item.variations-multiselect.html') else require('../../../../../public/themes/v1/default/views/item.variations.html')
      restrict    : "E"

    return directive

  itemVariations.$inject = [
    'cartService'
    '$timeout'
    'moneyService'
    'imageService'
    'mainConfig'
    '$rootScope'
  ]

  app.directive('itemVariations', itemVariations)

app.service('addressNodesService', [
  '$http',
  function ($http) {
    this.getFirstLevel = function (country, delivery_option_id) {
      return $http({
        method: 'GET',
        url: 'api/address_nodes?',
        params: {
          'countries[]': country,
          delivery_option_id: delivery_option_id,
          with_children: true,
          with_restrict_level: 1,
        },
      });
    };

    this.getLevel = function (payload) {
      var params = {
        with_children: true,
        'countries[]': payload.country,
        address_node_id: payload.nodeId,
        delivery_option_id: payload.deliveryOptionId,
      };

      if (payload.withAllChildren) {
        Object.assign(params, {
          'with_all_children[]': payload.country,
          with_restrict_level: 3,
        });
      }

      return $http({
        method: 'GET',
        url: 'api/address_nodes?',
        params: params,
      });
    };
  },
]);

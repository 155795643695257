app.run([
  '$rootScope',
  'mainConfig',
  '$filter',
  'productService',
  'trackerService',
  'slFeatureService',
  function (
    $rootScope,
    mainConfig,
    $filter,
    productService,
    trackerService,
    slFeatureService,
  ) {
    const baseCurrencyCode = mainConfig.merchantData.base_currency_code;
    const { generalEventType } = trackerService;
    const ttqTrack =
      !slFeatureService.hasFeature('tiktok_pixel_api') ||
      window.ttq == undefined
        ? null
        : window.ttq;

    const getCheckoutAndPurchasePayload = function (props) {
      const { items, subtotal } = props;
      return {
        contents: _.map(items, function (item) {
          const { total, quantity = 0 } = item;
          const { dollars = 0 } = total || {};
          return {
            content_id: productService.getSku(
              item._id,
              item.sku,
              item.variation,
            ),
            content_name: $filter('translateModel')(item.title_translations),
            currency: baseCurrencyCode,
            price: dollars,
            value: dollars * quantity,
            quantity,
            content_type: 'product',
          };
        }),
        value: subtotal?.dollars || 0,
        quantity: _.reduce(
          items,
          function (totalQuantity, product) {
            return totalQuantity + (product.quantity || 0);
          },
          0,
        ),
        currency: baseCurrencyCode,
      };
    };

    const ttTrackerFilters = {
      Search: function (props) {
        return {
          query: props.query,
        };
      },
      ViewContent: function (props) {
        const product = props.product;
        return {
          content_id: productService.getSku(
            product._id,
            product.sku,
            props.variationSelected,
          ),
          content_name: $filter('translateModel')(product.title_translations),
          currency: baseCurrencyCode,
          price: props.value,
          value: props.value,
          content_type: 'product',
        };
      },
      AddToCart: function (props) {
        const product = props.product;
        return {
          content_id: productService.getSku(
            product._id,
            product.sku,
            props.variationSelected,
          ),
          content_name: $filter('translateModel')(product.title_translations),
          currency: baseCurrencyCode,
          price: props.value,
          value: props.value,
          quantity: props.quantity,
          content_type: 'product',
        };
      },
      StartCheckout: getCheckoutAndPurchasePayload,
      Purchase: getCheckoutAndPurchasePayload,
    };

    function trackTT(eventName, props) {
      try {
        if (ttqTrack === null) {
          return;
        }

        const trackerFilters = ttTrackerFilters[eventName];
        if (typeof trackerFilters === 'function' && props) {
          ttqTrack.track(eventName, trackerFilters(props));
        } else {
          ttqTrack.track(eventName);
        }
      } catch (e) {
        // Something went wrong with tiktok tracker
      }
    }

    $rootScope.$on(generalEventType.REGISTRATION, () => {
      trackTT('Registration');
    });
    $rootScope.$on(generalEventType.SEARCH, (e, data) => {
      trackTT('Search', data);
    });
    $rootScope.$on(generalEventType.VIEW_CONTENT, (e, data) => {
      trackTT('ViewContent', data);
    });
    $rootScope.$on(generalEventType.ADD_TO_CART, (e, data) => {
      trackTT('AddToCart', data);
    });
    $rootScope.$on(generalEventType.START_CHECKOUT, (e, data) => {
      trackTT('StartCheckout', data);
    });
    $rootScope.$on(generalEventType.ADD_BILLING, () => {
      trackTT('AddBilling');
    });
    $rootScope.$on(generalEventType.PURCHASE, (e, data) => {
      trackTT('Purchase', data);
    });
  },
]);

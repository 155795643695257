import { onTabActive } from '../features/member-center';

app.controller('WishlistController', [
  '$scope',
  '$filter',
  '$uibModal',
  'mainConfig',
  'wishlistService',
  'productService',
  'cartService',
  'trackerService',
  'featureService',
  'slFeatureService',
  'buyNowService',
  '$rootScope',
  '$element',
  function (
    $scope,
    $filter,
    $uibModal,
    mainConfig,
    wishlistService,
    productService,
    cartService,
    trackerService,
    featureService,
    slFeatureService,
    buyNowService,
    $rootScope,
    $element,
  ) {
    $scope.wishlistItems = [];
    $scope.pagination = {
      limit: 10,
      page: 1,
      total: 0,
      maxSize: 5,
    };

    $scope.state = {
      isLoading: false,
    };

    $scope.getWishlist = function () {
      if ($scope.state.isLoading) {
        return;
      }
      var params = {
        limit: $scope.pagination.limit,
        page: $scope.pagination.page,
      };
      $scope.state.isLoading = true;
      wishlistService
        .getList(params)
        .then(function (res) {
          $scope.wishlistItems = _.map(res.data.items, function (item) {
            var product = item.product;
            var isProductSet =
              item.selected_child_products?.length > 0 &&
              slFeatureService.hasFeature('product_set_revamp');
            var defaultPrice = {
              cents: 0,
            };
            item.status = product.status;

            if (_.isEmpty(product.variation) || product.same_price) {
              item.productPrice = product.price || defaultPrice;
              item.hasPriceSale =
                isProductSet ||
                (product.price_sale && product.price_sale.cents > 0);
              item.hasMemberPrice =
                product.member_price && product.member_price.cents > 0;
            } else {
              item.productPrice = product.variation.price || defaultPrice;
              item.hasPriceSale =
                product.variation.price_sale &&
                product.variation.price_sale.cents > 0;
              item.hasMemberPrice =
                product.variation.member_price &&
                product.variation.member_price.cents > 0;
            }

            item.discountPrice = isProductSet
              ? product.price_sale
              : cartService.getItemPrice({
                  variation: product.variation,
                  product: product,
                }) || defaultPrice;

            item.isDiscount = item.hasPriceSale || item.hasMemberPrice;
            item.isHidePrice = isProductSet
              ? item.discountPrice.cents === 0
              : product.hide_price ||
                (item.productPrice.cents === 0 &&
                  item.discountPrice.cents === 0);

            if (product.variation && product.variation.media) {
              item.imgUrl = product.variation.media.images.original.url;
            } else {
              item.imgUrl = product.media.images.original.url;
            }
            if (item.isHidePrice) {
              item.status = 'draft';
            } else if (item.status != 'draft' && !product.unlimited_quantity) {
              if (
                (!_.isEmpty(product.variation) &&
                  product.variation.quantity == 0) ||
                product.quantity == 0
              ) {
                item.status = 'unavailable';
              }
            }

            if (featureService.hasFeature('product_available_time')) {
              item.availableTimeType = getAvailableTimeType(item.product);
            }

            item.isUnavailable =
              item.status === 'draft' || item.status === 'hidden';

            return item;
          });

          $scope.pagination.total = res.data.total;
        })
        .finally(function () {
          $scope.state.isLoading = false;
        });
    };

    var getAvailableTimeType = function (product) {
      var currentTime = dayjs(new Date().getTime());
      var startTime = dayjs(product.available_start_time);
      var endTime = dayjs(product.available_end_time);
      if (currentTime.isBefore(startTime)) {
        return 'coming_soon';
      } else if (currentTime.isAfter(endTime)) {
        return 'available_time_over';
      }
    };

    $scope.onCloseMessage = function () {
      $scope.errorMessage = '';
    };

    $scope.removeWishlistItem = function (item, index) {
      var product = item.product;
      $scope.errorMessage = '';

      $scope.currentModal = $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/messages.confirm.modal.html'),
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.submitConfirm = function () {
              trackerService.removeFromWishlist(product);
              $uibModalInstance.close();
            };
          },
        ],
      });

      $scope.currentModal.result.then(function () {
        var data = {
          product_id: product._id,
          variation_key: (product.variation && product.variation.key) || '',
        };
        // if product set
        if (
          (slFeatureService.hasFeature('product_set') ||
            slFeatureService.hasFeature('product_set_revamp')) &&
          !_.isEmpty(item.product_set_datas)
        ) {
          data.id = item._id;
        }
        wishlistService.removeItem(data).then(
          function () {
            $scope.wishlistItems.splice(index, 1);

            if (
              $scope.pagination.page > 1 &&
              $scope.wishlistItems.length <= 0
            ) {
              $scope.pagination.page -= 1;
              $scope.getWishlist();
            }
          },
          function () {
            $scope.errorMessage = $filter('translate')(
              'wishlist.messages.remove_error',
            );
          },
        );
      });
    };
    $scope.addItemToCart = function (item, index, isBuyNow) {
      var product = item.product;
      var variationKey = product.variation && product.variation.key;
      $scope.wishlistItems[index].loading = true;
      var checkStockFn = function () {
        if (
          (slFeatureService.hasFeature('product_set') ||
            slFeatureService.hasFeature('product_set_revamp')) &&
          !_.isEmpty(item.product_set_datas)
        ) {
          return productService.checkProductSetStock({
            id: product._id,
            productSetData: item.product_set_datas,
          });
        }
        return productService.checkStock(product._id, variationKey);
      };
      checkStockFn().then(function (res) {
        var data = res.data;
        var hasStock =
          data.unlimited_quantity ||
          1 <= data.left_items_quantity ||
          data.out_of_stock_orderable;
        var cartItemData = {
          quantity: 1,
          variation: product.variation,
          blacklisted_payment_option_ids: [],
          blacklisted_delivery_option_ids: [],
        };
        if (product.subscription_enabled) {
          cartItemData['type'] = 'subscription_product';
        }
        // if product set
        if (
          (slFeatureService.hasFeature('product_set') ||
            slFeatureService.hasFeature('product_set_revamp')) &&
          !_.isEmpty(item.product_set_datas)
        ) {
          cartItemData['type'] = 'product_set';
          cartItemData.productSetData = item.product_set_datas;
        }
        if (
          hasStock &&
          productService.validateCheckoutReady(
            product,
            false,
            cartService.getItemPrice({
              variation: product.variation,
              product: product,
            }),
          )
        ) {
          //add item to cart
          cartService
            .addItem(product._id, cartItemData)
            .then(function (data) {
              if (slFeatureService.hasFeature('data_layer_info')) {
                $rootScope.$emit(
                  'add.item.to.cart',
                  cartService.getGaItemData({
                    product: product,
                    variant: product.variation,
                  }),
                );
              }
              trackerService.fbAddToCart(
                product,
                cartService.getItemPrice({
                  variation: product.variation,
                  product: product,
                }).dollars,
                product.variation,
                data.event_id,
              );

              if (isBuyNow) {
                $rootScope.$broadcast('clicked_buy_now_button');
              } else {
                jQuery.sidr('open', 'cart-panel');
              }
            })
            .finally(() => {
              $scope.wishlistItems[index].loading = false;
            });
        } else {
          item.status = 'unavailable';
          $scope.wishlistItems[index].loading = false;
        }
      });
    };

    $scope.shouldShowBuyNowButton = buyNowService.isEnabled();

    $scope.getWishlist();

    $scope.getChildVariationShorthand = function (variation) {
      return variation.fields
        .map(function (field) {
          return $filter('translateModel')(field.name_translations);
        })
        .join(', ');
    };

    onTabActive($element, () => {
      history.replaceState(null, null, `wishlist${location.search}`);
    });
  },
]);

app.controller('ProductSetBasketController', [
  '$scope',
  '$uibModalInstance',
  '$filter',
  '$uibModal',
  'productService',
  '$rootScope',
  'modalTypes',
  'imageService',
  'moneyService',
  'productSetService',
  'mainConfig',
  function (
    $scope,
    $uibModalInstance,
    $filter,
    $uibModal,
    productService,
    $rootScope,
    modalTypes,
    imageService,
    moneyService,
    productSetService,
    mainConfig,
  ) {
    $scope.imageService = imageService;
    $scope.getChildVariationName = productSetService.getChildVariationName;
    $scope.state = {
      isPanelExpanded: false,
      modalLoadingIndex: null,
      isLoadingSubmitBasket: false,
    };
    $scope.cachedProducts = {};

    function init() {
      if ($scope.basketCartItems.length > 0) {
        $scope.state.isPanelExpanded = true;
      }
    }

    init();

    $scope.togglePanel = function () {
      $scope.state.isPanelExpanded = !$scope.state.isPanelExpanded;
    };

    $scope.goBack = function () {
      $uibModalInstance.close();
    };

    $scope.addChildProduct = function (childProduct, childProductIndex) {
      if (childProduct.variations.length === 0) {
        return;
      }
      showProductModal(childProduct, childProductIndex);
    };

    async function showProductModal(product, childProductIndex) {
      let modalProduct;
      if ($scope.cachedProducts[childProductIndex]) {
        modalProduct = $scope.cachedProducts[childProductIndex];
      } else {
        $scope.state.modalLoadingIndex = childProductIndex;
        const res = await productService.getById(product._id);
        $scope.state.modalLoadingIndex = null;
        modalProduct = res.data.data;
        modalProduct.hide_price = false; // overwrite hide_price for child product
        modalProduct.belongsToProductSet = true;
        $scope.cachedProducts[childProductIndex] = res.data.data;
      }

      const newScope = $scope.$new();
      newScope.product = {
        ...modalProduct,
        bundle_set: $scope.childProducts[childProductIndex].bundle_set,
      };
      newScope.isProductSetBasket = true;
      newScope.basketCartItems = $scope.basketCartItems;
      const modal = $uibModal.open({
        templateUrl:
          '/themes/v1/default/views/templates.dialog.product_set.quick_cart.html',
        controller: 'productQuickCartController',
        scope: newScope,
        windowClass: 'QuickCart-modal product-set-quick-cart-modal',
      });

      modal.opened.then(() => {
        $rootScope.$emit('modal.open', {
          modalType: modalTypes.QUICK_CART,
        });
      });

      modal.result.then(function () {
        $rootScope.$emit('modal.close', {
          modalType: modalTypes.QUICK_CART,
        });
      });
    }

    $scope.$on('product-set.add-child-variation', function (ev, payload) {
      if (payload) {
        $scope.addItemToBasket({
          childProduct: payload.product,
          variationSelected: payload.variationSelected,
          quantity: payload.quantity,
        });
        if (window.matchMedia('(min-width: 768px)').matches) {
          $scope.state.isPanelExpanded = true;
        }
      }
    });

    $scope.addItemToBasket = function ({
      childProduct,
      variationSelected,
      quantity,
    }) {
      const existingVariation = $scope.basketCartItems.find((item) => {
        return (
          item._id === childProduct._id &&
          item.variationSelected &&
          item.variationSelected.key === variationSelected.key
        );
      });
      if (existingVariation) {
        existingVariation.quantity += quantity;
      } else {
        $scope.basketCartItems.push({
          ...childProduct,
          quantity,
          variationSelected,
        });
      }
    };

    $scope.removeItem = function (itemIndex) {
      $scope.basketCartItems.splice(itemIndex, 1);
    };

    function getProductQuantityInBasket(childProduct) {
      const items = $scope.basketCartItems.filter(
        (item) => item._id === childProduct._id,
      );
      const quantity = items.reduce((accu, curr) => {
        return accu + curr.quantity;
      }, 0);
      return quantity;
    }

    $scope.isProductReachedLimit = function (childProduct) {
      return (
        getProductQuantityInBasket(childProduct) ===
        (childProduct.bundle_set?.min_quantity || 1)
      );
    };

    $scope.getQuantityLabel = function (childProduct) {
      if ($scope.isProductReachedLimit(childProduct)) {
        return $filter('translate')('product_set.items_selected', {
          quantity: getProductQuantityInBasket(childProduct),
        });
      }
      const diff =
        (childProduct.bundle_set?.min_quantity || 1) -
        getProductQuantityInBasket(childProduct);
      return $filter('translate')('product_set.items_needed', {
        quantity: diff,
      });
    };

    $scope.getSelectedTotalQuantity = function () {
      return $scope.basketCartItems.reduce((accu, curr) => {
        return accu + curr.quantity;
      }, 0);
    };

    $scope.getTotalRequiredQuantity = function () {
      return $scope.childProducts.reduce((accu, curr) => {
        return accu + (curr.bundle_set?.min_quantity || 1);
      }, 0);
    };

    $scope.allowFinishEdit = function () {
      const totalQuantity = $scope.getSelectedTotalQuantity();
      const totalRequiredQuantity = $scope.getTotalRequiredQuantity();
      return totalQuantity === totalRequiredQuantity;
    };

    $scope.getImgSrc = function (item, size) {
      return imageService.getMediaImageUrl(
        item.variationSelected && item.variationSelected.media
          ? item.variationSelected.media
          : item.media[0],
        { size },
      );
    };

    $scope.getProgressStyle = function () {
      return {
        width: `calc(${
          ($scope.getSelectedTotalQuantity() /
            $scope.getTotalRequiredQuantity()) *
          100
        }%)`,
      };
    };

    $scope.getCheckoutPrice = function () {
      return productSetService.getCheckoutPrice({
        product: $scope.product,
        basketCartItems: $scope.basketCartItems,
      });
    };

    $scope.submitBasket = function (e) {
      e.stopPropagation();
      $scope.state.isLoadingSubmitBasket = true;
      productService.getById($scope.product._id).then((res) => {
        $scope.product = res.data.data;
        $scope.childProducts = $scope.product.child_products;
        $scope.state.isLoadingSubmitBasket = false;
        if (checkBasketItemStock()) {
          $uibModalInstance.close({
            basketCartItems: $scope.basketCartItems,
            checkoutPrice: $scope.getCheckoutPrice(),
          });
        } else {
          scrollToError();
        }
      });
    };

    function scrollToError() {
      setTimeout(function () {
        $('.basket-cart-panel-content').animate(
          {
            scrollTop:
              $('.basket-cart-item .txt-sold-out').eq(0).offset().top -
              $('.basket-inner.panel-content-inner').offset().top,
          },
          300,
        );
      });
    }

    function checkBasketItemStock() {
      $scope.basketCartItems.forEach((item) => {
        const product = $scope.childProducts.find((p) => p._id === item._id);
        if (product.unlimited_quantity || product.out_of_stock_orderable) {
          item.isUnderstock = false;
          return;
        }
        let stock;
        stock = product.quantity;
        if (item.variationSelected) {
          const variation = product.variations.find(
            (v) => v.key === item.variationSelected.key,
          );
          stock = variation.quantity;
        }

        item.isUnderstock = item.quantity > stock;
      });
      return $scope.basketCartItems.every((item) => !item.isUnderstock);
    }

    $scope.getEmptyPriceLabel = function () {
      const baseCurrency = mainConfig.merchantData.base_currency;
      return baseCurrency?.symbol_first
        ? `${baseCurrency.alternate_symbol} -`
        : `- ${baseCurrency.alternate_symbol}`;
    };
  },
]);

app.service('smsSubscriptionService', [
  'benchatService',
  '$q',
  function (benchatService, $q) {
    return {
      activateSubscriptions: function (subscriptions) {
        var tasks = _.map(subscriptions, function (subscription) {
          return benchatService.activateSubscription(subscription._id, {
            activatedAt: Date.now(),
          });
        });

        return $q.all(tasks);
      },
      createActiveSubscriptions: function (
        userId,
        channelOwnerId,
        userRef,
        topics,
      ) {
        var self = this;
        var subscriptions = _.map(topics, function (topic) {
          return {
            userId: userId,
            channelOwnerId: channelOwnerId,
            userRef: userRef,
            platform: 'SMS',
            topic: topic,
          };
        });
        return benchatService
          .patchSubscriptions(subscriptions)
          .then(function (response) {
            return self.activateSubscriptions(response.data);
          });
      },
      createInactiveSubscriptions: function (
        userId,
        channelOwnerId,
        userRef,
        topics,
      ) {
        var subscriptions = _.map(topics, function (topic) {
          return {
            userId: userId,
            channelOwnerId: channelOwnerId,
            userRef: userRef,
            platform: 'SMS',
            topic: topic,
          };
        });

        return benchatService.patchSubscriptions(subscriptions);
      },
      hasMarketingSubscriptions: function (subscriptions) {
        var filteredSubscriptions = _.filter(subscriptions, function (
          subscription,
        ) {
          return (
            subscription.topic === 'MARKETING' &&
            subscription.platform === 'SMS'
          );
        });

        if (_.isEmpty(filteredSubscriptions)) {
          return false;
        }

        return true;
      },
      getActiveSubscriptions: function (topics, subscriptions) {
        return _.reduce(
          subscriptions,
          function (acc, subscription) {
            _.each(topics, function (topic) {
              if (
                subscription.isActive &&
                subscription.topic === topic &&
                subscription.platform === 'SMS'
              ) {
                acc.push(subscription);
              }
            });
            return acc;
          },
          [],
        );
      },
    };
  },
]);

// app.service('userService', ['$rootScope', '$http','$location',,'mainConfig', function($rootScope,$http,$location,mainConfig) {

// 	this.isUserLoggedIn = function() {
//       if (mainConfig.currentUser == null || angular.isUndefined(mainConfig.currentUser)) {
//         return false;
//       }

//       return true;
//     }

// }]);

app.service('userService', [
  '$http',
  function ($http) {
    var path = '/api/users/';
    this.getByUserId = function (userId) {
      return $http({
        method: 'GET',
        url: path + userId,
      });
    };

    this.sendVerificationEmail = function () {
      return $http({
        method: 'POST',
        url: path + '/send_verification_email',
      });
    };

    // input date format: "2019-08-25T00:00:00.000Z"
    this.convertDateWithoutTimezone = function (date) {
      return new Date(date.substring(0, 10).replace(/-/g, '/'));
    };
  },
]);

app.service('familyMartFreezeService', [
  '$http',
  function ($http) {
    return {
      isFreezeType: function (region_type) {
        switch (region_type) {
          case 'sl_logistics_fmt_freeze_pay':
          case 'sl_logistics_fmt_freeze_nopay':
            return true;
          default:
            return false;
        }
      },
      confirmStationSpace: function (payload) {
        return $http({
          method: 'POST',
          url: 'api/sl_logistics/station_spaces',
          data: payload,
        });
      },
    };
  },
]);

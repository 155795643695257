app.directive('instagramBanner', [
  '$rootScope',
  '$cookies',
  '$filter',
  'execCommandService',
  function ($rootScope, $cookies, $filter, execCommandService) {
    return {
      restrict: 'A',
      link: function (scope) {
        $('.search-panel-mobile, .body-wrapper').addClass(
          'has-instagram-banner',
        );
        $('.trial-banner').hide();

        scope.onClose = function () {
          $('.search-panel-mobile, .body-wrapper').removeClass(
            'has-instagram-banner',
          );
          $('.trial-banner').show();

          $cookies.remove('show_missing_session_key_warning');
          $rootScope.showMissingSessionKeyWarning = false;
        };

        scope.copy = function () {
          execCommandService.copyToClipboard(window.location.href);

          new PNotify({
            title: $filter('translate')('member_referral.copy.link.success'),
            addclass: 'success instagram-copy-notify',
            animate: {
              animate: true,
              in_class: 'fadeInDown',
              out_class: 'fadeOut',
            },
            delay: 4000,
            icon: 'fa fa-check-circle',
          });
        };
      },
    };
  },
]);

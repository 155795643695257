const { removeDashBracket } = require('../../utils/parser');

/**
 * link: https://shopline.atlassian.net/wiki/spaces/EN/pages/2430402688/intlTelControlGroup
 */
app.service('intlTelInputService', [
  '$filter',
  function ($filter) {
    /**
     * type = abbr | number
     * if type is abbr
     * return ex. 'hk', 'tw', etc.
     * return undefined when country is not selected
     */
    this.getCountry = function (type, elem) {
      switch (type) {
        case 'abbr':
          return elem.intlTelInput('getSelectedCountryData').iso2;
        case 'number':
          return elem.intlTelInput('getSelectedCountryData').dialCode;
      }
    };

    /**
     * type = abbr | number
     * value is a string
     * if type = abbr
     *  value = 'hk' | 'tw', etc.
     * if type = number
     *  value = '886' | '44', etc.
     * return boolean indicate whether it's successful or not.
     */
    this.setCountry = function (type, elem, value) {
      let isSuccess = false;
      if (!value) return isSuccess;
      try {
        switch (type) {
          case 'abbr': {
            elem.intlTelInput('setCountry', value);
            if (this.getCountry('abbr', elem) === value) isSuccess = true;
            break;
          }
          case 'number': {
            const iso2 = this.toAbbr(value);
            if (iso2) elem.intlTelInput('setCountry', iso2);
            if (this.getCountry('number', elem) === value) isSuccess = true;
            break;
          }
        }
      } catch (error) {
        isSuccess = false;
      }
      return isSuccess;
    };

    /**
     * type = complete | simple | national
     * if type is complete
     * return string ex. '+886958554123'
     * if type is simple
     * return string ex. '982544632'
     * if type is national
     * return national phone with prefix like '0985123123', depend on its country
     */
    this.getPhone = function (type, elem) {
      switch (type) {
        case 'complete':
          return elem.intlTelInput('getNumber');
        case 'simple':
          return elem.val();
        case 'national':
          return elem
            .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
            .replace(/\D/g, '');
      }
    };

    /**
     * type = complete | simple
     * if type is complete
     * value is string, ex, '+886928755412'
     * if type is simple
     * value is string, ex. '986554123'
     * if trigger is true, it will trigger input event and digest loop
     */
    this.setPhone = function (type, elem, value) {
      switch (type) {
        case 'simple':
          elem.val(value);
          break;
        case 'complete':
          elem.intlTelInput('setNumber', value);
          elem.val(elem.val().replace(/\D/g, ''));
      }
    };

    /**
     * return array [], each index is an object, which include following properties
     * type: countryRequired | numberRequired | numberValidation
     * message: which is a string, it's error content for displaying in UI
     * config: you can customize you validation error message by pass in the different translation key, ex.
     * {
     *  invalidKey: {
     *    countryRequired: 'form.validation.calling_code',
     *    numberRequired: 'form.validation.required'
     *  },
     *  wording: {
     *    field_name: 'Phone number'
     *  },
     *  validateTarget: {
     *    numberValidation: true
     *    localPhoneValidation: false
     *    mobilePhoneValidation: true
     *  }
     * }
     * otherwise, it will use default key
     */
    this.validatePhone = function (elem, config = {}) {
      const { invalidKey = {}, wording, validateTarget = {} } = config;
      const {
        countryRequired: doCountryRequired = true,
        numberRequired: doNumberRequired = true,
        numberValidation: doNumberValidation = true,
        localPhoneValidation: doLocalPhoneValidation = false,
        mobilePhoneValidation: doMobilePhoneValidation = false,
      } = validateTarget;
      const {
        countryRequired,
        numberRequired,
        numberValidation,
        localPhoneValidation,
        mobilePhoneValidation,
      } = invalidKey;
      const errorMsg = [];
      const dialCode = this.getCountry('number', elem);
      const phoneNum = this.getPhone('simple', elem);
      if (doCountryRequired && dialCode === undefined) {
        const key = countryRequired ?? 'form.validation.calling_code';
        errorMsg.push({
          type: 'countryRequired',
          message: $filter('translate')(key, wording),
        });
      }
      if (doNumberRequired && !phoneNum) {
        const key = numberRequired ?? 'form.validation.required';
        errorMsg.push({
          type: 'numberRequired',
          message: $filter('translate')(key, wording),
        });
      }
      if (
        doNumberValidation &&
        phoneNum.length > 0 &&
        !elem.intlTelInput('isValidNumber')
      ) {
        const key = numberValidation ?? 'form.validation.invalid';
        errorMsg.push({
          type: 'numberValidation',
          message: $filter('translate')(key, wording),
        });
      }
      if (doLocalPhoneValidation) {
        const key = localPhoneValidation ?? 'form.validation.invalid';
        const hkLocalPhoneRegex = /^[2-3]{1}\d{7}$/;
        const country = this.getCountry('abbr', elem);
        const nationalPhone = this.getPhone('national', elem);
        if (country === 'hk' && !hkLocalPhoneRegex.test(nationalPhone)) {
          errorMsg.push({
            type: 'localPhoneValidation',
            message: $filter('translate')(key, wording),
          });
        }
      }
      if (doMobilePhoneValidation) {
        const key = mobilePhoneValidation ?? 'form.validation.invalid';
        const hkMobilePhoneRegex = /^[4-9]{1}\d{7}$/;
        const country = this.getCountry('abbr', elem);
        const nationalPhone = this.getPhone('national', elem);
        console.log('pass', hkMobilePhoneRegex.test(nationalPhone));
        if (country === 'hk' && !hkMobilePhoneRegex.test(nationalPhone)) {
          errorMsg.push({
            type: 'mobilePhoneValidation',
            message: $filter('translate')(key, wording),
          });
        }
      }
      return errorMsg;
    };

    this.toAbbr = (value) =>
      window.intlTelInputGlobals
        .getCountryData()
        .find((v) => v.dialCode === value).iso2;

    /**
     * dialCode should be the string in number format, ex. '886'
     * phone should be simple number like '986321456'
     * defaultCountry should be abbr format, ex. 'tw'
     */
    this.initValue = (elem, { dialCode, phone, defaultCountry }) => {
      if (!dialCode && !phone) {
        this.setCountry('abbr', elem, defaultCountry);
        return;
      }
      this.setCountry('number', elem, dialCode);
      this.setPhone('simple', elem, phone);
    };

    this.initSetting = (elem, config = {}) => {
      const { userCountry, allowedCountry, hideHint } = config;
      const finalConfig = this.generateInitConfig(userCountry, allowedCountry);
      elem.intlTelInput(finalConfig);
      this.insertPlaceholderAndHint(elem, hideHint);
    };

    /* reset country to blank */
    this.reset = (elem, config) => {
      elem.intlTelInput('destroy');
      this.initSetting(elem, config);
    };

    /* start, utils */
    this.formatPhone = function (elem) {
      const nationalPhone = this.getPhone('national', elem);
      this.setPhone('simple', elem, nationalPhone);
      return nationalPhone;
    };

    this.insertPlaceholderAndHint = (element, hideHint = false) => {
      const defaultPlaceHolder = `<div class="default-place-holder">${$filter(
        'translate',
      )('users.fields.callingCode')}</div>`;
      const countryCodeHint = `<div class="country-code-hint">${$filter(
        'translate',
      )('users.fields.callingCodeHint')}</div>`;
      element
        .parents('.iti--allow-dropdown')
        .addClass('without-country-code')
        .find('.iti__selected-flag')
        .prepend($(defaultPlaceHolder));
      if (!hideHint) {
        element
          .parents('.iti--allow-dropdown.without-country-code')
          .append($(countryCodeHint));
      }
      const placeHolderWidth = element
        .parents('.iti--allow-dropdown.without-country-code')
        .find('.iti__flag-container')
        .outerWidth();
      const leftPadding = placeHolderWidth || '';
      element.css({ 'padding-left': leftPadding + 'px' });
    };

    this.removePlaceholderAndHint = (element) => {
      const inputEleWithPlaceHolder = element.parents(
        '.iti--allow-dropdown.without-country-code',
      );
      element.css({ 'padding-left': '' });
      inputEleWithPlaceHolder.removeClass('without-country-code');
      inputEleWithPlaceHolder.find('.default-place-holder').hide();
      inputEleWithPlaceHolder.find('.country-code-hint').hide();
    };

    this.generateInitConfig = (userCountry, allowedCountry) => {
      let preferredCountries = [
        'tw',
        'hk',
        'my',
        'us',
        'cn',
        'sg',
        'id',
        'th',
        'vn',
        'ph',
        'jp',
        'kr',
        'au',
        'nz',
        'ca',
      ];
      if (userCountry) {
        preferredCountries = preferredCountries.filter(
          (country) => country !== userCountry,
        );
        preferredCountries.unshift(userCountry);
      }
      const config = {
        initialCountry: 'auto',
        customPlaceholder: removeDashBracket,
        preferredCountries,
      };
      if (allowedCountry) config.onlyCountries = allowedCountry;
      return config;
    };
    /* end, utils */

    /* start, for AngularJs */

    /**
     * update validity for a modal of input, depends on your data
     * the validities include: countryRequired | numberRequired | numberValidation
     * @param {Element} element
     * @param {NgModelController} field
     */
    this.updateIntlInputValidity = (element, field, config) => {
      const initValidity = {
        countryRequired: true,
        numberRequired: true,
        numberValidation: true,
        localPhoneValidation: true,
      };
      const errors = this.validatePhone(element, config);
      errors.forEach((error) => (initValidity[error.type] = false)); // set to invalid if there is error
      Object.keys(initValidity).forEach((key) =>
        field.$setValidity(key, initValidity[key]),
      );
    };
  },
]);

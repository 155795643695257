app.service('productReviewCommentService', [
  '$http',
  'merchantService',
  'slFeatureService',
  'mainConfig',
  function ($http, merchantService, slFeatureService, mainConfig) {
    this.getProductReviewComments = function (params) {
      return $http({
        method: 'GET',
        url:
          '/api/merchants/' +
          merchantService.merchantId +
          '/products/' +
          params.product_id +
          '/product_review_comments',
        params: {
          page: params.page,
          sort_by: 'score',
          order_by: 'desc',
        },
      });
    };

    this.getUncommentedOrders = function (params) {
      return $http({
        method: 'GET',
        url: '/api/product_review_comments/orders',
        params: {
          is_product_reviewed: false,
          page: params.page,
          limit: params.limit,
          offset: (params.page - 1) * params.limit,
          user_id: mainConfig.currentUser._id,
          status: 'completed',
        },
      });
    };

    // enabled_product_review takes true as default, which means undefined should be enabled.
    this.productReviewEnabled =
      slFeatureService.hasFeature('shopline_product_reviews') &&
      mainConfig.merchantData.product_setting.enabled_product_review !== false;
    this.amazonProductReviewEnabled =
      slFeatureService.hasFeature('amazon_product_review') &&
      mainConfig.merchantData.product_setting.amazon_setting &&
      mainConfig.merchantData.product_setting.amazon_setting.status ===
        'active';

    this.getOrder = function (orderId) {
      return $http({
        method: 'GET',
        url: '/api/orders/' + orderId,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        params: {
          user_id: mainConfig.currentUser._id,
        },
      });
    };
  },
]);

app.directive("checkoutDeliverySevenEleven", [
  '$compile'
  '$filter'
  'ordersService'
  (
    $compile
    $filter
    ordersService
  ) ->
    {
      restrict: 'A'
      scope: {
        getFormData: "&"
      }
      link: (scope, element, attrs) ->
        scope.pickStore = () ->
          data = scope.getFormData()
          ordersService.saveSessionData(data.order, {
            "save_fields_phone": data.saveFields.phone,
            "save_fields": data.saveFields,
            "order": {
              "custom_fields_translations": data.order.custom_fields_translations
            }
            # "save_user_fields": data.save_user_fields
          }).then((data,status) ->
            if attrs.deliveryType == 'crossBorder'
              $('#seven_eleven_cross_border_selector').submit()
            else
              $('#seven_eleven_map_selector').submit()
          )
          return

        $container = element
        if $container.length == 1
          $container.find(".btn-pick-store").attr("ng-click", "pickStore()")
          $container.html($compile($container.html())(scope))
    }
])
import { removeDashBracket } from '../../utils/parser';

app.directive('intlTelInput', [
  '$rootScope',
  'mainConfig',
  function ($rootScope, mainConfig) {
    return {
      restrict: 'C',
      scope: {
        onChange: '=',
        phoneNumber: '@',
        onInputChange: '=',
        autoPaddingToFlag: '=',
      },
      link: function (scope, element) {
        var preferredCountries = _.filter(
          [
            'tw',
            'hk',
            'my',
            'us',
            'cn',
            'sg',
            'id',
            'th',
            'vn',
            'ph',
            'jp',
            'kr',
            'au',
            'nz',
            'ca',
          ],
          function (country) {
            return country != mainConfig.requestCountry.toLowerCase();
          },
        );
        preferredCountries.unshift(mainConfig.requestCountry.toLowerCase());

        function onCountryChange(countryData, isInit) {
          scope.onChange(countryData, isInit, element);
          if (!isInit) {
            $rootScope.$digest();
          }
        }

        function onInputChange(input, isInit) {
          scope.onInputChange?.(input, isInit, element);

          if (!isInit) {
            $rootScope.$digest();
          }
        }

        var onSetSelectedFlagInner = scope.autoPaddingToFlag
          ? function () {
              document.querySelector('.intl-tel-input').style.paddingLeft =
                document.querySelector(
                  '.iti__flag-container .iti__selected-flag',
                ).offsetWidth +
                4 +
                'px';
            }
          : undefined;

        if (scope.phoneNumber) {
          element.intlTelInput({
            onSetSelectedFlagInner: onSetSelectedFlagInner,
            customPlaceholder: removeDashBracket,
          });
          if (intlTelInputUtils.isValidNumber('+' + scope.phoneNumber)) {
            element.intlTelInput('setNumber', '+' + scope.phoneNumber);
          } else {
            element.intlTelInput(
              'setCountry',
              mainConfig.requestCountry.toLowerCase(),
            );
            element.intlTelInput('setNumber', scope.phoneNumber);
          }
        } else {
          element.intlTelInput({
            onSetSelectedFlagInner: onSetSelectedFlagInner,
            customPlaceholder: removeDashBracket,
            preferredCountries: preferredCountries,
          });
        }

        onCountryChange(element.intlTelInput('getSelectedCountryData'), true);

        element.on('countrychange', function () {
          onCountryChange(element.intlTelInput('getSelectedCountryData'));
        });

        element.on('input', function () {
          onInputChange($(this).val());
        });
      },
    };
  },
]);

app.directive('customerInfoAutoFill', [
  '$rootScope',
  '$http',
  '$filter',
  '$uibModal',
  'slFeatureService',
  'intlTelInputService',
  'checkoutService',
  'pnotifyService',
  'logger',
  function (
    $rootScope,
    $http,
    $filter,
    $uibModal,
    slFeatureService,
    intlTelInputService,
    checkoutService,
    pnotifyService,
    logger,
  ) {
    return {
      restrict: 'A',
      scope: {
        country: '@',
      },
      link: function (scope) {
        const isAddressModule = slFeatureService.hasFeature(
          'modularize_address_format',
        );

        function getPreference() {
          return $http({
            method: 'GET',
            url: '/api/address_preferences',
            params: {
              country: scope.country,
              scope: 'input_without_country',
            },
          });
        }

        function getSocialUserMeta(params) {
          return $http({
            method: 'GET',
            url: '/api/social_user_meta',
            params,
          });
        }

        async function getPreferenceData() {
          const preferenceRes = await getPreference();
          const preferenceData = preferenceRes.data.data;
          return {
            preferences: preferenceData.preferences,
            preferenceLevelMap: preferenceData.preferences
              .sort(function (a, b) {
                return a.level && b.level ? a.level - b.level : -1;
              })
              .map(function (p) {
                return {
                  [p.field_name]: p.level,
                };
              }),
          };
        }

        async function fillInLatestOrderData(params) {
          if (scope.isLoading) return;
          scope.isLoading = true;
          try {
            const socialUserMeta = await getSocialUserMeta(params);
            const latestOrderData = socialUserMeta?.data?.latest_order;
            if (latestOrderData && isAllowFillData(latestOrderData)) {
              fillData(latestOrderData);
            } else {
              pnotifyService.notify(
                $filter('translate')('checkout.auto_fill.toast.error'),
                { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
              );
              if (params?.customer_phone) {
                checkoutService
                  .getElement(`order[delivery_data][recipient_phone]`)
                  .val(params.customer_phone);
              }
            }
          } catch (error) {
            logger.log('Checkout Auto Fill Error:', error);
          } finally {
            scope.isLoading = false;
          }
        }

        function isAllowFillData(orderData) {
          const isSameCountry =
            scope.country === orderData.delivery_address.country_code;
          const isRequiredAddress =
            orderData.delivery_option.requires_customer_address;

          return isSameCountry && isRequiredAddress;
        }

        function fillData(orderData) {
          const customerData = {
            customer_name: orderData.customer_name,
            customer_email: orderData.customer_email,
            customer_phone: orderData.customer_phone,
            customer_phone_country_code: orderData.customer_phone_country_code,
          };
          setCustomerInfo(customerData);
          if (isAddressModule) {
            setRecipientFormWithAddressModule(orderData.delivery_address);
          } else {
            setRecipientForm(orderData.delivery_address);
          }
        }

        function setCustomerInfo(customerData) {
          if (
            customerData.customer_phone_country_code &&
            $('.order-customer-phone-with-countrycode').length
          ) {
            const countryCode = intlTelInputService.toAbbr(
              customerData.customer_phone_country_code,
            );

            $('.order-customer-phone-with-countrycode').intlTelInput(
              'setCountry',
              countryCode,
            );
          }
          Object.keys(customerData).forEach(function (key) {
            const value = customerData[key];
            checkoutService.getElement(`order[${key}]`).val(value);
          });
        }

        function setValueByKey(key, value) {
          const elementKey = ['recipient_name', 'recipient_phone'].includes(key)
            ? `order[delivery_data][${key}]`
            : `order[delivery_address][${key}]`;
          checkoutService.getElement(elementKey).val(value);
        }

        function setRecipientForm(deliveryData) {
          const payload = {};
          Object.keys(deliveryData).forEach(function (key) {
            const value = deliveryData[key];
            setValueByKey(key, value);
            payload[key] = value;
          });
          if ($('[delivery-form-address-option]').length) {
            $rootScope.$broadcast('checkout.address.auto_fill', payload);
          }
        }

        async function setRecipientFormWithAddressModule(deliveryData) {
          const { preferences, preferenceLevelMap } = await getPreferenceData();
          const payload = [];

          Object.keys(deliveryData)
            .sort(function (a, b) {
              return preferenceLevelMap[a] - preferenceLevelMap[b];
            })
            .forEach(function (key) {
              let value = deliveryData[key];
              setValueByKey(key, value);
              if (!['recipient_name', 'recipient_phone'].includes(key)) {
                if (scope.country === 'TW' && key === 'address_2') {
                  value = `${deliveryData.postcode} ${deliveryData.address_2}`;
                }
                payload.push({
                  key,
                  value,
                  preference: preferences.find(function (preference) {
                    return preference.field_name === key;
                  }),
                });
              }
            });
          $rootScope.$broadcast('checkout.address_module.auto_fill', payload);
        }

        function openPhoneInputModal() {
          $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/checkout.customer-info-fill-confirm-modal.html'),
            keyboard: false,
            backdrop: 'static',
            controller: [
              '$scope',
              '$timeout',
              '$uibModalInstance',
              'mainConfig',
              'intlTelInputService',
              function (
                $scope,
                $timeout,
                $uibModalInstance,
                mainConfig,
                intlTelInputService,
              ) {
                $scope.isSubmitted = false;
                $scope.errorMessage = null;
                $scope.cancel = function () {
                  $uibModalInstance.dismiss();
                };
                $scope.confirm = async function () {
                  $scope.isSubmitted = true;
                  if (validPhone()) {
                    $scope.isLoading = true;
                    const params = {
                      customer_phone_country_code: intlTelInputService.getCountry(
                        'number',
                        $scope.inputElement,
                      ),
                      customer_phone: intlTelInputService.getPhone(
                        'simple',
                        $scope.inputElement,
                      ),
                    };
                    await fillInLatestOrderData(params);
                    $uibModalInstance.close();
                  }
                };

                function validPhone() {
                  if (!$scope.isSubmitted) return;

                  const errors = intlTelInputService.validatePhone(
                    $('#checkout-auto-fill-confirm-input'),
                  );
                  $timeout(function () {
                    $scope.errorMessage = errors.length > 0;
                  });
                  return errors.length === 0;
                }

                $timeout(function () {
                  $scope.inputElement = $('#checkout-auto-fill-confirm-input');
                  const defaultCountry = mainConfig.requestCountry.toLowerCase();
                  intlTelInputService.initSetting($scope.inputElement, {
                    userCountry: defaultCountry,
                  });
                  intlTelInputService.initValue($scope.inputElement, {
                    defaultCountry: defaultCountry,
                  });
                  $scope.inputElement.on('input', validPhone);
                  $scope.inputElement.on('countrychange', validPhone);
                });
              },
            ],
          });
        }

        function init() {
          if (
            slFeatureService.hasFeature('fill_phone_popup') &&
            !scope.isModalOpen
          ) {
            scope.isModalOpen = true;
            openPhoneInputModal();
          } else {
            fillInLatestOrderData();
          }
        }

        if (isAddressModule) {
          $rootScope.$on('address_module.template.ready', function () {
            init();
          });
        } else {
          init();
        }
      },
    };
  },
]);

app.directive('googlePayBtn', [
  '$q',
  '$http',
  'cart',
  'mainConfig',
  '$filter',
  function ($q, $http, cart, mainConfig, $filter) {
    var loadScript;
    loadScript = function (u) {
      var doc, deferred, obj, str, type;
      doc = document;
      type = 'script';
      obj = doc.createElement(type);
      str = doc.getElementsByTagName(type)[0];
      obj.src = '//' + u;
      deferred = $q.defer();
      obj.addEventListener(
        'load',
        function () {
          deferred.resolve();
        },
        false,
      );
      str.parentNode.insertBefore(obj, str);
      return deferred.promise;
    };
    return {
      template:
        '<button class="google-pay-wrapper ladda-button" data-style="slide-down" ng-class="{\'show\': loading}"' +
        '<img src="https://s3-ap-southeast-1.amazonaws.com/static.shoplineapp.com/web/v1/img/loaders/loader1.gif">' +
        '</button><div id="payment-request-button" ng-class="{\'disabled\': isDisabled}"></div>',
      restrict: 'E',
      scope: {
        payment: '=',
        onSuccess: '=',
        beforePayment: '=',
        afterPayment: '=',
        cancelPayment: '=',
        loading: '=',
        isDisabled: '=',
      },
      link: function (scope) {
        var buildLineItems, elements, paymentRequest, prButton, stripe;
        loadScript('js.stripe.com/v3/').then(function () {
          stripe = Stripe(scope.payment.config_data.publishable_key);
          paymentRequest = stripe.paymentRequest({
            country: mainConfig.merchantData.base_country_code,
            currency: mainConfig.merchantData.base_currency_code.toLowerCase(),
            total: {
              label: mainConfig.merchantData.name,
              amount: cart.total.cents,
            },
            displayItems: buildLineItems(),
          });
          elements = stripe.elements({
            locale: mainConfig.localeData.loadedLanguage.code,
          });
          paymentRequest.on('cancel', scope.cancelPayment);
          prButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
          });
          paymentRequest.canMakePayment().then(function (result) {
            if (result && result.applePay === false) {
              scope.$emit('checkout.payments.google_pay.ready');
              prButton.mount('#payment-request-button');
              prButton.on('click', function (e) {
                if (!scope.beforePayment()) {
                  return e.preventDefault();
                }
              });
            } else {
              scope.$emit('checkout.payments.google_pay.not_supported');
              console.log('Google Pay is not available');
            }
          });

          paymentRequest.on('token', function (ev) {
            $http
              .post('api/payments/' + scope.payment._id + '/charge', {
                token: ev.token.id,
                cents: cart.total.cents,
                currency: mainConfig.merchantData.base_currency_code,
                description: mainConfig.merchantData.name,
              })
              .then(
                function (response) {
                  if (typeof response.data !== 'string') {
                    response.data;
                    if (scope.onSuccess) {
                      scope.onSuccess(response.data);
                    }
                    return ev.complete('success');
                  } else {
                    $q.reject(response.data);
                    ev.complete('fail');
                    if (scope.afterPayment) {
                      return scope.afterPayment('failed');
                    }
                  }
                },
                function (response) {
                  $q.reject(response.data);
                  ev.complete('fail');
                  if (scope.afterPayment) {
                    return scope.afterPayment(response.message);
                  }
                },
              );
          });
        });

        buildLineItems = function () {
          return _.chain([
            {
              label: $filter('translate')('orders.fields.subtotal'),
              amount: cart.subtotal.cents,
            },
            {
              label: $filter('translate')('orders.fields.delivery_fee'),
              amount: cart.delivery_fee.cents,
            },
            {
              label: $filter('translate')('orders.fields.payment_fee'),
              amount: cart.payment_fee.cents,
            },
            {
              label: $filter('translate')('orders.fields.order_discount'),
              amount: cart.discount.cents,
            },
          ])
            .filter(function (item) {
              return item.amount;
            })
            .map(function (item) {
              return item;
            })
            .value();
        };
      },
    };
  },
]);

app.service('sfexpressService', [
  '$http'
  (
    $http
  )->
    {
      getStoreLocations: -> return $http.get("/api/sfexpress/store_locations")
      getCentreLocations: -> return $http.get("/api/sfexpress/centre_locations")
      getEflockerLocations: -> return $http.get("/api/sfexpress/eflocker_locations")
      getSevenElevenLocations: -> return $http.get("/api/sfexpress/seven_eleven_locations")
      getCirclekLocations: -> return $http.get("/api/sfexpress/circlek_locations")
    }
])

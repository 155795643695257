app.directive("checkoutCartPromotions", [
  '$rootScope'
  '$timeout'
  '$http'
  '$compile',
  'checkoutService'
  'logger'
  (
    $rootScope
    $timeout
    $http
    $compile
    checkoutService
    logger
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        scope.$on "checkout.cart.promotions.reload", () ->
          element.addClass "checkout-section-loading"
          checkoutService.requestPartial('cart', 'promotions')
            .then (res) ->
              element.html($compile(res.data)(scope))
            .catch (error) ->
              logger.log("Unable to load cart promotions")
            .finally () ->
              element.removeClass "checkout-section-loading"
              scope.$emit "checkout.cart.content.loaded"

        element.on "destroy", (() -> element.off()) # Unbind events
        element.on "click", ".btn-remove-coupon", (event) ->
          $container = angular.element(event.currentTarget).closest(".promotion")
          code = $container.attr("data-coupon-code")
          return if _.isEmpty(code)
          $rootScope.$broadcast "checkout.cart.coupon.remove", code

        # on initialize
        $timeout(() ->
          scope.$emit "checkout.cart.content.loaded"
        , 100)
    }
])

app.directive('productReviewCommentsForm', [
  '$http',
  'imageService',
  'imageServiceEndpoint',
  'mainConfig',
  'productReviewCommentService',
  'pnotifyService',
  'ordersService',
  function (
    $http,
    imageService,
    imageServiceEndpoint,
    mainConfig,
    productReviewCommentService,
    pnotifyService,
    ordersService,
  ) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/product_review_comments/templates.product_review_comments_form.html'),
      scope: {
        currentOrderId: '=',
        allowUploadMedia: '=',
      },
      link: function ($scope) {
        $scope.imageServiceEndpoint = imageServiceEndpoint;
        $scope.imageService = imageService;
        $scope.ordersService = ordersService;
        $scope.submitForm = function () {
          var invalidComment = $scope.productReviewComments.some(function (
            comment,
          ) {
            return comment.score == 0;
          });
          if (invalidComment) {
            $scope.formState = 'invalid';
            return;
          }

          var payload = {
            user_id: mainConfig.currentUser._id,
            order_id: $scope.currentOrder._id,
            product_review_comments: $scope.productReviewComments.map(function (
              formData,
            ) {
              return {
                score: formData.score,
                comment: formData.comment,
                product_id: formData.product_id,
                media_ids: formData.medias.map(function (media) {
                  return media._id;
                }),
              };
            }),
          };
          $http({
            method: 'POST',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            url:
              '/api/merchants/' +
              mainConfig.merchantId +
              '/orders/' +
              $scope.currentOrder._id +
              '/product_review_comments/bulk',
            data: payload,
          })
            .then(function () {
              $scope.formState = 'submitted';
            })
            .catch(function (err) {
              pnotifyService.notify(err.data.error, {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              });
            });
        };

        var getOrder = function (orderId) {
          productReviewCommentService
            .getOrder(orderId)
            .then(function (response) {
              $scope.currentOrder = response.data;
              $scope.currentOrder.subtotal_items = response.data.subtotal_items.reduce(
                function (result, currentItem) {
                  var duplicatedItem = result.find(function (item) {
                    return item.object_data._id === currentItem.object_data._id;
                  });
                  if (currentItem.item_type == 'ProductSet') {
                    return result.concat([currentItem]);
                  } else if (
                    duplicatedItem ||
                    currentItem.item_type != 'Product'
                  ) {
                    return result;
                  } else {
                    return result.concat([currentItem]);
                  }
                },
                [],
              );
              initFormData($scope.currentOrder);
            });
        };

        var initFormData = function (order) {
          $scope.formState = 'editing';
          $scope.productReviewComments = order.subtotal_items.map(function (
            item,
          ) {
            return {
              score: 0,
              comment: '',
              product_id: item.object_data._id,
              medias: [],
            };
          });
          //focus a textarea on mobile devices to make it scroll to the form
          var textarea = document.getElementsByTagName('textarea')[0];
          if (textarea) {
            textarea.focus();
          }
        };

        $scope.$watch('currentOrderId', function (newValue) {
          getOrder(newValue);
        });

        $scope.getOrders = function () {
          $scope.$parent.getOrders();
        };
      },
    };
  },
]);

app.service('pnotifyService', [
  function () {
    this.notify = function (title, styleParams) {
      new PNotify({
        title: title,
        addclass: styleParams.customClass || 'success',
        animate: styleParams.animate || {
          animate: true,
          in_class: 'fadeInDown',
          out_class: 'fadeOut',
        },
        delay: styleParams.delay || 4000,
        icon: styleParams.icon || 'fa fa-check-circle',
      });
    };
  },
]);

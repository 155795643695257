app.service('benchatService', [
  '$http',
  function ($http) {
    var generateLineState = function () {
      return Math.floor(Math.random() * 10000000000000000);
    };

    var state = generateLineState();

    return {
      getSubscriptions: function (customerId) {
        return $http.get('/api/benchat/subscriptions', {
          params: { userId: customerId },
        });
      },
      getLineActiveSubscriptions: function (topics, subscriptions) {
        return _.reduce(
          subscriptions,
          function (acc, subscription) {
            _.each(topics, function (topic) {
              if (
                subscription.isActive &&
                subscription.topic === topic &&
                subscription.platform === 'LINE_MES_API'
              ) {
                acc.push(subscription);
              }
            });
            return acc;
          },
          [],
        );
      },
      getFacebookActiveSubscriptions: function (topics, subscriptions) {
        return _.reduce(
          subscriptions,
          function (acc, subscription) {
            _.each(topics, function (topic) {
              if (
                subscription.isActive &&
                subscription.topic === topic &&
                subscription.platform === 'FACEBOOK'
              ) {
                acc.push(subscription);
              }
            });
            return acc;
          },
          [],
        );
      },
      activateSubscription: function (subscriptionId, subscriptionsParams) {
        return $http.put(
          '/api/benchat/subscriptions/' + subscriptionId + '/activate',
          subscriptionsParams,
        );
      },
      deactivateSubscription: function (subscriptionId) {
        return $http.put(
          '/api/benchat/subscriptions/' + subscriptionId + '/deactivate',
        );
      },
      createSubscription: function (subscriptionParams) {
        return $http.post('/api/benchat/subscriptions', {
          subscription: subscriptionParams,
        });
      },
      patchSubscriptions: function (subscriptionsParams) {
        return $http.patch('/api/benchat/subscriptions', subscriptionsParams);
      },
      generateUserRef: function (customerIdentifier) {
        // user-ref has to be unique on each rendering, so a random nonce is appended
        return (
          customerIdentifier +
          '-' +
          Math.floor(Math.random() * 10000000000000000)
        );
      },
      getLineLoginUri: function (channelId) {
        return (
          'https://access.line.me/oauth2/v2.1/authorize?' +
          'response_type=code&bot_prompt=aggressive&state=' +
          state +
          '&scope=profile openid email&client_id=' +
          channelId +
          '&redirect_uri=' +
          window.location.origin +
          '/users/auth/line/setup'
        );
      },
      getLineMessageSubscriptionPopupUri: function (
        channelId,
        customerIdentifier,
      ) {
        var query = this.generateLineQuery(customerIdentifier, 'POPUP');
        return (
          'https://access.line.me/oauth2/v2.1/authorize?' +
          'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
          state +
          '&scope=profile openid&client_id=' +
          channelId +
          '&redirect_uri=' +
          this.getLineOAuthRedirectUri(customerIdentifier, query)
        );
      },
      getLineMessageSubscriptionRedirectToUri: function (
        channelId,
        customerIdentifier,
      ) {
        var query = this.generateLineQuery(customerIdentifier, 'REDIRECT_TO');
        return (
          'https://access.line.me/oauth2/v2.1/authorize?' +
          'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
          state +
          '&scope=profile openid&client_id=' +
          channelId +
          '&redirect_uri=' +
          this.getLineOAuthRedirectUri(customerIdentifier, query)
        );
      },
      generateLineQuery: function (customerIdentifier, action) {
        return (
          customerIdentifier +
          '-' +
          action +
          '-' +
          window.location.pathname +
          window.location.search
        );
      },
      getLineOAuthRedirectUri: function (customerIdentifier, query) {
        return window.location.origin + '/oauth/line/callback?query=' + query;
      },
      getLineQuickSignupUri: function (channelId) {
        return (
          'https://access.line.me/oauth2/v2.1/authorize?' +
          'response_type=code&bot_prompt=aggressive&state=' +
          state +
          '&scope=profile openid email&client_id=' +
          channelId +
          '&redirect_uri=' +
          window.location.origin +
          '/oauth/line/quick_sign_up_callback'
        );
      },
      getChannel: function (channelParams) {
        return $http.get('/api/benchat/channel', { params: channelParams });
      },
      getRule: function (ruleParams) {
        return $http.get('/api/benchat/rules', { params: ruleParams });
      },
    };
  },
]);

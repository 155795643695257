app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('fr', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',

      'recaptcha.language': 'fr',

      'form.validation.required': '{{field_name}} doit être renseigné',
      'form.validation.maxlength': '{{field_name}} est trop long',
      'form.validation.minlength': '{{field_name}} est trop court',
      'form.validation.invalid': "{{field_name}} n'est pas valide",
      'form.validation.pattern': "{{field_name}} n'est pas valide",
      'form.validation.pattern.recipient-name':
        'Espaces et symboles ou plus de  {{limit_length}}  caractères non autorisés.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} doit comporter 10 chiffres',
      'form.validation.email': "{{field_name}} n'est pas valide",
      'form.validation.unique':
        '{{field_name}} est pris. Veuillez effectuer un nouveau choix.',
      'form.validation.confirmation': 'Les mots de passe ne correspondent pas.',
      'form.validation.policy':
        "Vous devez accepter conditions d'utilisation pour continuer.",
      'form.validation.delivery_option.outlying':
        "Le type de livraison que vous avez choisi n'est pas disponible pour les îles éloignées de Taïwan. Veuillez modifier votre adresse de livraison ou choisir une autre méthode de livraison.",
      'form.validation.delivery_option.local':
        "Le type de livraison que vous avez choisi n'est pas disponible pour les îles de Taïwan. Veuillez modifier votre adresse de livraison en îles éloignées ou choisir une autre méthode de livraison.",
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',

      'session.signup.title': "S'inscrire",
      'session.terms.facebook': "S'inscrire via Facebook",
      'session.signup.facebook': 'Identifiant Facebook :',
      'sessions.or': 'OU',
      'session.terms':
        "En continuant  vous acceptez nos conditions d'utilisation.",
      'session.signin.submit': 'Connexion',
      'session.forgot_password': 'Mot de passe oublié',
      'session.signin.title': 'Connexion',
      'session.signin.facebook': 'Identifiant Facebook :',
      'session.mobile_signup.check_mobile.title':
        'Veuillez vérifier que la saisie de votre numéro de mobile est correcte. Un SMS sera envoyé vers ce numéro.',
      'session.mobile_signup.check_mobile.your_number':
        'Votre numéro de mobile.',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Veuillez saisir le numéro de mobile taïwanais (commençant par 09)',
      'session.mobile_signup.check_mobile.send_code':
        "M'envoyer le code de vérification",
      'session.mobile_signup.check_mobile.go_back.1': 'Vous pouvez',
      'session.mobile_signup.check_mobile.go_back.2': 'Retour',
      'session.mobile_signup.check_mobile.go_back.3':
        "pour corriger le numéro de mobile s'il est erroné",
      'session.mobile_signup.input_code.hint':
        'Veuillez saisir le code de vérification reçu',
      'session.mobile_signup.input_code.submit': 'Aller',
      'session.mobile_signup.input_code.resend.countdown':
        'Renvoyer le code de vérification par SMS sur mon mobile ({{resendCountdown}} sec)',
      'session.mobile_signup.input_code.resend':
        'Renvoyer le code de vérification par SMS sur mon mobile',
      'session.forget_passowrd.check_phone.error':
        'Numéro de mobile incorrect  veuillez revérifier',
      'session.mobile_center.check_phone.error':
        'Ce numéro de mobile est déjà pris',

      'user.quick_signup.title': 'Devenez membre maintenant !',
      'user.quick_signup.promotion':
        'Je souhaite recevoir les dernières nouveautés et promotions',
      'user.quick_signup.policy': "J'accepte les",
      'user.quick_signup.submit': 'Rejoignez-nous maintenant !',
      'user.quick_signup.error.format': 'Erreur de format',
      'user.quick_signup.error.confirmed.url': 'Se connecter maintenant',
      'user.quick_signup.validate.title': "Continuer l'inscription",
      'user.quick_signup.validate.success.shop':
        'faire du shopping dès maintenant',
      'user.quick_signup.validate.finish_hint':
        "Finalisez l'inscription pour vérifier et appliquer les crédits du magasin dès maintenant !",
      'user.quick_signup.validate.phone': 'Vérifier',
      'user.quick_signup.validate.send.sms': 'Envoyer le code de vérification',
      'user.quick_signup.validate.wrong.number':
        'Si le numéro de mobile est incorrect  veuillez',
      'user.quick_signup.validate.previous': 'Retour',
      'user.quick_signup.validate.modify': ' le mettre à jour.',
      'user.quick_signup.validate.hint.password': 'Au moins 8 caractères',
      'user.quick_signup.validate.submit': "Finaliser l'inscription",
      'user.quick_signup.validate.placeholder.sms':
        'Saisir le code de vérification',
      'user.quick_signup.validate.placeholder.name': 'Nom',
      'user.quick_signup.validate.placeholder.password':
        'Saisir un mot de passe',
      'user.sign_up.email_verification.dialog.title':
        "L'e-mail de vérification a été envoyé à {{mail}}",
      'user.sign_up.email_verification.dialog.description':
        "Veuillez vérifier l'adresse e-mail maintenant et la nouvelle adresse e-mail sera mise à jour. Si vous ne l'avez pas reçue  veuillez saisir à nouveau votre adresse e-mail et enregistrer.",
      'user.sign_up.email_verification.dialog.confirm': 'Confirmer',
      'user.delivery_data.recipient_phone': 'Numéro de contact du destinataire',

      'product.out_of_stock': 'Épuisé',
      'product.addon_products.label': 'Article supplémentaire',
      'product.bundled_products.label': 'Pack',
      'product.bundle_group_products.label': 'A + B',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Limiter {{message}} par commande.',
      'product.addon_products.errors.larger_addon_product':
        "La quantité d'articles supplémentaires ne peut excéder la quantité de produit principal",
      'product.addon_products.tips.limit_exceed':
        'Lorsque vous ajustez la quantité  la quantité des articles supplémentaires ne peut excéder celle des produits principaux.',
      'product.addon_products.add_to_cart.hint':
        "L'article a bien été ajouté à votre panier",

      'member.center.load.more': 'Plus',
      'member.center.load.more.error':
        'Échec de chargement des données  veuillez réessayer.',

      'wishlist.signin_register': "Se connecter/S'inscrire maintenant",
      'wishlist.messages.remove_error':
        "L'article de la liste de souhaits ne peut être retiré",

      orders: 'Commandes',
      order: 'Commande',
      'orders.fields.billing_address': 'Adresse de facturation',
      'orders.fields.order_number': 'Commande n°',
      'orders.fields.order_date': 'Date',
      'orders.fields.order_status': 'Statut',
      'orders.fields.order_remarks': 'Remarques concernant la commande',
      'orders.fields.customer_name': 'Nom du client',
      'orders.fields.product_subscription_period': '{{period}}',
      'orders.fields.status.temp': 'En attente',
      'orders.fields.status.pending': 'En attente',
      'orders.fields.status.confirmed': 'Confirmée',
      'orders.fields.payment_type': 'Expédiée',
      'orders.fields.payment_instructions': 'Terminée',

      'orders.fields.payment_method.validation.limit_maximum.cvs': 'Reçue',
      'orders.fields.payment_method.validation.limit_lowest.cvs': 'Annulée',
      'orders.fields.payment_method.validation.limit_maximum.barcode': 'Retour',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Retournée',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        "Précommander l'article",
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'Client',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'Numéro de téléphone du client',
      'orders.fields.delivery_method': 'Facturer à',
      'orders.fields.delivery_description': 'Type de paiement',
      'orders.fields.delivery_fee': 'Frais de livraison',
      'orders.fields.delivery_address': 'Adresse de livraison',
      'orders.fields.delivery_address.remarks':
        'Remarques concernant la livraison',
      'orders.fields.accept_terms.validation.required':
        "Vous devez accepter les conditions d'utilisation pour continuer.",
      'orders.fields.options.blacklist.error':
        "Vos produits ont différentes méthodes de livraison ou de paiement. Veuillez choisir d'autres méthodes valides  puis passer votre commande",
      'orders.show.message.thankyou.title': 'Merci pour votre commande',
      'orders.show.thankyou.continue': 'Merci pour votre commande',

      'orders.show.message.shipped.title':
        'Continuez votre shopping maintenant !',
      'orders.show.message.shipped.description':
        'Votre article a été expédié !',

      'orders.payment_slip.order_info.amount': 'Montant de la commande',
      'orders.payment_slip.order_info.instructions': 'Instructions de paiement',
      'orders.payment_slip.order_info.read_more':
        'Voir les instructions complètes',
      'orders.payment_slip.upload_form.title':
        "Après la fin du transfert, s'il vous plaît télécharger les détails de transfert de paiement",
      'orders.payment_slip.upload_form.hint':
        "Après avoir confirmé le paiement, nous organiserons l'expédition le plus tôt possible!",
      'orders.payment_slip.upload_form.notification':
        "Pour obtenir la dernière notification de commande, s'il vous plaît abonnez-vous",
      'orders.payment_slip.upload_form.image_input.title':
        'Transfert Upload Détails',
      'orders.payment_slip.upload_form.image_input.description':
        "S'il vous plaît confirmer que l'image est claire, et comprend 5 derniers chiffres du numéro de transaction, le temps de transfert et le montant.",
      'orders.payment_slip.upload_form.image_input.change':
        "Cliquez pour changer d'image",
      'orders.payment_slip.upload_form.image_input.error':
        "La taille de l'image doit être inférieure à 10 Mo",
      'orders.payment_slip.upload_form.paid_time':
        'Choisissez Délai de paiement',
      'orders.payment_slip.upload_form.message':
        'Remplissez votre Remarques de paiement',
      'orders.payment_slip.upload_form.message.error':
        'Ne peut pas dépasser {{ n }} caractères',
      'orders.payment_slip.uploaded.alert':
        'Merci pour les détails de transfert de paiement de téléchargement, nous vous confirmerons le plus tôt possible!',
      'orders.payment_slip.uploaded.notification':
        'Abonnez-vous pour obtenir les dernières informations de commande',
      'orders.payment_slip.uploaded.title':
        'Ci-dessous est les détails de votre transfert',
      'orders.payment_slip.uploaded.paid_time': 'Délai de paiement:',
      'orders.payment_slip.uploaded.upload_time': 'Mise à jour Temps Preuve:',
      'orders.payment_slip.uploaded.order_link': "Voir l'ordre",
      'orders.payment_slip.uploaded_without_login.title':
        'Merci! Les détails du virement de paiement ont été téléchargés pour cette commande',
      'orders.payment_slip.uploaded_without_login.hint':
        "S'il vous plaît Connectez-vous pour voir les détails",
      'orders.payment_slip.footer':
        'Les informations de paiement que vous avez rempli est uniquement pour vérifier et il est crypté en toute sécurité. Vous pouvez effectuer votre paiement en toute simplicité.',

      'orders.index.no_records.title': "Vous n'avez encore aucune commande.",
      'orders.index.no_records.description':
        "Il semblerait que vous n'ayez effectué aucune commande dans ce magasin.",
      'orders.index.no_records.action': 'Faire du shopping !',
      'orders.index.title': 'Commandes',
      'orders.index.action.view': 'Afficher',
      'address.fields.address.recipient_name': 'Nom du destinataire',
      'address.fields.address': 'Adresse',
      'address.fields.city': 'Ville',
      'address.fields.postcode': 'Code postal',
      'address.fields.country': 'Pays',

      'orders.fields.subtotal': 'Sous-total',
      'orders.fields.total': 'Total',
      'order.billing_address.same': "Identique à l'adresse de livraison",

      'orders.coupons.label': 'Code de réduction',
      'orders.coupons':
        'Félicitations ! Vous avez droit à la réduction suivante :',
      'orders.coupons.placeholder': 'Saisir le code de réduction',
      'orders.promotion_coupons.placeholder': 'Saisir le code de réduction',

      'orders.coupons.apply': 'Appliquez le bon',
      'orders.fields.order_discount': 'Réduction appliquée',
      'orders.fields.order_custom_discount': 'Réduction (personnalisée)',
      'orders.coupons.invalid': "Malheureusement  votre bon n'est pas valable.",
      'orders.coupons.validation.minamount':
        'Malheureusement  votre commande ne correspond pas à la valeur requise pour ce bon',
      'orders.coupons.invalid.order':
        'Veuillez supprimer les codes de réduction non valides avant de passer votre commande.',

      'orders.actions.title': 'Gérer le statut de la commande',
      'orders.action.cancel': 'Annuler la commande',
      'orders.action.confirm': 'Confirmer la commande',
      'orders.action.ship': 'Marquer comme expédiée',
      'orders.general.title': 'Détails de la commande',
      'orders.payment.title': 'Détails du paiement',
      'orders.delivery.title': 'Détails de livraison',
      'orders.products.title': 'Détails du produit',

      'orders.fields.accept_terms':
        "Vous acceptez les conditions d'utilisation et la politique de confidentialité du magasin",
      'orders.action.checkout': 'Passer une commande',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Nom du destinataire',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'Nom du magasin 7-11',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'Code de magasin 7-11',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Nom du destinataire',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Nom du magasin Family Mart',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Code du magasin Family Mart',
      'orders.fields.delivery_data.tw_ezship.location_code.hint':
        'Trouver un magasin',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        'Code du magasin',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        'Nom du magasin',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Nom du destinataire',
      'orders.fields.delivery_data.tw_ezship.sn_id.label':
        'Identifiant de référence',

      // Checkout page error message field name
      'order.customer_name': 'Nom du client',
      'order.email': 'Adresse e-mail',
      'order.customer_email': 'Adresse e-mail du client',
      'order.customer_phone': 'Numéro de contact du client',
      'order.delivery_data.recipient_name': 'Nom du destinataire',
      'order.delivery_data.recipient_phone':
        'Numéro de contact du destinataire',
      'order.delivery_address.address_1': 'Adresse',
      'order.delivery_address.city': 'Ville',
      'order.delivery_address.state': 'Département/état/province',
      'order.delivery_data.location_code': 'Code du magasin',
      'order.delivery_data.location_name': 'Nom du magasin',
      'order.delivery_data.time_slot': 'Délai de livraison',
      'order.delivery_data.scheduled_delivery_date': "Date d'arrivée",
      'order.delivery_data.time_slot_key': "Créneau d'arrivée",
      'order.invoice.carrier_number': 'Transporteur',
      'order.invoice.mailing_address': 'Adresse',
      'order.invoice.tax_id': 'Identifiant fiscal',
      'order.payment_data.holdername': 'Nom complet du titulaire de la carte',
      'order.payment_data.expiry_date': "Date d'expiration",
      'order.payment_data.cvc': 'Cryptogramme visuel',
      'order.payment_data.credit_card_number': 'Numéro de la carte de crédit',
      'delivery.sfexpress.district': 'Zone SF Express',
      'delivery.sfexpress.store': 'Emplacement SF Express',
      'delivery_address.region.hk': 'Région',
      'delivery_address.district.hk': 'Département',
      'delivery_address.region.tw': 'État/ville',
      'delivery_address.district.tw': 'Département',
      'save_fields.customer_info.birthday': 'Anniversaire du client',

      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'Province',
      'delivery_address.district.th': 'District',
      'delivery_address.ward.th': 'Sub-district',

      'delivery_options.fields.delivery_type': 'Type de livraison',
      'delivery_options.fields.delivery_types.pickup': 'Retrait',
      'delivery_options.fields.delivery_types.local': 'Local',
      'delivery_options.fields.delivery_types.email': 'E-mail',
      'delivery_options.fields.delivery_types.international': 'International',
      'delivery_options.fields.delivery_types.custom': 'Personnalisé',
      'delivery_options.fields.delivery_types.tw_simple_711':
        'Retrait 7-11 (TW uniquement) ',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Retrait Family Mart (TW uniquement) ',
      'delivery_options.fields.select_date': "Date d'arrivée",

      'ezship.error.invalid_input':
        'Un ou plusieurs champs sont non valides ou manquants',
      'ezship.error.account_not_exist':
        "Le compte ezShip n'existe pas. Veuillez contacter le vendeur",
      'ezship.error.no_permission':
        'Les autorisations du compte ezShip sont insuffisantes',
      'ezship.error.no_easybag':
        "Le compte ezShip n'a pas d'Easybag ou de Minibag disponible",
      'ezship.error.invalid_store': 'Magasin de retrait non valide',
      'ezship.error.invalid_amount':
        "Quantité non valide. Le montant maximum autorisé pour l'utilisation d'ezShip est de 6 000 TWD par commande.",
      'ezship.error.invalid_email': 'E-mail non valide',
      'ezship.error.invalid_mobile': 'Numéro de téléphone non valide',
      'ezship.error.invalid_name': 'Nom du destinataire non valide',
      'ezship.error.system_error':
        'Erreur système ezShip. Veuillez choisir une autre méthode de livraison ou réessayez ultérieurement.',

      'payments.fields.types.paypal': 'Carte de crédit ou Paypal',
      'payments.fields.types.paypal_express': 'Carte de crédit ou Paypal',
      'payments.fields.types.credit_card': 'Carte de crédit',
      'payments.fields.types.bank_transfer': 'Virement bancaire',
      'payments.fields.types.cash_on_delivery': 'Paiement à la livraison',
      'payments.fields.types.free_checkout': 'Paiement libre',
      'payments.fields.types.custom': 'Personnalisé',
      'payments.fields.types.allpay_barcode': 'Code-barres via Allpay',
      'payments.fields.types.allpay_credit': 'Carte de crédit via Allpay',
      'payments.fields.types.allpay_cvs': 'CVS via Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM via Allpay',
      'payments.fields.types.asiapay': 'Carte de crédit via Asiapay',
      'payments.fields.types.prizm': 'Carte de crédit Visa / Master',
      'payments.fields.types.esun': 'Carte de crédit Esun',

      'payment.cc.expirydate': "Date d'expiration",
      'payment.cc.holdername': 'Nom du titulaire de la carte ',
      'payment.cc.number': 'Numéro de carte de crédit (Visa/Master) ',
      'payment.cc.cvc': 'Cryptogramme visuel',

      'payment.cc.expirydate.placeholder': 'MM/AA',
      'payment.cc.holdername.placeholder': 'Nom du titulaire de la carte ',
      'payment.cc.holdername.hint': 'Identique au nom indiqué sur la carte',
      'payment.cc.number.placeholder':
        'Numéro de la carte de crédit (sans espaces) ',
      'payment.cc.cvc.placeholder': 'Cryptogramme visuel',
      'payment.cc.cvc.hint':
        'Le code de sécurité à 3 chiffres est généralement indiqué au verso de votre carte.',
      'payment.action.confirm': 'Confirmer',
      'payment.cc.update.instructions':
        'Veuillez saisir le numéro de votre carte de crédit ci-dessous pour mettre à jour',
      'payment.cc.fields.accept_terms':
        "J'accepte les conditions d'utilisation et la politique de confidentialité",
      'payment.cc.fields.accept_terms.validation.required':
        "Vous devez accepter les conditions d'utilisation pour continuer.",
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship':
        'Paiement lors du retrait en magasin via ezShip',

      'cart.title': 'Panier',
      'cart.item.remove': 'Supprimer',
      'cart.items.remove': 'Supprimer',
      'cart.checkout': 'Paiement',
      'cart.empty': 'Votre panier est vide.',
      'cart.empty.description':
        'Ajoutez des produits dans le panier pour continuer.',
      'cart.empty.continue': 'Continuez votre shopping maintenant !',
      'cart.promotion.checkout': 'Paiement',

      'checkout.instructions': 'Détails de la commande',
      'checkout.fields.email': 'E-mail du client',
      'checkout.fields.email.hint':
        'Veuillez saisir votre adresse e-mail correcte car la confirmation de commande vous sera envoyée par e-mail. ',
      'checkout.fields.phone.hint':
        'Veuillez saisir votre numéro de téléphone portable pour recevoir des mise à jour de statut de livraison par SMS',
      'checkout.fields.phone.invalid': 'Numéro de téléphone non valide',
      'checkout.fields.email.placeholder': 'Adresse e-mail',
      'checkout.fields.remarks': 'Remarques pour le magasin',
      'checkout.fields.remarks.placeholder':
        'Laissez des remarques concernant votre commande pour le magasin',
      'checkout.fields.delivery_method': 'Méthode de livraison',
      'checkout.fields.out_of_stock':
        "Malheureusement  le stock est insuffisant. Veuillez retirer l'article du panier et procéder à nouveau au paiement",
      'checkout.fields.phone': 'Numéro de téléphone de contact',
      'checkout.fields.phone.placeholder':
        'Saisissez votre numéro de téléphone de contact',
      'checkout.fields.add_new': 'Ajouter nouvelle',
      'checkout.fields.select': 'Sélectionnez une option',

      'checkout.delivery_option.title': 'Adresse de livraison',
      'checkout.instalment_amount':
        'environ {{amount}} x {{period}} versements ',

      'checkout.payment_method.title': 'Paiement',

      'checkout.discount.applied_promotions': 'Promotions appliquées',
      'checkout.discount.applied_coupons': 'Bons de réduction appliqués',

      'checkout.promotion.tags.promotion': 'Promotion',
      'checkout.promotion.tags.coupon': 'Bon',
      'checkout.promotion.tags.membership': 'Offre pour les membres',
      'checkout.promotion.tags.free_shipping': 'Livraison gratuite',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',

      'checkout.payment_failed':
        'Cette transaction a échoué  veuillez réessayer.',
      'checkout.timeout': 'Nom du destinataire',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': 'Nom du destinataire',
      'address.fields.address.city': 'Ville',
      'address.fields.address.state': 'Département/état/province',
      'address.fields.address.postcode': 'Code postal (le cas échéant)',
      'address.fields.address.country': 'Pays',
      'address.fields.address.remarks': 'Remarques',

      'messages.form.send': 'Envoyer',
      'action.add_photo': 'Ajouter une photo',
      'action.send': 'Envoyer',
      'action.load_earlier': 'Charger précédents',
      'action.load_more': 'Charger plus',

      'users.fields.name': 'Nom complet',
      'users.fields.email': 'E-mail',
      'users.fields.mobile_phone_or_email': 'E-mail ou numéro mobile',
      'users.fields.password': 'Mot de passe',
      'users.fields.name.placeholder': 'Nom complet',
      'users.fields.email.placeholder': 'E-mail',
      'users.fields.password.placeholder': 'Mot de passe',
      'users.save.success': 'Vos modifications ont été enregistrées',
      'users.save.email_verification.success':
        "Vos modifications ont été enregistrées L'adresse e-mail sera mise à jour une fois la vérification effectuée.",
      'users.add.phone': 'Ajouter un nouveau numéro',
      'users.add.phone.maximum':
        'Vous pouvez au maximum 5 numéros de téléphone.',
      'users.error.phone.format': "Le numéro de téléphone n'est pas valide",
      'users.error.phone.required':
        'Le numéro de téléphone doit être renseigné',
      'users.add.delivery_address': 'Ajouter une nouvelle adresse ',
      'users.add.delivery_address.maximum':
        'Vous pouvez ajouter 5 adresses au maximum.',
      'users.error.not_save_mobile_phone':
        'Please verify this phone number before saving change.',
      'users.error.no_changes': 'Aucune modification à enregistrer.',
      'users.error.maxlength': ' est trop long.',
      'users.signup.male': 'Homme',
      'users.signup.female': 'Femme',
      'users.signup.other': 'Non précisé',
      'users.signup.email': "S'inscrire par e-mail ",
      'users.signup.mobile_phone': "S'inscrire par numéro de mobile",
      'users.signup.unconfirmed':
        "Contents de vous revoir ! Pour protéger votre compte  une vérification est nécessaire pour vous connecter à votre compte. L'e-mail de vérification a été déjà envoyé à {{email}}. Veuillez consulter votre boîte de réception  merci !",
      'users.signup.verification.hint.send':
        "L'e-mail de vérification a été envoyé. Vous pouvez renvoyer un nouveau code après {{count}} secondes.",
      'users.einvoice_carrier':
        'Récapituler la facture électronique dans votre compte',

      'membership.profile.subscriptions.orders.update.status':
        'Mises à jour de mes commandes',
      'membership.profile.subscriptions.orders.comments.new':
        'Nouveaux commentaires du vendeur sur mes commandes',
      'membership.profile.subscriptions.messages.new':
        'Nouveaux messages du vendeur',
      'membership.profile.subscriptions.marketing.news':
        'Mises à jour et promotions du magasin',
      'membership.profile.subscriptions.none': 'Aucun(e)',
      'membership.profile.mobile.number.verified':
        'Numéro de mobile vérifié. Il remplacera tout numéro de téléphone sauvegardé précédemment.',
      'membership.profile.check.code.error':
        'Code de vérification incorrect ! Veuillez le ressaisir ou renvoyer le code de vérification par SMS',

      'store_credits.title': 'Crédits du magasin',
      'store_credits.credit_balance': 'Solde de crédits',
      'store_credits.credit_history': 'Historique des crédits',
      'store_credits.fields.date': 'Date',
      'store_credits.fields.remarks': 'Motif de modifications des crédits',
      'store_credits.fields.value': 'Mouvement de crédits',
      'store_credits.fields.expiry_date': "Date d'expiration",
      'store_credits.fields.balance': 'Solde de crédits',
      'store_credits.fields.order_prefix':
        'Utilisation des crédits dans la commade',
      'store_credits.fields.welcome_credit': 'Crédits de bienvenue',
      'store_credits.fields.birthday_credit': "Crédits d'anniversaire",
      'store_credits.fields.user_credit_expired': 'Crédits expirés',
      'store_credits.fields.never_expires': 'Aucune expiration',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward':
        "Crédits accordés sur un minimum d'achat dans la commande",
      'store_credits.fields.revert_credit':
        "Crédits récupérés d'une commande annulée",
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.no_data': 'Aucun historique de crédits',

      'error.404.title': 'Malheureusement ',
      'error.404.description': "Cette page n'existe pas",
      'error.401.title': 'Malheureusement ',
      'error.401.description':
        "Vous n'êtes pas autorisé(e) à accéder à cette page",
      'error.403.title': 'Malheureusement ',
      'error.403.description':
        "Vous n'êtes pas autorisé(e) à accéder à cette page",
      'error.403.not_signed_in.anchor': 'connecté(e)',
      'error.continue': 'Continuez votre shopping maintenant !',

      'orders.comments.title': 'Commentaires du magasin et des clients',
      'orders.comments.text.name': 'Commenter',
      'orders.comments.text.placeholder':
        'Rédigez un commentaire pour cette commande',

      'orders.fields.payment_fee': 'Frais de paiement',

      'orders.invoices.title': 'Facture',
      'orders.invoices.carrier': 'Transporteur général',
      'orders.invoices.carrier_type.member': 'Membre',
      'orders.invoices.carrier_type.mobile_barcode': 'Code-barres mobile',
      'orders.invoices.carrier_type.npc_barcode': 'Code-barres NPC',
      'orders.invoices.get_invoice': 'Il me faut un reçu',

      'orders.notifications.title':
        'Notifications de mise à jour de la commande',

      'order.payments.login.confirm':
        'Veuillez vous connecter et payer à nouveau !',

      'model.credit_card.title':
        'Informations relatives à votre carte de crédit',
      'model.credit_card.subtitle':
        'Veuillez saisir le numéro de votre carte de crédit ci-dessous pour mettre à jour ',

      'facebook_messenger_checkbox.subscribed': 'Envoyé via',
      'facebook_messenger_checkbox.turn_off': 'Désactiver',
      'facebook_messenger_checkbox.topics.MARKETING':
        'Mises à jour et promotions du magasin',

      'promotions.section.discount_summary.set.on_item':
        'Obtenez <b>%{discount_value}</b> de réduction sur une <b>sélection de produits</b>',
      'promotions.section.discount_summary.set.on_order':
        'Obtenez <b>%{discount_value}</b> de réduction sur <b>toute la commande</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? Currency : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Sélection de produits</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Toute la commande</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        " pour tout achat d'une <b>sélection de produits</b> <b>{{ condition_value }}</b>",
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' pour tout achat  <b>%{condition_value}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{count}} articles',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption':
        '{{ value }}% de réduction',

      'product.page.title.product-info': 'Produit',
      'product.page.quantity': 'Qté',
      'product.page.unit_price': "Prix à l'unité",
      'product.page.subtotal': 'Sous-total',

      'products.quick_cart.quantity': 'Quantité',
      'products.quick_cart.preorder_now': 'PRÉCOMMANDEZ MAINTENANT',
      'products.quick_cart.add_to_cart': 'AJOUTER AU PANIER',
      'products.quick_cart.out_of_stock': 'ÉPUISÉ',
      'products.quick_cart.out_of_stock_hint':
        "Stock insuffisant.\n L'article a été ajouté à votre panier",
      'products.quick_cart.low_stock_hint':
        'Stock insuffisant.\n Veuillez ajuster la quantité.',
      'products.quick_cart.reached_max_purchase_quantity':
        'Limiter {{message}} par commande.',
      'products.quick_cart.quantity_of_stock_hint':
        'Seulement {{message}} article(s) restant(s).',
      'products.quick_cart.messagetobuy':
        'Veuillez envoyer un message au propriétaire du magasin pour obtenir des détails concernant la commande.',
      'products.quick_cart.message': 'MESSAGE',
      'products.quick_cart.show_more': 'Plus de détails',
      'products.message.title':
        'Souhaitez-vous vraiment supprimer cet article ?',
      'products.message.button_cancel': 'Annuler',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'Filtre',
      'products.category.advance_filter.clear': 'Effacer la sélection',
      'products.category.advance_filter.clear_all': 'Tout effacer',
      'products.category.advance_filter.apply': 'Appliquer le filtre',
      'products.category.advance_filter.apply.hint':
        'Veuillez d’abord sélectionner les options de filtre',
      'products.category.advance_filter.show_more': 'Montre plus',
      'products.category.advance_filter.show_less': 'Montrer moins',
      'products.category.advance_filter.type.color': 'Couleur',
      'products.category.advance_filter.type.size': 'Taille',
      'products.category.advance_filter.type.brand': 'Marque',
      'products.category.advance_filter.type.material': 'Matériel',
      'products.category.advance_filter.type.price':
        'Échelle des prix ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小金額',
      'products.category.advance_filter.placeholder.max_price': '最大金額',

      'hk_sfplus.region': 'Région',
      'hk_sfplus.area': 'Secteur',
      'hk_sfplus.district': 'Département',
      'hk_sfplus.address': '{{region}}  {{district}}  {{area}}',

      'tcat.time_slot.title': "Créneau d'arrivée",
      'tcat.time_slot.01': 'Avant 13h',
      'tcat.time_slot.02': 'Entre 14h et 18h',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'à toute heure',
    });
    $translateProvider.preferredLanguage('fr');
  },
]);

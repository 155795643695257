app.directive('productCustomerReview', [
  '$http',
  '$filter',
  'product',
  'featureService',
  'staticImageHost',
  function ($http, $filter, product, featureService, staticImageHost) {
    return {
      templateUrl: require('../../../../../public/themes/shared/product/templates.product_customer_review.html'),
      restrict: 'E',
      scope: false,
      link: function (scope) {
        var amazonAsin = product.amazon_asin;
        if (
          !amazonAsin ||
          !featureService.hasFeature('amazon_product_review')
        ) {
          return;
        }
        scope.showMoreUrl =
          'https://www.amazon.com/product-reviews/' + amazonAsin;
        var pagination = {
          currentPage: 1,
          limit: 10,
        };

        var fetchProductReviews = function () {
          $http
            .get('/api/amazon_products/' + amazonAsin + '/reviews', {
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
              params: {
                page: pagination.currentPage,
                limit: pagination.limit,
              },
            })
            .then(function (res) {
              scope.reviewData = res.data;
              scope.amazonProductReview = res.data.amazon_product;
            })
            .catch(function () {
              scope.reviewData = null;
              scope.amazonProductReview = null;
            });
        };

        scope.reviewSummaryInfo = function () {
          if (scope.mainConfig.localeData.loadedLanguage.code === 'ja') {
            return (
              $filter('translate')('product.product_review.out_of_5_stars') +
              scope.reviewData.amazon_product.avg_rating
            );
          } else {
            return (
              scope.reviewData.amazon_product.avg_rating +
              $filter('translate')('product.product_review.out_of_5_stars')
            );
          }
        };
        scope.staticImageHost = staticImageHost;

        fetchProductReviews();
      },
    };
  },
]);

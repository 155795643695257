app.directive("checkoutDeliverySfexpress", [
  '$compile'
  '$filter'
  'sfexpressService'
  'cart'
  '$q'
  (
    $compile
    $filter
    sfexpressService
    cart,
    $q
  ) ->
    {
      restrict: 'A'
      scope: {
        defaultLocationCode: "@"
        getLocationCallback: "&onLocationChange"
      }
      link: (scope, element, attrs) ->
        initAngularForm = () ->
          $sfexpress = element
          if $sfexpress.length == 1
            $sfexpress.find("#sf-express-location-name")
              .attr("ng-model", "location_name")
            $sfexpress
              .find("#sf-express-district select")
              .attr("ng-model", "district")
              .attr("ng-options", "(location.district_translations| translateModel) for location in sf_locations")
              .attr("ng-change", "onSfDistrictChange()")
            $sfexpress
              .find("#sf-express-location select")
              .attr("ng-model", "location_code")
              .attr("ng-options", "location.code as (location.name_translations| translateModel) for location in availableSfLocations")
              .attr("ng-change", "onSfLocationChange()")
              .attr("ng-disabled", "!availableSfLocations")
            $sfexpress.html($compile($sfexpress.html())(scope))

        setSfLocations = () ->
          locationList = cart.sfexpress_locations
          locationsByDistrict = []
          defaultDistrict = null
          defaultLocation = null
          _.each locationList, (location, index) ->
            district = _.find(locationsByDistrict, ((d) -> return d.district_translations.en == location.district_translations.en))
            if !district
              district = {
                district_translations: location.district_translations
                locations: []
              }
              locationsByDistrict.push(district)

            district.locations.push({
              code: location.code
              name_translations: location.name_translations
            })

            if scope.defaultLocationCode && location.code is scope.defaultLocationCode
              defaultLocation = location
              defaultDistrict = district

          scope.sf_locations = _.sortBy locationsByDistrict, ((d) -> d.district_translations.en)

          # Pre-select location from order preference
          if defaultDistrict && defaultLocation
            scope.district = defaultDistrict
            scope.onSfDistrictChange()
            scope.location_code = defaultLocation.code
            scope.onSfLocationChange()

        scope.onSfDistrictChange = () ->
          scope.location_code = undefined
          scope.onSfLocationChange()
          if scope.district
            scope.availableSfLocations = _.find(scope.sf_locations, ((district) -> district.district_translations.en == scope.district.district_translations.en) ).locations

        scope.onSfLocationChange = () ->
          if scope.location_code?
            sfLocation = _.find scope.availableSfLocations, ((location) -> location.code == scope.location_code )
            element.find("#sf-express-location-name").val JSON.stringify(sfLocation.name_translations)
            element.find("#sf-express-location-code").val sfLocation.code
          else
            element.find("#sf-express-location-name").val ""
            element.find("#sf-express-location-code").val ""
          null # By-default coffee will return last statement from a function, and returning DOM node to an angular method will raise error (non-blocking)

        valueWatcher = scope.$watch "location_code", (newValue, oldValue) ->
          return if newValue is oldValue || scope.location_code is scope.defaultLocationCode
          scope.defaultLocationCode = scope.location_code
          scope.getLocationCallback()(scope.location_code) if scope.getLocationCallback()?

        rerenderWatcher = scope.$on "checkout.cart.form.loaded", () ->
          # Initialze
          setSfLocations()
          initAngularForm()          

        cleanup = () ->
          valueWatcher()
          rerenderWatcher()

        scope.$on '$destroy', (() -> cleanup())

        null
    }
])
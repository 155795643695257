app.service('cartService', [
  '$rootScope',
  '$http',
  'merchantService',
  'mainConfig',
  '$q',
  'trackerService',
  'productService',
  'featureService',
  '$filter',
  'Analytics',
  '$injector',
  'gaService',
  'slFeatureService',
  'slPixelService',
  '$cookies',
  'hiidoTrackerService',
  function (
    $rootScope,
    $http,
    merchantService,
    mainConfig,
    $q,
    trackerService,
    productService,
    featureService,
    $filter,
    Analytics,
    $injector,
    gaService,
    slFeatureService,
    slPixelService,
    $cookies,
    hiidoTrackerService,
  ) {
    this.merchantId = null;
    this.pageId = null; // for page cart
    var ERROR_CODE = {
      PRODUCT_INSUFFICIENT_STOCK: 'PRODUCT_INSUFFICIENT_STOCK',
    };
    var cartData = null;
    var fastCheckoutCartData = null;
    var orderData = null;
    //var subtotal_cents = null;
    var itemCount = undefined;
    var itemCountLoading;

    // inject data if cart is preloaded
    if ($injector.has('cart')) {
      $injector.invoke([
        'cart',
        function (cart) {
          cartData = cart;
        },
      ]);
    }

    function calculateItemCount() {
      return cartData.items.reduce(function (count, item) {
        if (
          ![
            'product',
            'addon_product',
            'subscription_product',
            'redeem_gift',
            'manual_gift',
          ].includes(item.type) &&
          !(
            item.type == 'product_set' &&
            slFeatureService.hasFeature('product_set')
          ) &&
          !(
            item.type === 'product_set' &&
            slFeatureService.hasFeature('product_set_revamp')
          )
        ) {
          return count;
        }
        if (item.addon_items) {
          count += item.addon_items.length;
        }
        return (count += 1);
      }, 0);
    }

    // shoplytics tracking
    function cartTracking(action, item, options) {
      options = options || {};
      var eventCategory = options.eventCategory || 'Cart';
      var trackingTypes = [
        'product',
        'addon_product',
        'subscription_product',
        'product_set',
      ];
      if (trackingTypes.includes(item.type)) {
        trackerService.userAction(action, eventCategory, {
          type: item.type,
          productId: item.product_id,
          variationId: item.variation_id,
          quantity: item.quantity,
          price: item.price,
        });

        // adapt hiido tracking cart action
        hiidoTrackerService.adaptCartEvents(action, item, options);
      }
    }

    function sendSlPixelTracking(actionName, item, specificQuantity) {
      if (!_.isObject(item)) return;
      var price = getItemPrice(item);
      var quantity = specificQuantity || item.quantity;

      slPixelService.hdTracking(actionName, null, {
        cartItem: {
          productID: item.product_id,
          variationID: item.variation_id,
          type: item.type,
          name:
            _.isObject(item.product) &&
            _.isObject(item.product.title_translations)
              ? $filter('translateModel')(item.product.title_translations)
              : null,
          currency: price && price.currency_iso,
          price: price && price.dollars,
          quantity: quantity,
        },
      });
    }

    function generateSlPixelParams(items, cartItems, mainProductId) {
      return items.map(function (item) {
        var isMainProduct = item.product_id === mainProductId;
        var type = item.type || (isMainProduct ? 'product' : 'addon_product');
        var productFromCart = null;
        if (isMainProduct) {
          productFromCart = cartItems.find(function (cartItem) {
            return cartItem.product_id === item.product_id;
          });
        } else {
          cartItems.forEach(function (cartItem) {
            if (!productFromCart) {
              productFromCart =
                cartItem.addon_items &&
                cartItem.addon_items.find(function (addonItem) {
                  return addonItem.product_id === item.product_id;
                });
            }
          });
        }
        var price = _.isObject(productFromCart)
          ? getItemPrice(productFromCart)
          : {};

        return {
          product_id: item.product_id,
          quantity: item.quantity,
          variation_id: _.isObject(item.variation_id)
            ? item.variation_id.key
            : '',
          type: type,
          price: price,
          product: _.isObject(productFromCart) ? productFromCart.product : {},
        };
      });
    }

    var getSubtotal = function () {
      if (cartData == null) return {};
      return cartData.subtotal;
    };

    var getItemCount = function () {
      if (_.isEmpty(cartData) === false && cartData.items) {
        var count = calculateItemCount();
        if (itemCount != count) {
          itemCount = count;
          $rootScope.$broadcast('cartItemCountUpdated', itemCount);
        }
      }

      if (itemCountLoading) {
        return;
      }

      if (itemCount === undefined) {
        itemCountLoading = true;
        $http({
          method: 'GET',
          url: '/api/merchants/' + this.merchantId + '/cart/count',
          params: { page_id: this.pageId },
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
          .then(
            function (res) {
              itemCount = res.data.count;
            },
            function () {
              itemCount = 0;
            },
          )
          .finally(function () {
            itemCountLoading = false;
            $rootScope.$broadcast('cartItemCountUpdated', itemCount);
          });
      }
      return itemCount;
    };

    var getItemPrice = function (item) {
      var currentFlashPriceSet = productService.getCurrentFlashPriceSet(
        item.product,
      );

      if (_.isEmpty(item.variation) || item.product.same_price) {
        if (
          !_.isEmpty(currentFlashPriceSet) &&
          currentFlashPriceSet.price_set &&
          !_.isEmpty(currentFlashPriceSet.price_set.price_sale)
        ) {
          return currentFlashPriceSet.price_set.price_sale;
        }

        if (
          item.type === 'product_set' &&
          slFeatureService.hasFeature('product_set_revamp')
        ) {
          return item.price;
        }

        // check if product has product price_sale or member_price
        if (productService.hasLowerPrice(item.product)) {
          // return main product price_sale or member_price
          var product = item.product;

          if (
            mainConfig.currentUser &&
            slFeatureService.hasFeature('member_price')
          ) {
            if (
              product.lowest_member_price &&
              product.lowest_member_price.cents > 0 &&
              slFeatureService.hasFeature('tier_member_price')
            ) {
              return product.lowest_member_price;
            } else if (product.member_price && product.member_price.cents > 0) {
              return product.member_price;
            } else {
              return product.price_sale;
            }
          } else {
            return product.price_sale;
          }
        } else {
          // return original price
          if (item.type == 'addon_product') {
            return item.price;
          }
          return item.product.price;
        }
      } else {
        if (
          !_.isEmpty(currentFlashPriceSet) &&
          currentFlashPriceSet.price_set &&
          !_.isEmpty(currentFlashPriceSet.price_set.price_details)
        ) {
          var flashPriceDetail = _.find(
            currentFlashPriceSet.price_set.price_details,
            function (priceDetail) {
              return priceDetail.variation_key === item.variation.key;
            },
          );
          if (
            !_.isEmpty(flashPriceDetail) &&
            !_.isEmpty(flashPriceDetail.price_sale)
          ) {
            return flashPriceDetail.price_sale;
          }
        }
        // return variation price or member_price or price_sale
        if (
          item.variation.member_price &&
          item.variation.member_price.cents > 0 &&
          mainConfig.currentUser &&
          slFeatureService.hasFeature('member_price')
        ) {
          return item.variation.member_price;
        }
        if (item.variation.price_sale && item.variation.price_sale.cents > 0) {
          return item.variation.price_sale;
        }
        return item.variation.price;
      }
    };

    var getFlattenCartItems = function (cartItems) {
      var _cartItems = [];
      if (cartItems) {
        _cartItems = cartItems;
      } else if (cartData) {
        _cartItems = cartData.items;
      } else if (fastCheckoutCartData) {
        _cartItems = fastCheckoutCartData.items;
      }

      return _.compact(
        _cartItems.concat(
          _.flatten(
            _.pluck(
              cartData ? cartData.items : fastCheckoutCartData.items,
              'addon_items',
            ),
          ),
        ),
      );
    };

    var getItemById = function (itemId) {
      var items = getFlattenCartItems();
      return items.find(function (item) {
        return item._id === itemId;
      });
    };

    var createDefaultTrackItem = function (productId, variationId, type) {
      return {
        type: type,
        product_id: productId,
        variation_id: variationId,
        quantity: 0,
        price: 0,
      };
    };

    var setTrackItem = function (trackItem, item) {
      trackItem.type = item.type;
      trackItem.quantity += item.quantity || 0;
      trackItem.price += item.total ? item.total.dollars : 0;
    };

    var getTrackItem = function (productId, variationId, type) {
      var itemInfo = createDefaultTrackItem(productId, variationId, type);
      var items = getFlattenCartItems();
      items.forEach(function (item) {
        var isProduct = item.product_id === productId;
        var isVariation = !variationId || item.variation_id === variationId;
        var isType = !type || item.type === type;
        if (isProduct && isVariation && isType) {
          setTrackItem(itemInfo, item);
        }
      });
      return itemInfo;
    };

    var getTrackItems = function () {
      var itemInfos = {};
      var items = getFlattenCartItems();
      items.forEach(function (item) {
        var productId = item.product_id;
        var variationId = item.variation_id;
        var type = item.type;
        var key = [productId, variationId, type].join('-');
        var itemInfo =
          itemInfos[key] ||
          createDefaultTrackItem(productId, variationId, type);
        setTrackItem(itemInfo, item);
        itemInfos[key] = itemInfo;
      });
      return Object.values(itemInfos);
    };

    var getShareItems = function () {
      return $http({
        method: 'GET',
        url: '/api/merchants/' + this.merchantId + '/cart/share_cart_items',
      });
    };

    var getGaItemData = function (itemData) {
      var variant = itemData.variant || itemData.variation;
      var isVariant = !_.isEmpty(variant);
      var gaItemData = {
        currency: mainConfig.merchantData.base_currency_code,
        product: itemData.product,
        sku: isVariant ? variant.sku : itemData.product.sku,
        variant: variant,
      };

      if (itemData.quantity) {
        gaItemData.quantity = itemData.quantity;
      }

      if (['addon', 'addon_product'].includes(itemData.product.type)) {
        gaItemData.price = itemData.price;
      } else {
        gaItemData.price = getItemPrice({
          product: itemData.product,
          variation: variant,
        }).dollars;

        if (itemData.addon_items && itemData.addon_items.length) {
          gaItemData.addon_items = itemData.addon_items.map(function (item) {
            return getGaItemData(item);
          });
        }
      }

      return gaItemData;
    };

    function handleOverLimit(promise) {
      return promise.catch((res) => {
        if (res.data.error_message === 'Exceeded cart limitation') {
          $rootScope.$broadcast('cart.overLimit');
        }

        throw { type: 'overLimit' };
      });
    }

    return {
      ERROR_CODE,
      isCartEmpty: function () {
        // if (cartData == null) return null;
        // if (angular.isUndefined(cartData)) return null;
        // if (!("items" in cartData)) return null;
        // return (Object.keys(cartData.items).length>0) ? false : true;
        return this.getItemCount() > 0 ? false : true;
      },
      getItemCount: getItemCount,
      getItems: function () {
        if (cartData == null) return 0;

        var rejectTypes = ['custom_discount'];
        if (
          !slFeatureService.hasFeature('product_set') &&
          !slFeatureService.hasFeature('product_set_revamp')
        ) {
          rejectTypes.push('product_set');
        }

        return _.isArray(cartData.items)
          ? _.reject(cartData.items, function (item) {
              return rejectTypes.includes(item.type);
            })
          : cartData.items;
      },
      getItemById: getItemById,
      getTrackItem: getTrackItem,
      getTrackItems: getTrackItems,
      tracking: cartTracking,
      sendSlPixelTracking: sendSlPixelTracking,
      getFlattenCartItems: getFlattenCartItems,
      getShareItems: getShareItems,
      getItemPrice: getItemPrice,
      getGaItemData: getGaItemData,
      getItemSalePrice: function (item) {
        if (
          (item.variation == undefined || item.product.same_price) &&
          item.product.price_sale &&
          !angular.isUndefined(item.product.price_sale) &&
          !angular.equals({}, item.product.price_sale) &&
          item.product.price_sale.cents > 0
        ) {
          return item.product.price_sale;
        }
        return null;
      },
      getItemOriginalPrice: function (item) {
        if (item.variation == undefined || item.product.same_price) {
          return item.product.price;
        } else {
          return item.variation.price;
        }
      },
      getOrderData: function () {
        return orderData;
      },
      getRequestParams: function () {
        return { page_id: this.pageId };
      },
      fetchItems: function (callback, cartOptions = {}) {
        return $http({
          method: 'GET',
          url: '/api/merchants/' + this.merchantId + '/cart',
          params: this.getRequestParams(),
          headers: {
            cacheKey: cartOptions['cache_key'] || window.location.pathname,
          },
        }).then(function (res) {
          var data = res.data;
          cartData = data.data;
          $rootScope.$broadcast('cartService.fetch', cartData);
          if (callback) {
            callback(data.data);
          }
        });
      },
      updateCartItems: function ({ isFastCheckout, data, headers }) {
        return handleOverLimit(
          $http({
            method: 'POST',
            url: isFastCheckout
              ? '/api/merchants/' + this.merchantId + '/cart/fast_checkout_cart'
              : '/api/merchants/' + this.merchantId + '/cart/items',
            data,
            headers,
          }),
        );
      },
      addItem: function (
        product_id,
        data,
        trackOptions,
        cartOptions = { skip_calculate_order: true },
      ) {
        var quantity = data.quantity;
        var type = data.type || 'product';
        var variation_id = data.variation ? data.variation.key : null;
        var blacklisted_payment_option_ids =
          data.blacklisted_payment_option_ids;
        var blacklisted_delivery_option_ids =
          data.blacklisted_delivery_option_ids;
        var isFastCheckout = data && data.isFastCheckoutCart;
        var params = _.extend(
          {
            item: {
              product_id: product_id,
              quantity: quantity,
              type: type,
              variation_id: variation_id,
              blacklisted_payment_option_ids: blacklisted_payment_option_ids,
              blacklisted_delivery_option_ids: blacklisted_delivery_option_ids,
              triggering_item_id: data.triggering_item_id
                ? data.triggering_item_id
                : null,
            },
            fbc: $cookies.get('_fbc'),
            fbp: $cookies.get('_fbp'),
            cart_options: cartOptions,
          },
          this.getRequestParams(),
        );

        if (
          type === 'product_set' &&
          slFeatureService.hasFeature('product_set')
        ) {
          params.item.item_data = {
            selected_child_products: data.productSetData,
          };
        }

        if (
          type === 'product_set' &&
          slFeatureService.hasFeature('product_set_revamp')
        ) {
          params.item.item_data = {
            selected_child_products: data.productSetData,
          };
        }

        if (isFastCheckout) {
          params.product_id = product_id;
        }

        return this.updateCartItems({
          isFastCheckout: data && data.isFastCheckoutCart,
          data: params,
          headers: {
            cacheKey: cartOptions['cache_key'] || window.location.pathname,
          },
        }).then(function (res) {
          var data = res.data;
          var responseCartData = data.data;
          if (!isFastCheckout) {
            cartData = data.data;
          } else {
            fastCheckoutCartData = data.data;
          }
          $rootScope.$broadcast('cartService.update', responseCartData);
          $rootScope.$broadcast('cartItemsUpdated', responseCartData);

          var item = $filter('filter')(responseCartData.items, {
            product_id: product_id,
            variation_id: variation_id ? variation_id : '',
          })[0];

          if (
            type === 'product_set' &&
            slFeatureService.hasFeature('product_set_revamp')
          ) {
            item = responseCartData.items.find((item) => {
              return item.item_data?.selected_child_products?.every(
                (childProduct, index) => {
                  return _.matches({
                    child_product_id:
                      params.item.item_data?.selected_child_products[index]
                        .child_product_id,
                    child_variation_id:
                      params.item.item_data?.selected_child_products[index]
                        .child_variation_id,
                    quantity:
                      params.item.item_data?.selected_child_products[index]
                        .quantity,
                  })(childProduct);
                },
              );
            });
            data.data.itemAfterAdded = item;
          }

          // Avoiding addItem logic here Owing to clicking plus btn or minus btn in 「Express Checkout Pages」 are called the logic
          if (!$('body').is('.express-checkout-page')) {
            cartTracking(
              'AddItem',
              getTrackItem(product_id, variation_id, type),
              trackOptions,
            );
            sendSlPixelTracking('addToCart', item, quantity);
          } else {
            //「Express Checkout Pages」use cartService's 'addItem' action to update item.
            hiidoTrackerService.adaptCartEvents(
              'UpdateItem',
              getTrackItem(product_id, variation_id, type),
              { pageType: 'expressCheckout' },
            );
          }

          //  Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
          if (Analytics.configuration.enhancedEcommerce) {
            try {
              gaService.setUserId();

              Analytics.addProduct(
                productService.getSku(
                  product_id,
                  item.product.sku,
                  item.variation,
                ),
                $filter('translateModel')(item.product.title_translations),
                '',
                '',
                productService.getVariationName(item.variation),
                getItemPrice(item)?.dollars?.toString(),
                quantity.toString(),
                '',
                '0',
              );
              Analytics.trackCart('add');
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }

          return data.data;
        });
      },
      addItems: function (
        mainProductId,
        items,
        trackOptions,
        cartOptions = { skip_calculate_order: true },
      ) {
        var itemsParams = _.map(items, function (data) {
          var item = {
            product_id: data.product_id,
            quantity: data.quantity,
            variation_id: _.isObject(data.variation_id)
              ? data.variation_id.key
              : '',
            blacklisted_payment_option_ids:
              data.blacklisted_payment_option_ids || [],
            blacklisted_delivery_option_ids:
              data.blacklisted_payment_option_ids || [],
            type: data.type,
          };

          if (
            data.type === 'product_set' &&
            (slFeatureService.hasFeature('product_set') ||
              slFeatureService.hasFeature('product_set_revamp'))
          ) {
            item.item_data = {
              selected_child_products: data.productSetData,
            };
            item.type = 'product_set';
          }

          return item;
        });

        // currently should calculate promotion when add addon-item
        const addonProductExist = itemsParams.some(
          (item) =>
            item.product_id !== mainProductId && item.type !== 'product_set',
        );
        if (addonProductExist) {
          cartOptions.skip_calculate_order = false;
        }
        var params = _.extend(
          {
            main_product_id: mainProductId,
            items: itemsParams,
            fbc: $cookies.get('_fbc'),
            fbp: $cookies.get('_fbp'),
            cart_options: cartOptions,
          },
          this.getRequestParams(),
        );

        if (items && items.isFastCheckoutCart) {
          params.product_id = mainProductId;
        }

        return this.updateCartItems({
          isFastCheckout: items && items.isFastCheckoutCart,
          data: params,
          headers: {
            cacheKey: cartOptions['cache_key'] || window.location.pathname,
          },
        }).then(function (response) {
          var responseCartData = response.data.data;
          if (items && items.isFastCheckoutCart) {
            fastCheckoutCartData = response.data.data;
          } else {
            cartData = response.data.data;
          }
          $rootScope.$broadcast('cartService.update', responseCartData);
          $rootScope.$broadcast('cartItemsUpdated', responseCartData);

          itemsParams.forEach(function (item) {
            var productId = item.product_id;
            var type =
              item.type ||
              (productId === mainProductId ? 'product' : 'addon_product');
            var trackItem = getTrackItem(productId, item.variation_id, type);
            cartTracking('AddItem', trackItem, trackOptions);
          });

          var slPixelItemParams = generateSlPixelParams(
            items,
            responseCartData.items,
            mainProductId,
          );

          slPixelItemParams.forEach(function (slPixelParams) {
            sendSlPixelTracking('addToCart', slPixelParams);
          });
          return responseCartData;
        });
      },
      removeItem: function (
        item_id,
        callback,
        trackOptions,
        cartOptions = { skip_calculate_order: true },
      ) {
        var flattenCartItems = getFlattenCartItems();
        var affectedItem = _.findWhere(flattenCartItems, { _id: item_id });
        var isDuplicatedItem =
          _.groupBy(
            _.map(flattenCartItems, function (item) {
              return item.product_id + '-' + item.variation_id;
            }),
          )[affectedItem.product_id + '-' + affectedItem.variation_id].length >
          1;

        sendSlPixelTracking('removeFromCart', affectedItem);
        if (Array.isArray(affectedItem.addon_items)) {
          affectedItem.addon_items.forEach(function (addonItem) {
            return sendSlPixelTracking('removeFromCart', addonItem);
          });
        }

        var products = [];
        var addonProducts = [];

        cartData.items.forEach(function (item) {
          var itemType = item.type;
          if (
            ['product', 'product_set', 'subscription_product'].includes(
              itemType,
            )
          ) {
            products.push(item);
          } else if (itemType === 'addon_product') {
            addonProducts.push(item);
          }
        });

        return $http({
          method: 'DELETE',
          url:
            '/api/merchants/' +
            this.merchantId +
            '/cart/items?item_id=' +
            item_id,
          data: { cart_options: cartOptions },
          headers: {
            'Content-type': 'application/json;charset=utf-8',
            cacheKey: cartOptions['cache_key'] || window.location.pathname,
          },
        }).then(function (res) {
          var data = res.data;
          if (!data.result) return;
          //  Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
          if (slFeatureService.hasFeature('data_layer_info') && affectedItem) {
            $rootScope.$emit(
              'remove.item.from.cart',
              getGaItemData(affectedItem),
            );
          }
          if (Analytics.configuration.enhancedEcommerce && affectedItem) {
            try {
              gaService.setUserId();

              Analytics.addProduct(
                productService.getSku(
                  affectedItem.product_id,
                  affectedItem.product.sku,
                  affectedItem.variation,
                ),
                $filter('translateModel')(
                  affectedItem.product.title_translations,
                ),
                '',
                '',
                productService.getVariationName(affectedItem.variation),
                getItemPrice(affectedItem)?.dollars?.toString(),
                affectedItem.quantity.toString(),
                '',
                '0',
              );
              Analytics.trackCart('remove');
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          cartData = data.data;
          if (callback) {
            callback(data.data, item_id);
          }

          var trackItem = getTrackItem(
            affectedItem.product_id,
            affectedItem.variation_id,
            affectedItem.type,
          );
          if (isDuplicatedItem) {
            /**
             * BundlePricing Case:
             *  There are two items which have same productId and variationId, remove the item that out of bundlePricing,
             *  we need to add the action of update tracking for remaining bundlePricing item when normal item been deleted
             */
            cartTracking('UpdateItem', trackItem, trackOptions);
          } else {
            cartTracking('RemoveItem', trackItem, trackOptions);
            (affectedItem.addon_items || []).forEach(function (item) {
              cartTracking(
                'RemoveItem',
                getTrackItem(item.product_id, item.variation_id, item.type),
                trackOptions,
              );
            });

            // Remove all cart addon items (except from product) if last mainProductItem has been removed
            if (products.length === 1 && trackItem.type !== 'addon_product') {
              addonProducts.forEach(function (item) {
                cartTracking(
                  'RemoveItem',
                  getTrackItem(item.product_id, item.variation_id, item.type),
                  trackOptions,
                );
              });
            }
          }

          $rootScope.$broadcast('cartService.update', cartData);
          $rootScope.$broadcast('cartItemsUpdated', cartData);
          $rootScope.$broadcast('checkout.cart.item.removed', cartData);
        });
      },
      updateItem: function (
        item_id,
        variationId,
        quantity,
        callback,
        trackOptions,
        cartOptions = { skip_calculate_order: true },
      ) {
        $http({
          method: 'PUT',
          url: '/api/merchants/' + this.merchantId + '/cart/items/' + item_id,
          data: {
            item: { quantity: quantity },
            cart_options: cartOptions,
          },
          headers: {
            cacheKey: cartOptions['cache_key'] || window.location.pathname,
          },
        })
          .then(function (res) {
            var data = res.data;
            cartData = data.data;
            if (callback) {
              callback(data.data);
            }

            $rootScope.$broadcast('cartItemsUpdated', cartData);

            cartTracking(
              'UpdateItem',
              getTrackItem(getItemById(item_id).product_id, variationId),
              trackOptions,
            );
          })
          .catch(function (res) {
            $rootScope.$broadcast('checkout.cart.nothing.changed', res.data);
          });
      },
      centsToDollars: function (cents) {
        var subunit_to_unit =
          mainConfig.merchantData.base_currency.subunit_to_unit;
        if (subunit_to_unit == undefined) {
          subunit_to_unit = 100;
        }
        var dollars = parseFloat(cents) / subunit_to_unit;

        try {
          if (merchantService.getCurrency().subunit_to_unit == 1) {
            //console.log("subunit = 1");
            dollars = this.toFixed(dollars, 0);
          } else {
            dollars = this.toFixed(dollars, 2);
            //console.log("subunit = 100 dollars:"+dollars);
          }
        } catch (e) {
          //        console.log("e:"+e);
        }
        //console.log("dollars:"+dollars);
        return dollars;
      },
      toFixed: function (number, precision) {
        var multiplier = Math.pow(10, precision + 1),
          wholeNumber = Math.floor(number * multiplier);
        return (Math.round(wholeNumber / 10) * 10) / multiplier;
      },
      updateCart: function (order) {
        var cart = {};
        if (!order.delivery_option || !order.payment_method) return;

        if (order.delivery_option) {
          cart.delivery_option_id = order.delivery_option._id;
        }
        if (order.payment_method) {
          cart.payment_id = order.payment_method._id;
        }
        orderData = order;
        var coupon_codes = [];
        angular.forEach(
          orderData.coupons,
          function (coupon) {
            this.push(coupon.coupon_item.coupon_code);
          },
          coupon_codes,
        );

        $http({
          method: 'PUT',
          url: '/api/merchants/' + this.merchantId + '/cart',
          data: {
            cart: {
              delivery_option_id: order.delivery_option._id,
              payment_id: order.payment_method._id,
            },
            coupon_codes: coupon_codes,
          },
        })
          .then(function (res) {
            cartData = res.data.data;
            $rootScope.$broadcast('cartService.update', cartData);
          })
          .catch(function (res) {
            $rootScope.$broadcast('cartService.error', res.data.data.error);
          });
      },
      destroyCart: function () {
        return $http({
          method: 'DELETE',
          url: '/api/merchants/' + this.merchantId + '/cart',
          params: this.getRequestParams(),
        })
          .then(function () {
            $rootScope.$broadcast('cartService.update');
          })
          .catch(function (res) {
            $rootScope.$broadcast('cartService.error', res.data.data.error);
          });
      },

      getDeliveryFee: function () {
        if (orderData == null) return {};
        if (orderData == undefined) return {};

        if (!cartData) {
          return {};
        }

        if (cartData.delivery_fee.label) {
          return cartData.delivery_fee;
        }
        // var delivery_fee_cents = orderData.delivery_option.fee.cents;
        // var delivery_fee = this.centsToDollars(delivery_fee_cents);
        var currency = merchantService.getCurrency();
        // if (currency == undefined ) return {};
        // // var coupon = this.getCoupon();

        // if (!angular.isUndefined(orderData.coupons)) {
        //   if (orderData.coupons.length>0) {
        //     for (var i = 0; i < orderData.coupons.length; i++) {
        //       var coupon =  orderData.coupons[i];
        //       if (coupon && coupon.type=="free_shipping"){
        //         if (coupon.whitelisted_delivery_option_ids && (coupon.whitelisted_delivery_option_ids.length == 0 || coupon.whitelisted_delivery_option_ids.indexOf(orderData.delivery_option._id)) !== -1){
        //           return {
        //             label: currency.alternate_symbol + 0,
        //             cents: 0
        //           };
        //         }
        //       }
        //     }
        //   }
        // }

        return {
          label: currency.alternate_symbol + 0,
          cents: 0,
        };
      },
      // canCouponBeApplied: function(coupon) {
      //   var subtotal = this.getSubtotal();
      //   if (angular.isUndefined(subtotal)) {return false};
      //   if (angular.isUndefined(subtotal.cents)) {return false};
      //   if (angular.isUndefined(coupon)) {return false};

      //   if (coupon.min_amount && coupon.min_amount.dollars && subtotal.cents < coupon.min_amount.cents) {
      //     return false;
      //   }
      //   if (coupon.status != "active") {
      //     return false;
      //   }
      //   //$log.log("coupon CAN be applied subtotal cents:"+subtotal.cents+ "coupon mincents:"+coupon.min_amount.cents)
      //   return true
      // },

      updateCoupons: function (coupons, callback) {
        // TODO update checkout option first
        var codes = [];
        angular.forEach(
          coupons,
          function (coupon) {
            // this.push(key);
            this.push(coupon.coupon_item.coupon_code);
          },
          codes,
        );

        $http({
          // method: 'POST',
          // url: '/api/theme/v1/merchants/'+this.merchantId+'/validate_coupon',
          // data: {coupon: coupon}
          method: 'POST',
          url: '/api/merchants/' + this.merchantId + '/cart/apply_coupon_codes',
          data: {
            coupon_codes: codes,
            // delivery_option_id: orderData.delivery_option._id,
            // payment_id: orderData.payment_method._id
          },
        })
          .then(function (res) {
            var data = res.data;
            if (data.error) {
              if (callback) {
                callback(data.data ? data.data.coupons : undefined, data.error);
              }
            } else {
              cartData = data.data; // update discount value from cartData
              // var couponJSON = cartData.coupons;
              // if (couponJSON == undefined){
              //   couponJSON = {};
              // }
              // var coupons = [];
              // // sort coupon accoding to code just in case they return in random order
              // for (var i = 0; i < codes.length; i++) {
              //   code = codes[i];
              //   if (couponJSON[code]) {
              //     json = couponJSON[code]
              //     // if (json.codes[0]) {
              //       json.code = code;  // code that has being used
              //     // }
              //     coupons.push(json);
              //   }
              // }
              if (callback) {
                callback(cartData.coupons);
              }
            }
          })
          .catch(function (res) {
            var data = res.data;
            // var errorMsg = data.error;
            if (callback) {
              callback(data.coupons, data.error);
            }
          });
      },
      getPaymentFee: function () {
        //     return {};
        if (orderData == null) return {};
        if (orderData == undefined) return {};
        // var delivery_fee = this.getDeliveryFee();
        // total_cents = getSubtotal().cents + delivery_fee.cents ;

        // var currency = merchantService.getCurrency();
        // if (currency == undefined ) return {};

        // var order_discount = this.getOrderDiscount();

        // if (order_discount.cents) {
        //   total_cents = total_cents-order_discount.cents;
        // }

        // var total = this.centsToDollars(total_cents);

        // var fee_multiplier = orderData.payment_method.fee_multiplier;
        // if (fee_multiplier == undefined) return {};
        // var payment_fee_cents = total_cents *fee_multiplier;
        // var payment_fee = this.centsToDollars(Math.ceil(payment_fee_cents));
        // return {
        //       label: currency.alternate_symbol + payment_fee,
        //       cents: payment_fee_cents
        //   };
        if (!cartData || !cartData.payment_fee) return {};
        return cartData.payment_fee;
      },
      // getCoupon: function () {
      //   if ( orderData.coupons && orderData.coupons.length>0){
      //     return orderData.coupons[0];
      //   }
      //   return null;
      // },
      getOrderDiscount: function () {
        //     if (orderData == null) return {};
        //     if (orderData == undefined) return {};
        //     var currency = merchantService.getCurrency();
        //     var order_discount = 0;
        // var cents = 0;
        //     if (!angular.isUndefined(orderData.coupons)) {
        //       if (orderData.coupons.length>0) {
        //         for (var i = 0; i < orderData.coupons.length; i++) {
        //           coupon = orderData.coupons[i];

        //           if (coupon.min_amount && coupon.min_amount.dollars && cartData.subtotal.cents < coupon.min_amount.cents ) {
        //             // return{};
        //             continue;
        //           }
        //           if (coupon.type =="free_shipping"){
        //             // return{};
        //             continue;
        //           }
        //           //console.log("orderData.coupons[0]:"+JSON.stringify(orderData.coupons[0]));
        //           var couponValue  =parseFloat(coupon.coupon_value);
        //           var order_discount_cents = 0;
        //           if (coupon.type == "order_discount_percent") {
        //             order_discount_cents = couponValue * cartData.subtotal.cents;
        //           }else if (coupon.type == "order_discount_amount"){
        //             order_discount_cents = Math.min(couponValue, cartData.subtotal.cents); // couponValue should always be cents in db
        //           }

        // 			cents += order_discount_cents;
        //           order_discount += this.centsToDollars(order_discount_cents);

        //         }
        //       }
        //     }
        //     var displayDiscount = currency.alternate_symbol+order_discount;
        //     if (angular.isUndefined(displayDiscount)) {
        //       return {}
        //     }

        //     return {
        //       label: displayDiscount,
        //       cents: cents
        //     }
        if (!cartData || angular.equals({}, cartData)) {
          return {};
        }
        return cartData.discount;
      },
      getSubtotal: getSubtotal,
      getFastCheckoutSubtotal: function () {
        return fastCheckoutCartData ? fastCheckoutCartData.subtotal : {};
      },
      getAppliedCredits: function () {
        if (!orderData) return {};
        if (!cartData || angular.equals({}, cartData)) {
          return {};
        }
        return cartData.applied_user_credits;
      },
      getUserCreditBalance: function () {
        if (!orderData) return {};
        if (!cartData || angular.equals({}, cartData)) {
          return {};
        }
        return cartData.user_credit_balance;
      },
      getTotal: function () {
        if (!orderData) return {};
        if (!cartData || angular.equals({}, cartData)) {
          return {};
        }
        return cartData.total;
      },
      requestCheckout: function (
        orderParams,
        saveFields,
        benchatFields,
        recaptchable,
        callback,
      ) {
        recaptchable = _.isUndefined(recaptchable) ? true : recaptchable;
        orderParams = angular.extend({}, orderParams);
        var timeZoneOffset = new Date().getTimezoneOffset() / -60;
        if (orderParams.delivery_option.requires_customer_address === false) {
          //  delivery address should contain country if not required
          var deliveryAddress = angular.extend(orderParams.delivery_address, {
            country: orderParams.delivery_address.country,
          });
          orderParams.delivery_address = deliveryAddress;
        }
        $http({
          method: 'POST',
          url: '/api/orders/checkout',
          data: _.extend(
            {
              order: orderParams,
              saveFields: angular.extend(saveFields, {
                time_zone_offset: timeZoneOffset,
              }),
              benchatFields: benchatFields,
              recaptchable: recaptchable,
              is_fast_checkout: false,
            },
            this.getRequestParams(),
          ),
        })
          .then(function (res) {
            var data = res.data;
            if (callback) {
              var order = data.order;
              delete data.order;
              callback(order, null, data);
            }
          })
          .catch(function (res) {
            if (res.status == 504 && callback) {
              // 504 does not contain response
              // injecting error data from client side instead
              return callback(null, null, { message: 'timeout' });
            }

            if (callback) {
              // if (data.message != undefined && data.message.length >0) {
              callback(null, res.data.message, res.data.data);
              // }
            }
          });
      },
      checkStockResult: function (
        addItemQuantity,
        qtyData,
        isFastCheckoutCart,
      ) {
        // handle string
        addItemQuantity = Number(addItemQuantity);

        if (isFastCheckoutCart) {
          qtyData.cart_quantity = 0;
          qtyData.total_variations_cart_qty = 0;
        }

        var result = {};
        var numberOfStock;
        result.reachedPurchaseLimit =
          addItemQuantity + qtyData.total_variations_cart_qty >
            qtyData.max_order_quantity && qtyData.max_order_quantity != -1;
        result.quantityOfStock = undefined;

        // not Enough Stock
        if (
          addItemQuantity > qtyData.left_items_quantity &&
          !(qtyData.unlimited_quantity || qtyData.out_of_stock_orderable)
        ) {
          if (qtyData.left_items_quantity > 0) {
            result.notEnoughStockQty = qtyData.left_items_quantity;
          } else {
            result.notEnoughStockQty = 0;
          }
        } else {
          result.notEnoughStockQty = -1;
        }

        if (
          mainConfig.merchantData.enabled_stock_reminder &&
          !(qtyData.unlimited_quantity || qtyData.out_of_stock_orderable)
        ) {
          // Checking Customer is FIRST TIME to add items to cart or not
          qtyData.cart_quantity = qtyData.cart_quantity || 0;
          // Calculate number of product inventory
          if (result.notEnoughStockQty >= 0 || result.reachedPurchaseLimit) {
            numberOfStock = qtyData.quantity - qtyData.cart_quantity;
          } else {
            numberOfStock =
              qtyData.quantity - qtyData.cart_quantity - addItemQuantity;
          }
          // Show number of item that Customer can add to cart
          if (numberOfStock >= 0) {
            if (numberOfStock < 11) {
              // Show quantity of inventory if less than 11
              result.quantityOfStock = numberOfStock;
            } else {
              result.quantityOfStock = undefined;
            }
          } else {
            result.quantityOfStock = undefined;
          }
        }
        return result;
      },
      validate: function () {
        // http://www.metaltoad.com/blog/angularjs-promises-from-service-to-template
        var promise = $http.get(
          '/api/merchants/' + this.merchantId + '/cart/validate',
        );
        var deferObject = $q.defer();

        promise.then(
          // OnSuccess function
          function (res) {
            // This code will only run if we have a successful promise.
            deferObject.resolve(res.data);
          },
          // OnFailure function
          function (res) {
            // This code will only run if we have a failed promise.
            deferObject.reject({
              message: res.data.message,
              items: res.data.data,
            });
          },
        );
        return deferObject.promise;
      },
      getPromotions: function () {
        if (cartData == null) return [];
        return cartData.promotions;
      },
      isAllRedeemGift: function () {
        if (!_.isEmpty(cartData) && cartData.items) {
          return _.every(cartData.items, function (item) {
            return item.type === 'redeem_gift';
          });
        }
      },
      getFastCheckoutCartItems: function () {
        return fastCheckoutCartData == null ? [] : fastCheckoutCartData.items;
      },

      addOrderItemsToCart: function (orderId, cartOptions = {}) {
        return handleOverLimit(
          $http({
            method: 'POST',
            url: `/api/merchants/${this.merchantId}/cart/add_order_items_to_cart`,
            params: { order_id: orderId },
            headers: {
              cacheKey: cartOptions['cache_key'] || window.location.pathname,
            },
          }).then(function (res) {
            const cartData = res.data.data;
            $rootScope.$broadcast('cartService.update', cartData);
            $rootScope.$broadcast('cartItemsUpdated', cartData);
          }),
        ).catch(function (res) {
          $rootScope.$broadcast('cartService.error', res.data.data.error);
        });
      },
    };
  },
]);

app.service('browserService', [
  '$window',
  function ($window) {
    return {
      isInAppBrowser: function () {
        var userAgent = $window.navigator.userAgent;
        var regex = 'FBAN|FBAV|Line|Instagram|MicroMessenger';
        return userAgent.match(regex);
      },

      isSafariMobileBrowser: function () {
        var userAgent = $window.navigator.userAgent;
        return userAgent.match(/Safari/) && userAgent.match(/iPad|iPhone/);
      },
    };
  },
]);

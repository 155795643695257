app.controller('RedeemGiftCartController', [
  '$rootScope',
  '$scope',
  '$attrs',
  '$window',
  '$filter',
  '$timeout',
  '$cookies',
  '$q',
  'mainConfig',
  'Analytics',
  'gaService',
  'trackerService',
  'cartService',
  'productService',
  'imageService',
  'redeemGiftIds',
  'fbService',
  'fastCheckoutService',
  function (
    $rootScope,
    $scope,
    $attrs,
    $window,
    $filter,
    $timeout,
    $cookies,
    $q,
    mainConfig,
    Analytics,
    gaService,
    trackerService,
    cartService,
    productService,
    imageService,
    redeemGiftIds,
    fbService,
    fastCheckoutService,
  ) {
    /* Variables */
    var registeredEvents = [];
    var scrollbarInstance;
    $scope.state = {
      isPanelActived: false,
      isPanelExpanded: false,
      isCheckoutButtonLoading: false,
    };
    $scope.redeemGiftCartItems = [];
    $scope.memberPointBalance = parseInt($attrs.memberPointBalance || 0, 10);

    /* Functions */
    $scope.togglePanel = function () {
      $scope.state.isPanelExpanded = !$scope.state.isPanelExpanded;
      $scope.state.isPanelExpanded
        ? $cookies.put('isRedeemGiftCartPanelExpanded')
        : $cookies.remove('isRedeemGiftCartPanelExpanded');
      if ($scope.state.isPanelExpanded) fbService.hideFacebookCustomerChat();
      else fbService.showFacebookCustomerChat();
    };

    $scope.getImgSrc = function (item, size) {
      return imageService.getMediaImageUrl(item.product.cover_media, {
        size: size,
      });
    };

    $scope.removeItemFromCart = function ($event, cartItemId) {
      $event.stopPropagation();
      // @TODO: track event
      cartService.removeItem(
        cartItemId,
        null,
        {},
        { skip_calculate_order: false },
      );
    };

    $scope.updateQuantity = function (cartItem, newValue, oldValue) {
      if (newValue.length === 0) {
        return;
      }

      var isUpdatedByButtonClick = typeof newValue === 'number';
      var newQuantity = parseInt(newValue, 10);
      var originQuantity = parseInt(oldValue, 10) || cartItem.originQuantity;
      var cartOptions = { skip_calculate_lock_inventory: true };

      if (newQuantity < 1) {
        return cartService.removeItem(cartItem._id, null, null, cartOptions);
      }

      if (
        newQuantity > originQuantity &&
        $scope.remainingMemberPoint < cartItem.unit_point
      ) {
        cartItem.notEnoughMemberPoint = true;
        return $timeout(function () {
          cartItem.notEnoughMemberPoint = false;
        }, 3000);
      }

      productService.checkStock(cartItem.product_id).then(function (res) {
        var stock = res.data;
        var quantityDiff = isUpdatedByButtonClick
          ? newQuantity - cartItem.quantity
          : newQuantity - originQuantity;
        var result = cartService.checkStockResult(quantityDiff, stock);

        if (result.notEnoughStockQty >= 0) {
          cartItem.quantity = cartItem.originQuantity;
          cartItem.isOutOfStock = true;
          return $timeout(function () {
            cartItem.isOutOfStock = false;
          }, 3000);
        }

        cartService.updateItem(
          cartItem._id,
          null,
          newQuantity,
          null,
          null,
          cartOptions,
        );
        cartItem.originQuantity = newQuantity;

        var slPixelActionName =
          +quantityDiff > 0 ? 'addToCart' : 'removeFromCart';
        cartService.sendSlPixelTracking(
          slPixelActionName,
          cartItem,
          Math.abs(+quantityDiff),
        );
      });
    };

    $scope.onCheckoutClicked = function ($event) {
      $event.stopPropagation();
      if ($scope.remainingMemberPoint < 0) {
        return;
      }

      if (!cartService.isAllRedeemGift()) {
        if (Analytics.configuration.enhancedEcommerce) {
          var cartItems = $rootScope.currentCart.getItems();
          gaService.setUserId();
          cartItems.forEach(function (item) {
            Analytics.addProduct(
              productService.getSku(item.product_id, item.sku, item.variation),
              $filter('translateModel')(item.product.title_translations),
              '',
              '',
              productService.getVariationName(item.variation),
              cartService.getItemPrice(item).dollars.toString(),
              item.quantity,
              '',
              '0',
            );
          });
          Analytics.trackCheckout(1, 'Checkout');
          Analytics.trackEvent('UX', 'checkout', 'Checkout', undefined, true);
        }

        const subtotal = $rootScope.currentCart.getSubtotal();
        trackerService.fbInitiateCheckout(cartItems, subtotal);
        trackerService.track({
          type: trackerService.generalEventType.START_CHECKOUT,
          data: {
            items: cartItems,
            subtotal,
          },
        });
      }

      var tasks = [];
      $scope.state.isCheckoutButtonLoading = true;
      $scope.redeemGiftCartItems.forEach(function (item) {
        if (item.quantity === 0) {
          tasks.push(cartService.removeItem(item._id));
        }
      });
      $q.all(tasks).then(function () {
        if (fastCheckoutService.isEnableEc) {
          if (Analytics.configuration.enhancedEcommerce) {
            Analytics.trackCheckout(2, 'InitateCheckout');
            Analytics.trackEvent(
              'UX',
              'initate_checkout',
              'InitateCheckout',
              undefined,
              true,
            );
          }
          $window.location = '/fast_checkout';
          return;
        }
        if (isInIframe()) {
          var win = window.open(mainConfig.checkoutLandingPath, '_blank');
          win.focus();
        } else {
          $window.location = mainConfig.checkoutLandingPath;
        }
      });
    };

    function updateCart($event, cartData) {
      var originRedeemGiftCartItemsLength = $scope.redeemGiftCartItems.length;
      $timeout(function () {
        addEllipsisToTitle();
      });

      $scope.redeemGiftCartItems = getRedeemGiftCartItems(cartData.items);

      updateMemberPointInfo();

      if ($cookies.get('isRedeemGiftCartPanelExpanded')) {
        $scope.state.isPanelExpanded = true;
      }

      // toggle cart panel depends on size of promotion cart after cart fetching and updating
      if ($scope.redeemGiftCartItems.length > 0) {
        $scope.state.isPanelActived = true;
      } else {
        $scope.state.isPanelActived = false;
        $scope.state.isPanelExpanded = false;
        fbService.showFacebookCustomerChat();
      }
      if ($scope.redeemGiftCartItems.length > originRedeemGiftCartItemsLength) {
        addScrollBar();
        // scroll the cart to bottom when the item user added is not existed in cart
        var $cartScrollZone = $('.redeemGiftCart-content');
        var targetScrollTop = $cartScrollZone.find('.container').height();
        scrollbarInstance.scroll({ y: targetScrollTop }, 450);
      }
    }

    function getRedeemGiftCartItems(cartItems) {
      return cartItems
        ? cartItems
            .filter(function (item) {
              return (
                item.type === 'redeem_gift' &&
                redeemGiftIds.includes(item.product_id)
              );
            })
            .map(function (item) {
              item.originQuantity = item.quantity;
              return item;
            })
        : [];
    }

    function updateMemberPointInfo() {
      $scope.usedMemberPoint = $scope.redeemGiftCartItems.reduce(function (
        total,
        item,
      ) {
        return (total += item.total_point || 0);
      },
      0);
      $scope.remainingMemberPoint =
        $scope.memberPointBalance - $scope.usedMemberPoint;
      $rootScope.$broadcast('redeemPointUpdate', $scope.remainingMemberPoint);
    }

    var resizeEventHandler = _.throttle(function () {
      addEllipsisToTitle();
    }, 500);

    var isInIframe = function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };

    function addScrollBar() {
      if (scrollbarInstance) {
        scrollbarInstance.destroy();
        scrollbarInstance = null;
      }

      scrollbarInstance = $('.PromotionCart-content')
        .overlayScrollbars({ overflowBehavior: { x: 'hidden' } })
        .overlayScrollbars();
    }

    function addEllipsisToTitle() {
      $timeout(function () {
        $jq('.PromotionCart-panel .product-content .title').each(function () {
          $(this).dotdotdot({
            wrap: 'letter',
            ellipsis: '...',
            height: $(this).css('max-height').replace('px', ''),
          });
        });
      });
    }

    /* Events */
    angular.element($window).on('resize', resizeEventHandler);

    registeredEvents.push(
      $scope.$on('cartService.fetch', updateCart),
      $scope.$on('cartItemsUpdated', function (event, cartData) {
        $cookies.put('isRedeemGiftCartPanelExpanded');
        $scope.state.isPanelExpanded = true;
        updateCart(event, cartData);
      }),
    );

    // use timeout to delay for preventing cart cache when history go back
    $timeout(function () {
      $('.body-wrapper').css('padding-bottom', '50px');
      cartService.fetchItems();
    });

    $window.onpageshow = function (event) {
      if (event.persisted) {
        // prevent some browser(e.g. mobile safari) try to get old state from history
        $scope.state.isCheckoutButtonLoading = false;
        cartService.fetchItems();
      }
    };
  },
]);

app.service('slPixelService', [
  'mainConfig',
  '$location',
  '$translate',
  'userAgentService',
  'slFeatureService',
  function (
    mainConfig,
    $location,
    $translate,
    userAgentService,
    slFeatureService,
  ) {
    var init = function () {
      var slMerchantId = 'sl-' + mainConfig.merchantId;
      var sessionId = mainConfig.sessionId;
      var env = mainConfig.env;
      var siteType = userAgentService.isMobi() ? 'mobile' : 'desktop';
      var urlSearchParams = $location.search();

      hd('config', slMerchantId);
      hd('context', slMerchantId, {
        isStaging: env === 'production' ? 0 : 1,
        sessionID: sessionId,
        siteType: siteType,
        campaignID: urlSearchParams.campaign_id,
        adID: urlSearchParams.ad_id,
        placement: urlSearchParams.placement,
      });

      hd('config', { debug: env === 'development' });
    };

    var loadedLanguage =
      mainConfig.localeData && mainConfig.localeData.loadedLanguage
        ? mainConfig.localeData.loadedLanguage.code
        : $translate.use();
    var pageTitle = document.title;
    var memberId = mainConfig.currentUser ? mainConfig.currentUser._id : null;
    var url = $location.absUrl();
    var impressions = [];

    var commonParams = {
      shopLanguage: loadedLanguage,
      pageTitle: pageTitle,
      memberID: memberId,
      url: url,
    };

    var createHdEvent = function (eventAction) {
      return function (eventLabel, extraParam) {
        var eventValue = Object.assign({}, commonParams, extraParam);
        return hd('event', {
          eventAction: eventAction,
          eventLabel: eventLabel,
          eventValue: eventValue,
        });
      };
    };

    var noop = function () {
      return null;
    };

    if (!slFeatureService.hasFeature('sl_pixel_tracker')) {
      return {
        hdPageView: noop,
        hdTracking: noop,
        addImpression: noop,
        clearImpressions: noop,
        sendProductImpressions: noop,
        impressions: [],
      };
    }

    init();
    return {
      hdPageView: createHdEvent('viewPage'),
      hdTracking: function (eventAction, eventLabel, extraParam) {
        var hdAction = createHdEvent(eventAction);
        return hdAction(eventLabel, extraParam);
      },
      addImpression: function (product) {
        impressions.push(product);
      },
      clearImpressions: function () {
        impressions = [];
      },
      sendProductImpressions: function () {
        var products = impressions.map(function (product, index) {
          return {
            position: index,
            productId: product.id,
            name: product.title,
          };
        });
        this.hdTracking('productImpression', null, {
          products: products,
        });
      },
      get impressions() {
        return impressions;
      },
    };
  },
]);

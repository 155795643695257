import { onTabActive } from '../features/member-center';

app.controller('UsersEditController', [
  '$window',
  '$rootScope',
  '$scope',
  '$http',
  'merchantService',
  '$location',
  '$filter',
  'mainConfig',
  'featureService',
  'mobilePhoneService',
  'pnotifyService',
  '$uibModal',
  'imageServiceEndpoint',
  'customValidator',
  'benchatService',
  'smsSubscriptionService',
  'userService',
  'staticResourceHost',
  'RecaptchaService',
  'slFeatureService',
  '$element',
  function (
    $window,
    $rootScope,
    $scope,
    $http,
    merchantService,
    $location,
    $filter,
    mainConfig,
    featureService,
    mobilePhoneService,
    pnotifyService,
    $uibModal,
    imageServiceEndpoint,
    customValidator,
    benchatService,
    smsSubscriptionService,
    userService,
    staticResourceHost,
    RecaptchaService,
    slFeatureService,
    $element,
  ) {
    $scope.imageServiceEndpoint = imageServiceEndpoint;
    $scope.benchatSubscriptions = [];
    $scope.listOfLayer1Map = {};
    $scope.listOfLayer2Map = {};
    $scope.listOfLayer3Map = {};

    var hkMobileFormat = /^[4-9]{1}\d{7}$/;
    var hkFormat = /^[2-9]{1}\d{7}$/;
    var timeZoneOffset = new Date().getTimezoneOffset() / -60;

    $scope.subscription_tags = {
      'marketing.news': false,
    };

    $scope.phoneNumberRegex = /^\+?[()\-#.\s\d]+$/;
    $scope.emailRegex = customValidator.EMAIL_REGEX;

    $scope.mobileForm = {
      mobilePhone: '',
      phoneSelectShow: false,
      mobileInvalid: false,
      mobileSuccessMessages: [],
      mobileErrorMessages: [],
    };

    $scope.user_setting = {
      email_verification: {
        status: false,
      },
      login_with_verification: {
        status: false,
      },
    };

    $scope.$on('mobile-step-change', function (event, step) {
      $scope.mobileSignInStep = step;
    });

    $scope.getTiers = function () {
      $http({
        method: 'GET',
        url: '/api/membership_tiers',
      }).then(function (res) {
        $scope.tiers = res.data;
      });
    };

    $scope.loadItems = function () {
      $scope.loadingItems = true;
      //get user setting
      $http({
        method: 'GET',
        url: '/api/merchants/' + mainConfig.merchantId + '/user_setting',
      }).then(function (res) {
        $scope.user_setting = res.data;
        try {
          // avoid email_verification return empty string
          if ($scope.user_setting.email_verification) {
            $scope.user_setting.email_verification.status = JSON.parse(
              $scope.user_setting.email_verification.status,
            );
          } else {
            $scope.user_setting.email_verification = {
              status: false,
            };
          }
          $scope.minimumAgeLimit = Number(res.data.minimum_age_limit || 13);
          $scope.maxBirthdayDate = dayjs()
            .endOf('day')
            .subtract($scope.minimumAgeLimit, 'years');
          $scope.datepickerOptions = {
            showWeeks: true,
            maxDate: $scope.maxBirthdayDate,
            minDate: new Date('1899-12-31'),
            initDate: new Date($scope.maxBirthdayDate),
          };
        } catch (e) {
          $scope.user_setting.email_verification = {
            status: false,
          };
        }

        try {
          // avoid login_with_verification return empty string
          if ($scope.user_setting.login_with_verification) {
            $scope.user_setting.login_with_verification.status = JSON.parse(
              $scope.user_setting.login_with_verification.status,
            );
          } else {
            $scope.user_setting.login_with_verification = {
              status: false,
            };
          }
        } catch (e) {
          $scope.user_setting.login_with_verification = {
            status: false,
          };
        }

        // get user info
        $scope.getUserInfo();
      });
    };

    $scope.getUserInfo = function (action) {
      $http({
        method: 'GET',
        url: '/api/users/' + $rootScope.currentUser._id,
        params: { 'includes[]': 'membership_tier_gap' },
      }).then(function (res) {
        $scope.loadingItems = false;
        $scope.user = res.data.data;
        $scope.originalEmail = $scope.user.email;
        if ($scope.user.phones.length > 1) {
          $scope.user.phones = $scope.user.phones.slice(-1);
        }
        $scope.userBackup = angular.copy($scope.user);
        if ($scope.userBackup.mobile_phone != undefined) {
          $scope.userBackup.phones.push($scope.userBackup.mobile_phone);
        }
        $scope.setSubscriptionTags();
        $scope.$broadcast('memberCenterUser.fetch', $scope.user);
        $scope.isBirthday = !!$scope.user.birthday;
        if ($scope.user.birthday) {
          $scope.user.birthday = userService.convertDateWithoutTimezone(
            $scope.user.birthday,
          );
          $scope.userBackup.birthday = $scope.user.birthday;
        }

        // test pipeline feat(controllers.users.edit.js): Do not use user's phones as mobile phone
        $scope.mobileForm.mobilePhone = $scope.user.mobile_phone || '';
        $scope.mobileForm.phoneSelectShow =
          !$scope.user.is_mobile_phone_confirmed &&
          !$scope.user.mobile_phone &&
          $scope.user.phones.length > 1;
        $scope.mobileForm.countryCallingCode = $scope.user.country_calling_code;

        if (!slFeatureService.hasFeature('modularize_address_format')) {
          $scope.user.delivery_addresses.forEach(function (addr) {
            if ($scope.shouldUseOmsAddress(addr.country) && !addr.logistic_code)
              addr.state = null;
            addr.states = undefined;
            $scope.fetchStateData(addr);
          });
        }

        if (action == 'sms_code_success') {
          $scope.mobileForm.mobileSuccessMessages = [
            $filter('translate')('membership.profile.mobile.number.verified'),
          ];
        }
        angular.element('[data-toggle="popover"]').popover();
        // get benchat subscriptions info
        benchatService
          .getSubscriptions($scope.user._id)
          .then(function (result) {
            return ($scope.benchatSubscriptions = result.data.subscriptions);
          });
      });
    };

    $scope.loadItems();
    $scope.getTiers();
    $scope.setSubscriptionTags = function () {
      angular.forEach($scope.subscription_tags, function (value, key) {
        if ($scope.user.subscribed_email_types.indexOf(key) >= 0) {
          $scope.subscription_tags[key] = true;
        } else {
          $scope.subscription_tags[key] = false;
        }
      });
    };
    $scope.setUserSubscriptionTags = function () {
      angular.forEach($scope.subscription_tags, function (value, key) {
        if (value) {
          if ($scope.user.subscribed_email_types.indexOf(key) < 0) {
            $scope.user.subscribed_email_types.push(key);
          }
        } else {
          if ($scope.user.subscribed_email_types.indexOf(key) >= 0) {
            $scope.user.subscribed_email_types = $scope.user.subscribed_email_types.filter(
              function (type) {
                return type !== key;
              },
            );
          }
        }
      });
    };

    // Datepicker
    $scope.datepicker = {
      open: false,
    };
    $scope.open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datepicker.open = true;
    };

    $scope.onCountryChanged = function (index, country) {
      $scope.user.delivery_addresses[index] = {};
      $scope.user.delivery_addresses[index]['country'] = country;
    };
    $scope.successMessages = [];
    $scope.errorMessages = [];
    $scope.sendVerificationEmailreachedRateLimit = false;

    $http({
      method: 'GET',
      url: '/api/merchants/' + merchantService.merchantId + '/countries',
    }).then(function (res) {
      $scope.countries = res.data;
    });

    $scope.onDismissMessages = function (type, index) {
      if (type == 'error') {
        $scope.errorMessages.splice(index, 1);
      } else if (type == 'success') {
        $scope.successMessages.splice(index, 1);
      }
    };

    $scope.onDismissMobileMessages = function (type, index) {
      if (type == 'error') {
        $scope.mobileForm.mobileErrorMessages.splice(index, 1);
      } else if (type == 'success') {
        $scope.mobileForm.mobileSuccessMessages.splice(index, 1);
      }
    };

    $scope.onCancelEdit = function () {
      $scope.userForm.submitting = true;
      $scope.user = angular.copy($scope.userBackup);
      $scope.setSubscriptionTags();
      $scope.userForm.submitting = false;
    };
    $scope.onPhoneAdd = function () {
      $scope.user.phones.push('');
    };
    $scope.onPhoneDelete = function (index) {
      $scope.user.phones.splice(index, 1);
    };
    $scope.onDeliveryAddressAdd = function () {
      $scope.user.delivery_addresses.push({
        country: mainConfig.requestCountry,
      });
      $scope.user.delivery_addresses.forEach(function (addr) {
        if (addr.country === mainConfig.requestCountry) {
          $scope.fetchStateData(addr);
        }
      });
    };
    $scope.onDeliveryAddressDelete = function (index) {
      $scope.user.delivery_addresses.splice(index, 1);
    };

    $scope.isGreaterThanMaximumDate = function () {
      return (
        $scope.user.birthday > $scope.maxBirthdayDate &&
        $scope.user.birthday < new Date()
      );
    };

    $scope.isFeatureDate = function () {
      return $scope.user.birthday > new Date();
    };

    $scope.formSubmit = function () {
      if ($scope.userForm.submitting) {
        return;
      }

      $scope.userForm.submitted = true;
      $scope.userForm.submitting = true;
      $scope.userForm.errorMessage = null;

      if ($scope.userForm.$invalid || $('.format-error:visible').length !== 0) {
        $scope.userForm.submitting = false;
        return;
      }
      $scope.userForm.user_email.$setValidity('emailUnique', true);

      // If no sms subscription, removing marketing.news will not untick sms checkbox but create a active sms subscription
      var subscriptions = $scope.benchatSubscriptions;
      var userRef = benchatService.generateUserRef($scope.user._id);
      var topics = ['MARKETING'];
      var hasMarketingNews =
        $scope.user.subscribed_email_types &&
        $scope.user.subscribed_email_types.includes('marketing.news');

      if (
        featureService.hasRolloutFeature('broadcast_unsubscribe_email') &&
        !smsSubscriptionService.hasMarketingSubscriptions(subscriptions) &&
        hasMarketingNews
      ) {
        // no sms subscription but has rollout = create sms subscription

        smsSubscriptionService.createActiveSubscriptions(
          $scope.user._id,
          mainConfig.merchantData._id,
          userRef,
          topics,
        );
      }

      if (featureService.hasRolloutFeature('broadcast_to_unsubscribers_sms')) {
        if (
          (!smsSubscriptionService.hasMarketingSubscriptions(subscriptions) &&
            hasMarketingNews) ||
          smsSubscriptionService.getActiveSubscriptions(topics, subscriptions)
            .length > 0
        ) {
          $scope.user.exclude_sms_platform = false;
        }
      }

      $scope.successMessages = [];
      $scope.errorMessages = [];
      $scope.setUserSubscriptionTags();

      $scope.user.delivery_addresses = $scope.user.delivery_addresses.map(
        function (deliveryAddress) {
          if (deliveryAddress.postcode) {
            deliveryAddress.postcode = deliveryAddress.postcode.trim();
          }
          return deliveryAddress;
        },
      );

      if (featureService.hasRolloutFeature('country_code')) {
        $scope.user.phones = $('.phone-input-wrapper input.intl-tel-input')
          .map(function () {
            return $(this).intlTelInput('getNumber').replace('+', '');
          })
          .toArray();

        $scope.user.delivery_addresses = $scope.user.delivery_addresses.map(
          function (deliveryAddress, index) {
            deliveryAddress.recipient_phone = $(
              '#delivery_address_' + index + ' input.intl-tel-input',
            )
              .intlTelInput('getNumber')
              .replace('+', '');
            return deliveryAddress;
          },
        );
      }

      if ($scope.user.email) {
        $scope.user.email = $scope.user.email.toLowerCase();
      }
      var method = 'PUT';
      var path = '/api/users/' + $scope.user._id;

      $http({
        method: method,
        url: path,
        data: {
          user: angular.extend(
            {},
            $scope.user,
            {
              birthday: $scope.user.birthday
                ? new Date($scope.user.birthday).toDateString()
                : null,
              delivery_addresses: $scope.user.delivery_addresses.map(function (
                addr,
              ) {
                return _.omit(addr, 'states');
              }),
            },
            { time_zone_offset: timeZoneOffset },
          ),
          benchatFields: renderBenchatFields(),
          recaptchable: true,
          includes: ['membership_tier_gap'],
        },
      })
        .then(function (res) {
          let mobileChanged = false;
          var data = res.data;
          $scope.userForm.submitting = false;
          if (data.result) {
            var successMessage = $filter('translate')('users.save.success');
            // check if email has changed
            if (
              $scope.user_setting.email_verification.status &&
              $scope.user.email &&
              $scope.originalEmail != $scope.user.email
            ) {
              if ($scope.user_setting.login_with_verification.status) {
                successMessage = $filter('translate')(
                  'users.save.email_verification.success',
                );
                openEmailVerificationSendedDialog();
              } else {
                pnotifyService.notify(
                  $filter('translate')('users.save.email_verification.send'),
                  {},
                );
              }
            }

            // for change mobile but not yet, we need to set is_mobile_phone_confirmed to false
            mobileChanged = $scope.mobileChanged(data.data);
            if ($scope.user.mobile_phone !== $scope.mobileForm.mobilePhone) {
              // original mobile phone not confirmed and column is empty
              if (
                $scope.mobileForm.mobilePhone === '' &&
                !data.data.is_mobile_phone_confirmed
              ) {
                $scope.mobileForm.mobilePhone = data.data.mobile_phone;
              }
              // mobile phone confirmed state has changed and column is empty
              if (
                data.data.is_mobile_phone_confirmed !==
                  $scope.user.is_mobile_phone_confirmed &&
                $scope.mobileForm.mobilePhone !== ''
              ) {
                data.data.is_mobile_phone_confirmed = false;
              }
            }

            $scope.user = data.data;
            $scope.originalEmail = $scope.user.email;
            $scope.userBackup = angular.copy($scope.user);
            if ($scope.userBackup.mobile_phone != undefined) {
              $scope.userBackup.phones.push($scope.userBackup.mobile_phone);
            }
            if ($scope.user.birthday) {
              $scope.user.birthday = userService.convertDateWithoutTimezone(
                $scope.user.birthday,
              );
              $scope.userBackup.birthday = $scope.user.birthday;
            }
            if (mobileChanged) {
              $scope.errorMessages = [
                $filter('translate')('users.error.not_save_mobile_phone'),
              ];
            } else {
              $scope.successMessages.push(successMessage);
            }
            $scope.userForm.submitted = false;
            $scope.isBirthday = !!$scope.user.birthday;
            $scope.user.delivery_addresses.forEach(function (addr) {
              $scope.fetchStateData(addr);
            });
          } else {
            if (data.data.email && data.data.email[0] === 'is already taken') {
              $scope.userForm.user_email.$setValidity('emailUnique', false);
            }
          }
        })
        .catch(function (data) {
          $scope.errorMessages = data.data.error;
          $scope.user.email = $scope.originalEmail;
          $scope.userForm.submitting = false;
        })
        .finally(function () {
          RecaptchaService.reset('profile-save-recaptcha');
        });
    };

    $scope.included = function (generalFields, customFields) {
      var needToInclude = function (fields) {
        var isIncluded = false;
        fields.map(function (field) {
          if (field.options.profile.include == 'true') isIncluded = true;
        });
        return isIncluded;
      };
      return (
        (generalFields && needToInclude(generalFields)) ||
        (customFields && needToInclude(customFields))
      );
    };

    $scope.checkColumntTypeReadable = function (column_type) {
      if (featureService.hasRolloutFeature('member_customfield'))
        return column_type === 'readable';
    };

    $scope.onCountryChange = function (addr) {
      addr.city = undefined;
      addr.address_1 = undefined;
      addr.address_2 = undefined;
      addr.state = undefined;
      addr.postcode = undefined;
      addr.region_code = undefined;
      addr.key = undefined;
      $scope.fetchStateData(addr);
    };

    $scope.getPostcodeRegex = function (addr) {
      switch (addr.country) {
        case 'MY':
        case 'TH':
          return /^\d{5}$/;
        case 'SG':
          return /^\d{6}$/;
        default:
          return /.*/;
      }
    };

    $scope.hasCustomSateTranslationCountries = [
      'JP',
      'TH',
      'DE',
      'FR',
      'ID',
      'CA',
      'US',
      'VN',
    ];
    $scope.shouldUseOmsAddress = function (country) {
      return (
        country === 'MY' && slFeatureService.hasFeature('dynamic_shipping_rate')
      );
    };

    $scope.hasModularizeAddress = function () {
      return slFeatureService.hasFeature('modularize_address_format');
    };

    $scope.fetchStateData = function (addr) {
      if ($scope.shouldShowThreeLayerAddress(addr.country))
        return getFirstLayerOptions(addr);
      if (shouldFetchStateData(addr)) {
        if (_.include($scope.hasCustomSateTranslationCountries, addr.country)) {
          fetchCustomStateTranslation(addr);
        } else {
          $http({
            method: 'GET',
            url:
              '/api/merchants/' +
              merchantService.merchantId +
              '/country_states',
            params: { country_code: addr.country.toLowerCase() },
          }).then(function (res) {
            addr.states = res.data;
          });
        }
      }
    };

    function getFirstLayerOptions(addr) {
      var country = addr.country.toLowerCase();
      var layer1JsonUrl =
        staticResourceHost + 'web/v1/translations/districts_' + country;
      $http
        .get(layer1JsonUrl + '_v2.json?t=' + Date.now(), {
          withCredentials: false,
        })
        .then(function (res) {
          $scope.listOfLayer1Map[country] = generateListOfFirstLayer(res.data);
          getRemainLayerOptions(addr);
        });
    }

    function generateListOfFirstLayer(datas) {
      return _.map(datas, function (data) {
        return {
          data: data.data,
          displayName: $filter('translateModel')(data.data),
        };
      });
    }

    function resetAddrLayer2(addr) {
      addr.layer2 = undefined;
      addr.city = undefined;
    }

    function resetAddrLayer3(addr) {
      addr.layer3 = undefined;
      addr.state = undefined;
    }

    $scope.onLayer1Change = function (addr) {
      getRemainLayerOptions(addr);
      resetAddrLayer2(addr);
      resetAddrLayer3(addr);
    };

    var fetchCustomStateTranslation = function (addr) {
      var jsonFiles = {
        JP: 'ja.json',
        TH: 'th.json',
        DE: 'de.json',
        FR: 'fr.json',
        ID: 'id.json',
        CA: 'ca.json',
        US: 'us.json',
        VN: 'vn.json',
      };
      var jsonUrl =
        staticResourceHost +
        'web/v1/translations/districts_' +
        jsonFiles[addr.country];
      $.getJSON(jsonUrl, function (data) {
        addr.states = data
          .filter(function (record) {
            return record.type === 'state';
          })
          .map(function (state) {
            return state.data;
          });
        $scope.$apply();
      });
    };

    var renderBenchatFields = function () {
      var benchatFields = {
        subscriptions: {
          line: [],
          facebook: [],
        },
      };

      if (
        document.getElementsByName('benchatFields[subscriptions][line]')
          .length > 0
      ) {
        benchatFields.subscriptions.line = document.getElementsByName(
          'benchatFields[subscriptions][line]',
        )[0].value;
      }

      if (
        document.getElementsByName('benchatFields[subscriptions][facebook]')
          .length > 0
      ) {
        benchatFields.subscriptions.facebook = document.getElementsByName(
          'benchatFields[subscriptions][facebook]',
        )[0].value;
      }

      return benchatFields;
    };

    $scope.onStateChange = function (addr) {
      var state = _.find(addr.states, function (state) {
        return state.key === addr.key;
      });

      addr.state = $filter('translateModel')(state);
    };

    $scope.handleOmsStateChange = function (addr) {
      var targetOmsState = addr.states.find(function (state) {
        return state.name === addr.state;
      });
      addr.logistic_code = targetOmsState.logistic_code;
    };

    function shouldFetchStateData(addr) {
      return (
        (['US', 'UM', 'MY', 'JP'].indexOf(addr.country) > -1 &&
          featureService.hasRolloutFeature(
            ('address_format_' + addr.country).toLowerCase(),
          )) ||
        (['TH', 'DE', 'FR', 'ID', 'CA'].indexOf(addr.country) > -1 &&
          featureService.hasRolloutFeature('address_format_batch2'))
      );
    }

    $scope.verifyPhoneFormat = function () {
      // Not fill out mobile phone
      if ($scope.mobileForm.mobilePhone === '') {
        return true;
      }
      var mobilePhoneInput = $('.mobile-phone-control .intl-tel-input');
      var internationalNumber = mobilePhoneInput
        .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
        .replace(/\D/g, '');
      var nationalNumber = mobilePhoneInput
        .intlTelInput('getNumber', intlTelInputUtils.numberFormat)
        .replace(/\D/g, '');

      // TODO: update utils.js for newest hk mobile number validation
      if ($scope.mobileForm.countryCode == 'hk') {
        return (
          hkMobileFormat.test($scope.mobileForm.mobilePhone) &&
          ($scope.mobileForm.mobilePhone === internationalNumber ||
            $scope.mobileForm.mobilePhone === nationalNumber)
        );
      }
      return (
        intlTelInputUtils.isValidNumber(
          $scope.mobileForm.mobilePhone,
          $scope.mobileForm.countryCode,
        ) &&
        ($scope.mobileForm.mobilePhone === internationalNumber ||
          $scope.mobileForm.mobilePhone === nationalNumber)
      );
    };

    $scope.onNewPhoneChange = function () {
      var mobilePhoneInput = $('.intl-tel-input');
      $scope.mobileForm.mobilePhone = mobilePhoneInput
        .intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL)
        .replace(/\D/g, '');

      $scope.mobileForm.mobileInvalid = !$scope.verifyPhoneFormat();
    };

    $scope.updateMobile = function () {
      $scope.user.is_mobile_phone_confirmed = false;
      $scope.onPhoneMethodChange('new_phone');
    };

    $scope.onPhonesSelectChange = function () {
      if ($scope.mobileForm.mobilePhone == 'new_phone') {
        $scope.onPhoneMethodChange('new_phone');
      }
      $scope.mobileForm.mobileInvalid = !$scope.verifyPhoneFormat();
    };

    $scope.onPhoneMethodChange = function (method) {
      if (method === 'mobile_phones_init') {
        $scope.mobileForm.phoneSelectShow = true;
        $scope.mobileForm.mobilePhone = $scope.userBackup.phones[0] || '';
        $scope.mobileForm.mobileInvalid = false;
      } else if (method === 'new_phone') {
        $scope.mobileForm.phoneSelectShow = false;
        $scope.mobileForm.mobilePhone = '';
        $scope.mobileForm.mobileInvalid = !$scope.verifyPhoneFormat();
      }
    };

    $scope.onEmailChange = function () {
      $scope.userForm.user_email.$setValidity('emailUnique', true);
      if ($scope.user.email === '') {
        $scope.user.email = null;
      }
    };

    $scope.onCountryCodeChange = function (countryData, isInit) {
      $scope.mobileForm.countryCallingCode = countryData.dialCode;
      $scope.mobileForm.countryCode = countryData.iso2;
      if (isInit) return false;
      $scope.mobileForm.mobileInvalid = !$scope.verifyPhoneFormat();
    };

    $scope.showValidateUserEmailResult = false;

    $scope.updateUserEmailAndSendVerificationEmail = function () {
      $http({
        method: 'PUT',
        url: '/api/users/' + $scope.user._id,
        data: {
          user: { email: $scope.user.email },
          skip_email_verification: true,
        },
      }).then(function (res) {
        var data = res.data;

        if (data.result) {
          userService
            .sendVerificationEmail($scope.user._id)
            .then(function () {
              openEmailVerificationSendedDialog();
            })
            .catch(function (error) {
              $scope.errorMessages.push(error.data.error);
              $scope.sendVerificationEmailreachedRateLimit =
                error.data.reached_rate_limit;
            });
        } else {
          if (data.data.email && data.data.email[0] === 'is already taken') {
            $scope.userForm.user_email.$setValidity('emailUnique', false);
            $scope.showValidateUserEmailResult = true;
          }
        }
      });
    };

    $scope.verifyPhoneSubmit = function () {
      $scope.mobileForm.mobileInvalid = !$scope.verifyPhoneFormat();

      if ($scope.mobileForm.mobileInvalid) {
        return;
      }

      if (
        $rootScope.currentUser.mobile_phone === $scope.mobileForm.mobilePhone &&
        $rootScope.currentUser.mobile_phone_confirmed_at
      ) {
        $scope.mobileForm.mobileErrorMessages = [
          $filter('translate')('user.sign_up.check_phone.error'),
        ];
        return;
      }

      $scope.mobileForm.mobileSubmitting = true;
      var params = {
        mobilePhone: $scope.mobileForm.mobilePhone,
        countryCallingCode: $scope.mobileForm.countryCallingCode,
      };

      mobilePhoneService.checkUserExistenceByPhone(params).then(
        function () {
          $scope.mobileForm.mobileSubmitting = false;
          $scope.mobileForm.mobileErrorMessages = [
            $filter('translate')('session.mobile_center.check_phone.error'),
          ];
        },
        function (error) {
          if (error.status === 404) {
            $scope.$broadcast('mobile-step-change', 'check_mobile');
            $scope.mobileForm.mobileErrorMessages = [];
          } else {
            $scope.mobileForm.mobileErrorMessages = [error.data.error_messages];
          }
          $scope.mobileForm.mobileSubmitting = false;
        },
      );
    };

    $scope.userPhoneChange = function (changedValue, init, element) {
      var errorTarget = element.parent().siblings('.format-error');
      var validateBool =
        element.intlTelInput('getSelectedCountryData').iso2 === 'hk'
          ? hkFormat.test(element.val().replace(/\s/g, ''))
          : intlTelInputUtils.isValidNumber(element.intlTelInput('getNumber'));

      if (!validateBool) {
        if (!element.val()) {
          errorTarget.html($filter('translate')('users.error.phone.required'));
        } else {
          errorTarget.html($filter('translate')('users.error.phone.format'));
        }

        errorTarget.show();
      } else {
        errorTarget.hide();
      }
    };

    $scope.shouldShowFomattedAdrress = function (country) {
      return (
        (featureService.hasRolloutFeature('address_format_us') &&
          ['US', 'UM'].indexOf(country) > -1) ||
        (featureService.hasRolloutFeature('address_format_my') &&
          country === 'MY') ||
        (featureService.hasRolloutFeature('address_format_jp') &&
          country === 'JP') ||
        (featureService.hasRolloutFeature('address_format_batch2') &&
          ['DE', 'FR', 'ID', 'CA'].indexOf(country) > -1)
      );
    };

    $scope.shouldShowThreeLayerAddress = function (country) {
      var countryLowercase = country.toLowerCase();
      var targetCountries = ['vn', 'th'];
      if (!targetCountries.includes(countryLowercase)) return false;
      return (
        featureService.hasRolloutFeature(
          'address_format_' + countryLowercase,
        ) || featureService.hasRolloutFeature('address_format_batch2')
      );
    };
    $scope.shouldShowThreeLayerPostcode = function (country) {
      var countryLowercase = country.toLowerCase();
      var targetCountries = ['th'];

      return (
        $scope.shouldShowThreeLayerAddress &&
        targetCountries.includes(countryLowercase)
      );
    };

    function getRemainLayerOptions(addr) {
      if (!addr.layer1 || !addr.country) return;
      var targetLayer1 = $scope.listOfLayer1Map[
        addr.country.toLowerCase()
      ].find(function (layer1) {
        return layer1.data.key === addr.layer1;
      });
      addr.address_2 = targetLayer1.displayName;

      var remainLayerJsonUrl =
        staticResourceHost +
        'web/v1/translations/' +
        addr.country.toLowerCase() +
        '_districts/' +
        targetLayer1.data.key +
        '.json?t=' +
        Date.now();
      $http
        .get(remainLayerJsonUrl, {
          withCredentials: false,
        })
        .then(function (res) {
          $scope.listOfLayer2Map[addr.layer1] = generateListOfSecondLayer(
            res.data,
          );
          if (addr.layer2) {
            saveLayer2AsCity(addr);
            $scope.listOfLayer3Map[addr.layer2] = generateListOfThirdLayer(
              addr,
            );
          }
          if (addr.layer3) {
            saveLayer3AsState(addr);
          }
        });
    }

    function saveLayer2AsCity(addr) {
      var targetLayer2 = $scope.listOfLayer2Map[addr.layer1].find(function (
        layer2,
      ) {
        return layer2.key === addr.layer2;
      });
      addr.city = targetLayer2.displayName;
    }

    function saveLayer3AsState(addr) {
      var targetLayer3 = $scope.listOfLayer3Map[addr.layer2].find(function (
        layer3,
      ) {
        return layer3.key === addr.layer3;
      });
      addr.state = targetLayer3.displayName;
    }

    function generateListOfSecondLayer(data) {
      return _.map(data.districts, function (district) {
        return {
          key: district.key,
          displayName: $filter('translateModel')(district),
          wards: district.wards,
        };
      });
    }

    $scope.onLayer2Change = function (addr) {
      resetAddrLayer3(addr);
      saveLayer2AsCity(addr);
      $scope.listOfLayer3Map[addr.layer2] = generateListOfThirdLayer(addr);
    };

    $scope.onLayer3Change = function (addr) {
      saveLayer3AsState(addr);
    };

    $scope.mobileChanged = function (data) {
      // if no feature
      if (!featureService.hasFeature('mobile_signup_p2')) {
        return false;
      }

      // no mobile phone
      if (
        $scope.user.mobile_phone === null ||
        $scope.user.mobile_phone === undefined
      ) {
        return $scope.mobileForm.mobilePhone !== '';
      } else if ($scope.user.mobile_phone !== $scope.mobileForm.mobilePhone) {
        // mobile phone be changed
        if (
          $scope.mobileForm.mobilePhone !== '' ||
          ($scope.mobileForm.mobilePhone === '' &&
            !data.is_mobile_phone_confirmed)
        ) {
          return true;
        }
      }

      return false;
    };

    function generateListOfThirdLayer(addr) {
      if (!addr || !addr.layer1 || !addr.layer2) return;
      var targetLayer2 = $scope.listOfLayer2Map[addr.layer1].find(function (
        layer2,
      ) {
        return layer2.key === addr.layer2;
      });
      return _.map(targetLayer2.wards, function (ward) {
        return {
          key: ward.key,
          displayName: $filter('translateModel')(ward),
        };
      });
    }

    function openEmailVerificationSendedDialog() {
      $uibModal.open({
        templateUrl: require('../../../../../public/themes/v1/default/views/templates.dialog.email-verification.html'),
        controller: 'UsersEditEmailVerificationDialogController',
        resolve: {
          userEmail: function () {
            return $scope.user.email;
          },
        },
      });
    }

    onTabActive($element, () => {
      history.replaceState(
        null,
        null,
        `/users/${mainConfig.currentUser._id}/edit${location.search}`,
      );
    });
  },
]);

app.directive('paymentConditionPanel', [
  '$rootScope',
  'mainConfig',
  'moneyService',
  function ($rootScope, mainConfig, moneyService) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.payment_condition_panel.html'),
      scope: {
        totalOrderOver: '=',
        totalAmount: '=',
        productsLink: '@',
      },
      link: function (scope, element) {
        scope.difference = scope.totalOrderOver - scope.totalAmount;

        scope.getMoneyLabel = function (dollars) {
          return moneyService.toMoney({
            dollars: dollars,
            symbol: mainConfig.merchantData.base_currency.alternate_symbol,
            symbol_first: mainConfig.merchantData.base_currency.symbol_first,
            currencyIso: mainConfig.merchantData.base_currency.iso_code,
          }).label;
        };

        $rootScope.$on('checkout.cart.summary.loaded', function () {
          scope.totalAmount = $('#total_amount').val();
          scope.totalAmountToMoney = moneyService.toMoney({
            cents: scope.totalAmount,
            symbol: mainConfig.merchantData.base_currency.alternate_symbol,
            symbol_first: mainConfig.merchantData.base_currency.symbol_first,
            currencyIso: mainConfig.merchantData.base_currency.iso_code,
          });
          scope.difference =
            scope.totalOrderOver - scope.totalAmountToMoney.dollars;
          scope.$emit('checkout.payment.condition.updated', {
            difference: scope.totalOrderOver - scope.totalAmountToMoney.dollars,
          });
          setHintAndButton();
        });

        function setHintAndButton() {
          if (scope.difference > 0) {
            $('.payment-condition-hint').show();
            $('#place-order-btn').attr('disabled', true);
            $('#place-order-recaptcha').attr('disabled', true);
          } else {
            $('.payment-condition-hint').hide();
            $('#place-order-btn').attr('disabled', false);
            $('#place-order-recaptcha').attr('disabled', false);
          }
        }

        $(document).ready(function () {
          var isV1Theme = $('body.v1_theme').length > 0;
          var getNavHeight = function () {
            if (isV1Theme) {
              return window.matchMedia('(min-width: 768px)').matches
                ? 0
                : $('#fixed-menu-container').height();
            }
            return $('nav:visible').height();
          };
          var onScroll = function () {
            var panelTop = $(element).offset().top;
            var navHeight = getNavHeight();
            var scrollTop = $(window).scrollTop();
            var targetPosition = panelTop - navHeight;
            if (scrollTop > targetPosition) {
              $('.payment-condition-panel')
                .css('top', navHeight)
                .addClass('fixed');
            } else {
              $('.payment-condition-panel')
                .css('top', 'unset')
                .removeClass('fixed');
            }
          };
          $(window).on(
            'scroll',
            _.throttle(function () {
              onScroll();
            }, 100),
          );
          setHintAndButton();
          onScroll();
        });
      },
    };
  },
]);

app.service('sevenElevenService', [
  function () {
    var isSevenEleven = function (region_type) {
      switch (region_type) {
        case 'tw_711_pay':
        case 'tw_711_nopay':
        case 'tw_711_b2c_pay':
        case 'tw_711_b2c_nopay':
          return true;
        default:
          return false;
      }
    };

    return {
      isSevenEleven: isSevenEleven,
    };
  },
]);

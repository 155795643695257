app.directive('facebookMessengerCheckbox', [
  'mainConfig',
  'benchatService',
  'featureService',
  function (mainConfig, benchatService, featureService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.facebook-messenger-checkbox.html'),
      scope: {
        benchatConfig: '=',
        benchatChannel: '=',
        customerId: '@',
        orderId: '@',
        sessionId: '@',
        topicStr: '@topics',
      },
      link: function (scope) {
        scope.featureService = featureService;
        scope.topics = scope.topicStr.split(',');
      },
    };
  },
]);

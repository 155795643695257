/**
 * Directive to choose language
 **/
app.directive('slLanguageChooser', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'C',
      scope: {},
      link: function (scope, element) {
        element.on('click', '.sl-language-chooser-language', function (event) {
          event.preventDefault();
          var language = $(event.currentTarget).attr('href').slice(1);
          $rootScope.changeLanguage(language);
        });
        element.on('$destroy', function () {
          element.off(); // Unbind events
        });
      },
    };
  },
]);
